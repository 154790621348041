import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput,
} from 'react-admin';

import { Testimonials, } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';


const TestimonialEdit = () => {
    const { isUpdatable } = useIsPermission("TESTIMONIALS");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<TestimonialTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <ImageInput source="author_image" fullWidth validate={[required()]} />
                    <TextInput source="author_name" fullWidth validate={[required()]} />
                    <TextInput source="display_order" type={'number'} fullWidth validate={[required()]} />
                    <HTMLEditorInput source="description" fullWidth validate={[required(), validHtml]} />
                    <ReferenceInput label="Batch Template" source="batch_template_id" reference="batch_templates">
                        <SelectInput optionText="title" disabled fullWidth />
                    </ReferenceInput>
                </SimpleForm>
            </Edit>
        </PermissionGuard>
    )
}

const TestimonialTitle = () => {
    const record = useRecordContext<Testimonials>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.testimonials.name', { smart_count: 1 })} &quot;
            {record.display_order}&quot;
        </span>
    ) : null;
};

export default TestimonialEdit;
