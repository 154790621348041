import { ContentCopy } from "@mui/icons-material";
import { Button, useRecordContext } from "react-admin";
import { Tooltip } from "@mui/material";

export const CopyIdButton = () => {
    const record = useRecordContext();

    if (!record) return null;

    const handleCopy = () => {
        if (record.id) {
            navigator.clipboard.writeText(record.id.toString()).then(() => {
            }).catch(err => {
                console.error("Failed to copy ID:", err);
            });
        }
    };

    return (
        <Tooltip title="Copy ID">
            <Button onClick={handleCopy}>
                <ContentCopy />
            </Button>
        </Tooltip>
    );
};
