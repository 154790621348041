import { useState, useRef, useCallback } from "react";
import { Button, TextField, Card, CardContent, Typography, IconButton, CircularProgress, FormControlLabel, Checkbox } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BASE_URL } from "../../common/constants";

const PAGE_SIZE = 50;

const QuestionsView = ({ questions, setQuestions }: any) => {
  const [visibleQuestions, setVisibleQuestions] = useState(questions.slice(0, PAGE_SIZE));
  const [hasMore, setHasMore] = useState(questions.length > PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  // Function to update question details
  const handleQuestionChange = (index: number, field: string, value: any) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  // Function to update options
  const handleOptionChange = (qIndex: number, oIndex: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[oIndex] = value;
    setQuestions(updatedQuestions);
  };

  // Function to delete an option
  const handleDeleteOption = (qIndex: number, oIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.splice(oIndex, 1);
    setQuestions(updatedQuestions);
  };

  // Function to add a new option
  const handleAddOption = (qIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.push("");
    setQuestions(updatedQuestions);
  };

  // Function to set the correct option
  const handleSetCorrectOption = (qIndex: number, optionKey: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].correctOption = optionKey;
    setQuestions(updatedQuestions);
  };

  // Load more questions when user scrolls down
  const loadMoreQuestions = () => {
    if (loading) return; // Prevent multiple calls
    setLoading(true);

    setTimeout(() => {
      const currentLength = visibleQuestions.length;
      const moreQuestions = questions.slice(currentLength, currentLength + PAGE_SIZE);
      setVisibleQuestions([...visibleQuestions, ...moreQuestions]);
      setLoading(false);

      if (visibleQuestions.length + moreQuestions.length >= questions.length) {
        setHasMore(false);
      }
    }, 300); // Simulating API delay
  };

  // Infinite scrolling effect
  const lastQuestionRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreQuestions();
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, visibleQuestions, loading]
  );

  return (
    <div className="p-4 flex justify-center w-full">
      <div className="w-full">

        {visibleQuestions.map((q: any, qIndex: number) => (
          <Card key={qIndex} className="mb-4" ref={qIndex === visibleQuestions.length - 1 ? lastQuestionRef : null}>
            <CardContent>
              <TextField
                label="Question"
                multiline
                fullWidth
                value={q.question}
                onChange={(e) => handleQuestionChange(qIndex, "question", e.target.value)}
                margin="normal"
              />

              {/* Options with Checkboxes */}
              <Typography variant="subtitle1">Options:</Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {q.options.map((opt: string, oIndex: number) => {
                  const optionKey = String.fromCharCode(65 + oIndex);
                  return (
                    <div key={oIndex} className="flex items-center mb-2 p-2 w-full">
                      <FormControlLabel
                        className="w-full"
                        control={
                          <Checkbox
                            checked={q.correctOption === optionKey}
                            onChange={() => handleSetCorrectOption(qIndex, optionKey)}
                          />
                        }
                        label={
                          <TextField
                            className=" w-full md:w-[30vw] "
                            multiline
                            value={opt}
                            onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
                          />
                        }
                      />
                    </div>
                  );
                })}
              </div>


              {/* Marks */}
              <div className="flex gap-4">
                <TextField
                  label="Positive Marks"
                  type="number"
                  value={q.positiveMarks}
                  onChange={(e) => handleQuestionChange(qIndex, "positiveMarks", e.target.value)}
                />
                <TextField
                  label="Negative Marks"
                  type="number"
                  value={q.negativeMarks}
                  onChange={(e) => handleQuestionChange(qIndex, "negativeMarks", e.target.value)}
                />
              </div>
            </CardContent>
          </Card>
        ))}

        {/* Loader when fetching more questions */}
        {loading && (
          <div className="flex justify-center my-4">
            <CircularProgress />
          </div>
        )}

        {/* Fallback Button */}
        {/* {hasMore && !loading && (
        <Button variant="contained" onClick={loadMoreQuestions} className="w-full mt-4">
          Load More
        </Button>
      )} */}

      </div>
    </div >
  );
};


const DocQuestionsPrev = ({ questionsList, handleClose, open }: { questionsList: any, handleClose: any, open: any }) => {
  const [questions, setQuestions] = useState([...questionsList]);
  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No authentication token found");
      return;
    }

    try {
      const response = await fetch(BASE_URL +"/admin/tests/question-bank/questions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({"questions":questions}), // Directly sending questions array
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Successfully submitted questions:", result);
    } catch (error) {
      console.error("Error submitting questions:", error);
    }
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "90vw", maxWidth: "90vw", height: "95vh" } }}
    >
      <div className="flex flex-row justify-between">

        <DialogTitle>
          Questions Preview
        </DialogTitle>
      </div>
      <DialogContent className="">
        <DialogContentText>
          <QuestionsView questions={questions} setQuestions={setQuestions} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>

  );
};

export default DocQuestionsPrev;
