import Button from "@mui/material/Button";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from "@mui/material";
import PermissionsListInput from "./PermissionsListInputField";

function AssignPermissionsModal({ open, setOpen }: { open: boolean, setOpen: any }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={false}
            sx={{ "& .MuiDialog-paper": { width: "80vw", maxWidth: "80vw" } }}
        >
            <DialogTitle>
                Select Permissions
            </DialogTitle>
            <DialogContent className="">
                <PermissionsListInput handleClose={handleClose} />
            </DialogContent>
        </Dialog>
    );
}


export const AssignPermissions = ({ path }: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <AssignPermissionsModal open={open} setOpen={setOpen} />
        <Tooltip title="Assign Permissions">
            <Button sx={{ paddingBottom: "0" }} className="gap-3 pb-0" onClick={() => {
                setOpen(true)
            }}>
                Assign Permissions
            </Button>
        </Tooltip>
    </>
};

