import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  useRecordContext,
  required,
  SelectInput,
  ReferenceInput,
  NullableBooleanInput,
  minValue,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
} from "react-admin";

import { Ecom_Categories, Ecom_Products } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import PDFInput from "../../common/inputs/PDFInput";
import { STATUSES } from "../../common/constants";
import { filterToQueryProductChildren } from "./ProductCreate";
import TotalAmountInput from "../../common/inputs/TotalAmountInput";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";

const ProductEdit = () => {
  let { categoryId, productTemplateId, productId } = useParams();
  if (!productTemplateId) {
    return <LoadingSection />;
  }
  return (
    <Edit
      title={<ProductTitle />}
      redirect={() => {
        return `ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_products`;
      }}
      id={productId}
      resource={"ecom_products"}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" fullWidth validate={[required()]} />
        <ImageInput
          source="images"
          multiple
          fullWidth
          validate={[required()]}
        />
        <PDFInput source="preview_pdf" fullWidth label={"Preview PDF"} />
        <PDFInput source="pdf_url" fullWidth label={"PDF"} />
        <ReferenceInput label="Demo Video" source="demo_video_id" reference="demo_videos">
          <SelectInput optionText="title" fullWidth validate={[required()]} />
        </ReferenceInput>

        <SelectInput source="status" choices={STATUSES} fullWidth />
        <NullableBooleanInput
          source={"address_required"}
          name={"Address Required"}
          fullWidth
        />
        <ReferenceInput
          reference={"ecom_product_templates"}
          source={"product_template_id"}
        >
          <SelectInput disabled fullWidth optionText="name" />
        </ReferenceInput>
        <ReferenceInput reference={"batches"} source={"batch_id"}>
          <SelectInput disabled fullWidth optionText="title" />
        </ReferenceInput>
        <TextInput
          type={"number"}
          source="mrp"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="discount"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="gst_percentage"
          prefix={"%"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <div className="w-full">
                <TotalAmountInput formData={formData} source="amount" />
              </div>
            );
          }}
        </FormDataConsumer>
        <TextInput
          type={"number"}
          source="apple_amount"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="delivery_amount"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="height_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="width_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="length_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="weight_in_grams"
          prefix={"In Grams"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <ReferenceArrayInput reference={"ecom_products"} source={"children"}>
          <AutocompleteArrayInput
            filterToQuery={filterToQueryProductChildren}
            label={"For Combo Products"}
            fullWidth
            optionText="name"
          />
        </ReferenceArrayInput>
        <TextInput
          source="text_description"
          multiline
          rows={4}
          fullWidth
          validate={[required()]}
        />
        <HTMLEditorInput
          source={"html_description"}
          fullWidth
          validate={[required(), validHtml]}
        />
      </SimpleForm>
    </Edit>
  );
};

const ProductTitle = () => {
  const record = useRecordContext<Ecom_Products>();
  const translate = useTranslate();

  console.log(record);
  return record ? (
    <span>
      {translate("resources.products.name", { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default ProductEdit;
