import * as React from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@mui/material';
import {

    List,
    EditButton,
    Datagrid,
    TextField, DateField, NumberField, SelectInput, required, ReferenceField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { STATUSES } from "../../common/constants";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';
import useIsPermission from '../../hooks/useIsPermission';


const IssueTemplateList = () => {
    const { isWritable, isDeletable, isUpdatable } = useIsPermission("ISSUE_TEMPLATE");
    return (
        <List
            actions={<PermissionBasedActions isWritable={isWritable} />}

            sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="slug" />
                <TextField source="status" />
                <FormattedDateTimeField source="created_at" />
                {isUpdatable &&
                    <EditButton />
                }
                {isDeletable &&
                    <DeleteWithDialogAction />
                }
            </Datagrid>
        </List>
    );
};
export default IssueTemplateList;
