import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    ReferenceField,
    TextField,
    Labeled,
    Datagrid, EditButton, List, useDataProvider, useGetOne, Loading,
} from 'react-admin';
import { Sections, Student_Test_Mapping } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Button, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Check, Close } from "@mui/icons-material";
import { displayDuration } from "../../common/string-utilities";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";


const QuestionWiseShow = (props: any) => {
    const dataProvider = useDataProvider();
    const { testSeriesId, programId, testId, studentResponseId } = useParams();
    const { data: results, refetch, isLoading, error } = useQuery(
        ['results_' + studentResponseId, 'getList', { test_id: testId }],
        () => dataProvider.getList('student_test_mapping_results', {
            filter: { student_test_mapping_id: studentResponseId },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'created_at', order: 'DESC' }
        })
    );
    const { data, isLoading: loading, error: isError } = useGetOne("tests", { id: testId });
    const result = results?.data?.[0];
    const resultId = result?.id
    const { data: userData, isLoading: load, error: err } = useGetOne("users", { id: results?.data?.[0]?.student_id });
    if (isLoading || !results?.data || !userData) {
        return <LoadingSection />
    }
    const fileName = (userData?.first_name + userData?.last_name)?.toLowerCase();
    if (!result) {
        return <div />
    }
    const questionWise = result?.question_wise || [];
    const markedAnswerSheet = result?.marked_answer_sheet
    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return [hrs, mins, secs]
            .map((val) => String(val).padStart(2, "0"))
            .join(":");
    };

    const exportData = () => {
        const csvContent = [
            ["Question #", "Is Correct", "Status", "Marks Obtained", "Time Spent"],
            ...questionWise.map((row: any, index: number) => [
                index + 1,
                row.is_correct ? "Yes" : row.status === "ATTEMPTED" ? "No" : "-",
                row.status,
                row.marks_obtained,
                formatTime(row.time_spend_in_seconds),
            ])
        ]
            .map((e) => e.join(","))
            .join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}_${resultId}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    };
    return data && data?.category === "SUBJECTIVE" ?
        <>
            <div className="flex flex-col">
                <Typography variant={'h6'}>Score Card</Typography>
                <Typography> Marks Obtained
                    : <strong>{`${result.marks_obtained}`}</strong></Typography>
                <a className="text-blue-500  background-transparent font-bold outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    href={markedAnswerSheet} download target="blank">
                    Download Marked  Answer sheet
                </a>
                <div className="flex justify-center">
                    <Button className="w-1/2">Reset Score Card</Button>
                </div>
            </div>
        </> :
        <div style={{ width: '100%' }}>
            <Typography className='px-10 font-bold' variant={'h5'} >Score Card</Typography>
            <div style={{ padding: '20px 0' }}>

                <div className="grid grid-cols-2  w-full lg:grid-cols-3 gap-4">
                    <div className="p-4 shadow-md text-center bg-amber-50">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Time Taken", value: result.total_time_in_minutes ?? 0 },
                                            { name: "Remaining", value: (data?.max_duration_in_minutes ?? 0) - (result.total_time_in_minutes ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="#3b82f6" />
                                        <Cell fill="#e2e8f0" />
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.total_time_in_minutes ?? 0}/${data?.max_duration_in_minutes ?? 0}`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Time Taken</h3>
                    </div>

                    <div className="p-4 shadow-md text-center bg-purple-50">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Attempted", value: result.total_questions_attempted ?? 0 },
                                            { name: "Remaining", value: (result.total_questions ?? 0) - (result.total_questions_attempted ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="#6b46c1" /> {/* Purple */}
                                        <Cell fill="#e2e8f0" /> {/* Light Gray */}
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.total_questions_attempted ?? 0}/${result.total_questions ?? 0}`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Questions Attempted</h3>
                    </div>

                    <div className="p-4 shadow-md text-center bg-green-100">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Correct", value: result.total_correct_questions ?? 0 },
                                            { name: "Incorrect", value: (result.total_questions_attempted ?? 0) - (result.total_correct_questions ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="#22c55e" />
                                        <Cell fill="#f87171" />
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.total_correct_questions ?? 0}/${result.total_questions_attempted ?? 0}`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Correct Questions</h3>
                    </div>

                    <div className="p-4 shadow-md text-center bg-blue-50">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Marked as Review", value: result.total_marked_as_review ?? 0 },
                                            { name: "Remaining", value: (result.total_questions ?? 0) - (result.total_marked_as_review ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="#f97316" /> {/* Orange for marked as review */}
                                        <Cell fill="#e2e8f0" /> {/* Light Gray for remaining */}
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.total_marked_as_review ?? 0}/${result.total_questions}`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Marked as Review</h3>


                    </div>

                    <div className="p-4 shadow-md text-center bg-red-100">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Accuracy", value: result.accuracy ?? 0 },
                                            { name: "Remaining", value: 100 - (result.accuracy ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="green" /> {/* Red */}
                                        <Cell fill="#e2e8f0" /> {/* Light Gray for remaining */}
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.accuracy ?? 0}%`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Accuracy</h3>
                    </div>

                    <div className="p-4 shadow-md text-center bg-yellow-50">
                        <div className="flex justify-center items-center">
                            <ResponsiveContainer width={120} height={120}>
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: "Obtained", value: result.marks_obtained ?? 0 },
                                            { name: "Remaining", value: (result.total_marks ?? 0) - (result.marks_obtained ?? 0) },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={55}
                                        stroke="none"
                                    >
                                        <Cell fill="#22c55e" /> {/* Green for obtained marks */}
                                        <Cell fill="#e2e8f0" /> {/* Light Gray for remaining */}
                                    </Pie>
                                    {/* Center Value */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        className="text-lg font-semibold text-gray-700"
                                    >
                                        {`${result.marks_obtained}/${result.total_marks}`}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <h3 className="text-gray-600 font-semibold">Marks Obtained</h3>
                    </div>
                </div>
            </div>

            <div className="table py-10 w-full">
                <div className="flex justify-end ">
                    <Button
                        onClick={exportData}
                    >
                        Export as CSV
                    </Button>
                </div>

                <Labeled style={{ width: '100%' }} label={'Question Wise Responses'}>
                    <TableContainer style={{ width: '100%' }} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Question #</TableCell>
                                    <TableCell>Is Correct</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Marks Obtained</TableCell>
                                    <TableCell>Time Spent</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {questionWise.map((row: any, index: number) => {

                                    return <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {
                                                index + 1
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                row.is_correct ? <Check /> : (row.status === 'ATTEMPTED' ? <Close /> :
                                                    <span>-</span>)
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                row.status
                                            }
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {
                                                row.marks_obtained
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                displayDuration(row.time_spend_in_seconds)
                                            }
                                        </TableCell>

                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Labeled>
            </div>
        </div>

}

const StudentResponseShow = () => {
    let { programId, testSeriesId, testId, studentResponseId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return <Show title={<SectionTitle />} resource={'student_test_mapping'} id={studentResponseId}>
        <SimpleShowLayout >
            <div className="o bg-white  p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Test Result</h2>
                <div className="flex flex-col lg:flex-row gap-4">
                    <div className='border rounded-xl w-full lg:w-2/3 shadow-md p-3'>
                        <div className="my-auto flex flex-wrap items-center h-full">
                            <ReferenceField reference="users" source="created_by_id" link={false}>
                                <FullNameField source='first_name' />
                            </ReferenceField>
                        </div>
                    </div>

                    <div className="grid grid-cols-2  w-full 2xl:grid-cols-3 gap-4">
                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">Test Name</h3>
                            <ReferenceField reference="tests" source="test_id" link={false}>
                                <TextField source="name" className="text-gray-800 font-medium" />
                            </ReferenceField>
                        </div>

                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">Status</h3>
                            <TextField source="status" className="text-gray-800 font-medium" />
                        </div>

                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">Created At</h3>
                            <FormattedDateTimeField source='created_at' />
                        </div>

                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">Started At</h3>
                            <FormattedDateTimeField source='started_at' />
                        </div>

                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">End Time</h3>
                            <FormattedDateTimeField source='end_time' />
                        </div>

                        <div className='p-4 shadow-md rounded-md'>
                            <h3 className="text-gray-600 font-semibold">Submitted At</h3>
                            <FormattedDateTimeField source='submitted_at' />
                        </div>
                    </div>
                </div>
            </div>

            <Divider />
            <QuestionWiseShow />
        </SimpleShowLayout>
    </Show>
};

const SectionTitle = () => {
    const record = useRecordContext<Student_Test_Mapping>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.student_responses.name', { smart_count: 1 })} &quot;
            {record.started_at}&quot;
        </span>
    ) : null;
};

export default StudentResponseShow;
