import React from 'react'
import { BooleanField, Button, Count, Datagrid, EditButton, FieldProps, Filter, Link, List, ListActions, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useRecordContext } from 'react-admin';
import { useNavigate, useParams } from 'react-router';
import { NoDataSection } from '../../common/NoDataSection';
import StatusTags from '../../common/StatusTags';
import { Tests } from '../../custom';
import { Tooltip } from '@mui/material';
import { DocumentScanner } from '@mui/icons-material';
import { TestMeta } from '../tests/TestList';
import { BulkUploadSectionQuestions } from '../sections/SectionQuestionUpload';

const QuestionPaperViewPage = (props: FieldProps<Tests>) => {
    const record = useRecordContext<any>();

    if (!record) {
        return null;
    }

    return (
        <Link
            to={`/exercise/${record.id}/question_paper`}>
            <Tooltip title="Show question paper">
                <DocumentScanner sx={{ fontSize: 30 }} />
            </Tooltip>
        </Link>
    );
};

const QuestionsLinkField = (props: FieldProps<Tests>) => {
    const record = useRecordContext<any>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/exercises/${record.id}/questions_list`}>
            Questions
            (
            <Count resource="section_question_mappings" filter={{ test_id: record?.id }} />
            )
        </Link>
    );
};

QuestionPaperViewPage.defaultProps = {};

const ExerciseFilters = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput variant={'outlined'} label="Name" source="name" alwaysOn={true} defaultValue="" />
            <ReferenceInput label="Program" source="program_id" alwaysOn reference="programs">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    );
}

// const Actions = () => {
//     const [showActions, setShowActions] = useState(false);
//     const record = useRecordContext();
//     const dropdownRef = useRef<HTMLDivElement>(null);

//     // Close dropdown on click outside
//     useEffect(() => {
//         const handleClickOutside = (event: MouseEvent) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
//                 setShowActions(false);
//             }
//         };

//         if (showActions) {
//             document.addEventListener("mousedown", handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [showActions]);

//     return (
//         <div className="relative inline-block" ref={dropdownRef}>
//             <button
//                 onClick={() => setShowActions((prev:any) => !prev)}
//                 className="p-2 rounded-full hover:bg-gray-200"
//             >
//                 <MoreVertIcon />
//             </button>

//             {showActions && (
//                 <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg p-2 flex flex-col text-start gap-2 z-10">
//                     <Link to={`/test_serieses/${record.test_series_id}/tests/${record.id}/question_paper`} className="hover:bg-blue-50">
//                         <QuestionPaperViewPage /> view Questions
//                     </Link>
//                     <div className="flex w-full justify-start gap-2 text-[#4f3cc9] text-md items-center">
//                         <BulkUploadButtonVisibility /> bulk upload
//                     </div>
//                     <div className="flex w-full gap-2 justify-start text-[#4f3cc9] text-md items-center hover:bg-blue-50">
//                         <BulkUploadViaQBButtonVisibility />
//                     </div>
//                     <div className="flex w-full gap-2 justify-start text-[#4f3cc9] text-md items-center hover:bg-blue-50">
//                         <CloneBatch /> clone Batch
//                     </div>
//                     <div className="flex w-full gap-2 justify-start text-[#4f3cc9] text-md items-center hover:bg-blue-50">
//                         <EditButton /> Edit
//                     </div>
//                     <div className="flex w-full gap-2 justify-start text-[#4f3cc9] text-md items-center hover:bg-blue-50">
//                         <DeleteWithDialogAction /> Delete
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

const ExercisesList = () => {
    const navigate = useNavigate();
    return (
        <List sort={{ field: 'created_at', order: 'DESC' }}
            empty={<NoDataSection resource={'tests'} onCreate={() => {
                navigate(`/exercise/create`)
            }}

            />
            }
            resource={'tests'} actions={
                <ListActions />
            } filter={{
                type: "EXERCISE",
            }}
            filters={<ExerciseFilters />}
        >
            <Datagrid>
                <TextField source="name" />
                <NumberField source="display_order" />
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="is_demo" />
                <QuestionsLinkField />
                <StatusTags source="status" label={'Status'} />
                <TestMeta />
                < BulkUploadSectionQuestions type={"exercise"} />
                <QuestionPaperViewPage />
            </Datagrid>
        </List>
    );
};

export default ExercisesList;

