import * as React from 'react';
import { memo } from 'react';
import { TextField, Box, Typography } from '@mui/material';
import { FieldProps, useRecordContext, ImageField, RichTextField } from 'react-admin';

interface Props extends FieldProps {
    source: string;
    label?: string;
}

const CustomDisableField = ({ source, label }: Props) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    const value = record[source];

    // Check if the value is an image URL
    const isImage = typeof value === 'string' && value.match(/\.(jpeg|jpg|gif|png|webp|svg)$/i);

    // Check if the value contains HTML
    const isHTML = typeof value === 'string' && /<\/?[a-z][\s\S]*>/i.test(value);

    return (
        <Box sx={{ width: '100%', mt: 1 }}>
            {isImage ? (
                <ImageField source={source} record={record} label={label} sx={{ mt: 1 }} />
            ) : isHTML ? (
                <div className="border p-2 my-3 rounded-md">
                    <p className='' >{source}</p>
                    <RichTextField source={source} record={record} label={label} sx={{ mt: 1 }} />
                </div>
            ) : (
                <TextField
                    label={label}
                    value={value}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                    sx={{ mt: 1 }}
                />
            )}
        </Box>
    );
};

CustomDisableField.defaultProps = {
    label: '',
};

export default memo<Props>(CustomDisableField);
