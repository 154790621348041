import React from 'react'
import { Datagrid, EditButton, List, NumberField, TextField } from 'react-admin'
import { DeleteWithDialogAction } from '../../common/DeleteWithDialogAction'
import DemoVideoLinkField from './ProgramDemoVideoLinkField'
import useIsPermission from '../../hooks/useIsPermission'
import PermissionBasedActions from '../../common/PermissionBasedActionsButton'

const DemoVideosCategoriesList = () => {
    const { isDeletable, isWritable, isUpdatable } = useIsPermission("DEMO_VIDEOS");
    return (
        <List
            actions={<PermissionBasedActions isWritable={isWritable} />}
        >
            <Datagrid>
                <TextField source="name" />
                <DemoVideoLinkField />
                {isUpdatable &&
                    <EditButton />
                }
                {isDeletable &&
                    <DeleteWithDialogAction />
                }
            </Datagrid>
        </List>
    )
}

export default DemoVideosCategoriesList
