import { Create, SimpleForm, TextInput } from 'react-admin'

const PdfCategoriesCreate = () => {
    return (
        <Create redirect={'/pdf_categories'}>
            <SimpleForm>
                <TextInput source="name" fullWidth />
            </SimpleForm>
        </Create>
    )
}

export default PdfCategoriesCreate
