import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton,
    useRecordContext,
    FieldProps,
    Link,
    ReferenceManyCount,
    NumberField,
    useNotify,
    useDataProvider,
    SimpleForm,
    TextInput, required, SaveButton, Toolbar, Create, useRefresh, ReferenceInput, SelectInput, useCreate, Filter, minValue,
    useGetOne
} from 'react-admin';
import ChaptersLinkField from "./ChaptersLinkField";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { Add, CopyAll, CurrencyRupee, Edit, Visibility, Warning } from "@mui/icons-material";
import { Subjects } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { useQuery } from "react-query";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import PDFInput from "../../common/inputs/PDFInput";
import StatusTags from "../../common/StatusTags";
import ContentCounts from "../../common/ContentCounts";
import { STATUS } from "../../common/constants";
import { ChevronRightIcon } from 'lucide-react';

const ListActions = ({ programId }: { programId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/subjects/create`)
        }} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Subjects = useRecordContext<Subjects>();
    return <Button onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.id}/edit`)
    }}>
        <Edit />
    </Button>

};
const ShowButton = () => {
    const navigate = useNavigate();
    const record: Subjects = useRecordContext<Subjects>();
    return <Button onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.id}/show`)
    }}>
        <Visibility />
    </Button>

};

const DoubtsLinkField = (props: FieldProps<Subjects>) => {
    const record = useRecordContext<Subjects>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/subjects/${record.id}/doubts`}>
            Doubts
            (
            <ReferenceManyCount
                label="Doubts"
                reference="doubts"
                target="subject_id"
            />
            )
        </Link>
    );
};
DoubtsLinkField.defaultProps = {
    label: 'Doubts'
};

const Actions = (props: FieldProps<Subjects>) => {
    return (
        <div className={'flex space-x-2'}>
            <ShowButton />
            <EditButton />
            <DeleteWithDialogAction />
        </div>
    );
};
Actions.defaultProps = {
    label: 'Actions'
};
export const ProductTemplateFormModal = (props: { subject_id?: string, batch_id?: string, test_series_id?: string, chapter_id?: string, program_id?: string }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext<Subjects>();
    const defaultValues = { ...props, name: record.name, height_in_mtr: 0, width_in_mtr: 0, length_in_mtr: 0, weight_in_grams: 0, text_description: '', html_description: '' };
    const refresh = useRefresh();
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();
    const postSave = (data: any) => {
        try {
            if (isLoading) {
                return;
            }
            create('ecom_product_templates', { data }, {
                onSuccess: (e) => {
                    create('ecom_products', { data: { ...data, product_template_id: e.id, } }, {
                        onSuccess: (e) => {
                            notify(
                                'Product added successfully',
                                {
                                    type: 'success'
                                }
                            );
                            setOpen(false);
                            refresh();
                        }
                    });
                }
            });
        } catch (e) {
            return false;
        }
    };
    return <div>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle style={{ paddingBottom: '0' }}>
                Add store item to "{record.name}"
            </DialogTitle>
            <DialogContent sx={{ width: '500px', padding: '0' }}>
                <Create redirect={'/ecom_products'}>
                    <SimpleForm onSubmit={postSave} toolbar={
                        <Toolbar className={'w-full'}>
                            <div className={'flex space-between w-full'}>
                                <SaveButton />
                                <Button disabled={isLoading} onClick={() => setOpen(false)}>Close</Button>
                            </div>
                        </Toolbar>
                    } defaultValues={defaultValues}>
                        <PDFInput source="preview_pdf" fullWidth label={'Preview PDF'} />
                        <TextInput source="name" fullWidth validate={[required()]} />
                        <TextInput type={'number'} source="apple_amount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                        <TextInput type={'number'} source="amount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                        <TextInput type={'number'} source="discount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                        <TextInput source="text_description" multiline rows={4} fullWidth validate={[required()]} />
                        <HTMLEditorInput fullWidth source={'html_description'} />
                    </SimpleForm>
                </Create>
            </DialogContent>
        </Dialog>
        <Button size={'small'} onClick={() => {
            setOpen(true)
        }}>Sell</Button>
    </div>
}
const ProductTemplateField = (props: FieldProps<Subjects>) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext<Subjects>();
    const { data: productsData, isLoading, error: resultError } = useQuery(
        ['products_subject_' + record.id, 'getList', { subject_id: record.id }],
        () => dataProvider.getList('ecom_products', {
            filter: {
                product_template: {
                    format: 'hasura-raw-query',
                    value: {
                        subject_id: {
                            _eq: record?.id
                        }
                    },
                }
            },
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'created_at', order: 'DESC' }
        })
    );
    const products = productsData?.data;
    if (isLoading) {
        return <div>...</div>
    }
    if (products?.length) {
        return <div className={'min-w-[90px] text-center px-2 py-0.5 rounded-md bg-blue-500 text-white no-wrap text-xs'}>
            Products ({products.length})
        </div>
    }
    return <ProductTemplateFormModal subject_id={record.id} />;
};

ProductTemplateField.defaultProps = {
    label: 'Product Template'
};
const SubjectNameField = (props: FieldProps<Subjects>) => {
    const record = useRecordContext<Subjects>();
    const notify = useNotify();
    return <div className={'flex space-x-2 items-center'}>
        {/* <div
            className={'w-[80px] h-[45px] min-w-[80px] min-h-[45px] aspect-video rounded overflow-hidden bg-gray-100 border bg-center bg-contain bg-no-repeat'}
            style={{ backgroundImage: `url(${record.image})` }} /> */}
        <div className={'flex flex-col items-start'}>
            <div className={'font-medium'}>
                {record.name}
            </div>
            <ProductTemplateField />
        </div>
    </div>
}

const ListFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <SelectInput
            source="status"
            label={'Status'}
            choices={STATUS}
            alwaysOn
        />
    </Filter>
);
const SubjectList = () => {
    const params = useParams();
    const navigate = useNavigate();
    if (!params['programId']) {
        return <LoadingSection />
    }
    return (
        <List
            title={<SubjectsTitle />}
            filters={<ListFilter />}
            empty={<NoDataSection resource={'subjects'} onCreate={() => {
                navigate(`/programs/${params['programId']}/subjects/create`)
            }} />}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'subjects'} actions={
                <ListActions programId={params['programId'] + ''} />
            } filter={{
                program_id: params['programId']
            }}>
            <Datagrid>
                <SubjectNameField />
                <NumberField source="display_order" />
                <TextField source="slug" />
                <StatusTags source="status" label={'Status'} />
                {/* <StatusTags source="program_status" label={'Program Status'} /> */}
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <ContentCounts />
                <FormattedDateTimeField source="created_at" />
                <ChaptersLinkField />
                {/* <DoubtsLinkField /> */}
                <Actions />
            </Datagrid>
        </List>
    );
};

const SubjectsTitle = () => {
    const { programId } = useParams();
    const { data: program, isLoading } = useGetOne("programs", { id: programId });

    if (isLoading) return <span>Loading...</span>;

    return (
        <span className="flex flex-row items-center text-lg font-semibold">
            <Link to={`/programs`} className="text-blue-600 hover:underline">
                {program?.name}
            </Link>
            <ChevronRightIcon />
            <span>Subjects</span>
        </span>
    );
};


export default SubjectList;
