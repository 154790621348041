import { useState } from 'react';
import {
    useDataProvider,
    Datagrid,
    TextField,
    List,
    Pagination,
    TextInput
} from 'react-admin';
import { CopyIdButton } from '../../common/CopyIdButton';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';

const filters = [
    <TextInput label="Search by Name" source="name" alwaysOn fullWidth/>,
];

const MetaDataList = () => {
    const [activeTab, setActiveTab] = useState(0);

    const tabData = [
        { label: 'Master Class', resource: 'master_classes' },
        { label: 'Master Subjects', resource: 'master_subjects' },
        { label: 'Master Chapters', resource: 'master_chapters' },
        { label: 'Master Topics', resource: 'master_topics' },
    ];

    return (
        <div className="border rounded-md mb-14">
            <div className="flex text-lg flex-row gap-16 px-10 pt-4 border-b">
                {tabData.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`cursor-pointer pb-2 ${index === activeTab ? 'text-[#4f3cc9] border-b-4 border-[#4f3cc9]' : ''
                            }`}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>

            <div className="p-4">
                <List
                    resource={tabData[activeTab].resource}
                    filters={filters}
                    sort={{ field: 'display_order', order: 'DESC' }}
                    perPage={10}  
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <CopyIdButton />
                        <TextField source="name" />
                        <TextField source="display_order" />
                        <FormattedDateTimeField source='created_at' />
                    </Datagrid>
                </List>
            </div>
        </div>
    );
};

export default MetaDataList;
