import * as React from "react";
import {
  Edit,
  SimpleForm,
  useTranslate,
  useRecordContext,
  required,
  SelectInput,
  TextInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  useGetOne,
} from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { SEGMENTATION_MODULE } from "../../common/constants";
import {
  BatchRender,
  ConditionMethodsChoice,
  ConditionTypeChoice,
  operatorChoices,
  ProgramRender,
  SubjectsRender,
  TestSeriesRender,
  UsersRender,
} from "./SegmentCreate";
import { useParams } from "react-router";
import PermissionGuard from "../../common/PermissionGuard";
import useIsPermission from "../../hooks/useIsPermission";

const SegmentEdit = () => {
  const { id } = useParams()
  const { data } = useGetOne("segmentations", { id: id });
  const Type = data?.condition[0]?.type;
  const VALUE = ConditionTypeChoice?.find(option => option.id === Type)?.name || null
  const [selectedType, setSelectedType] = React.useState<string | null>(VALUE);
  const handleTypeChange = (typeId: string) => {
    const selectedModule = ConditionTypeChoice.find((type) => type.id === typeId);
    setSelectedType(selectedModule ? selectedModule.name : null);
  };
  const { isUpdatable } = useIsPermission("SEGMENTS");
  return (
    <PermissionGuard permission={isUpdatable} >
      <Edit title={<SegmentTitle />}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput source="name" fullWidth validate={[minValue(0)]} />

          <SelectInput
            source="module"
            label="Module"
            fullWidth
            defaultValue="BATCH"
            validate={[required()]}
            choices={SEGMENTATION_MODULE}
          />

          <ArrayInput source="condition">
            <SimpleFormIterator disableAdd disableClear>
              <SelectInput
                fullWidth
                source="type"
                label="Type"
                choices={ConditionTypeChoice}
                onChange={(event) => handleTypeChange(event.target.value)}
              />
              <SelectInput
                fullWidth
                source="method"
                label="Method"
                choices={ConditionMethodsChoice}
              />
              {selectedType === "Batch" ?
                <ReferenceInput name={'Batch'} source={'value'} reference={'batches'}
                  fullwidth>
                  <AutocompleteInput optionText={BatchRender}
                    debounce={500} fullWidth />
                </ReferenceInput>
                :
                selectedType === "Programs" ?
                  <ReferenceInput name={'Program'} source={'value'} reference={'programs'}
                    fullwidth>
                    <AutocompleteInput optionText={ProgramRender}
                      debounce={500} fullWidth />
                  </ReferenceInput>
                  :
                  selectedType === "Subjects" ?
                    <ReferenceInput name={'Subject'} source={'value'} reference={'subjects'}
                      fullwidth>
                      <AutocompleteInput optionText={SubjectsRender}
                        debounce={500} fullWidth />
                    </ReferenceInput>
                    :
                    selectedType === "Test Series" ?
                      <ReferenceInput name={'Test Series'} source={'value'} reference={'test_serieses'}
                        fullwidth>
                        <AutocompleteInput optionText={TestSeriesRender}
                          debounce={500} fullWidth />
                      </ReferenceInput>
                      :
                      selectedType === "User" ?
                        <ReferenceInput name={'User'} source={'value'} reference={'users'}
                          fullwidth>
                          <AutocompleteInput optionText={UsersRender}
                            debounce={500} fullWidth />
                        </ReferenceInput>
                        : <>
                        </>
              }
              <SelectInput
                source="operator"
                fullWidth
                label="Operator"
                choices={operatorChoices}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </PermissionGuard >
  );

};

const SegmentTitle = () => {
  const record = useRecordContext<any>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("Segment", { smart_count: 1 })} &quot;
      {record.module}&quot;
    </span>
  ) : null;
};

export default SegmentEdit;
