import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const InfoModal = ({ open, setOpen }: { open: any, setOpen: any }) => {
    return (

        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle className="flex flex-row justify-between items-center bg-gray-100 h-7">
                <div className="flex flex-row gap-3 items-center">
                    <InfoOutlinedIcon sx={{ color: 'blue' }} />
                    <p>Info</p>
                </div>
                <CloseOutlinedIcon onClick={() => setOpen(false)} className='cursor-pointer' sx={{ color: "red" }} />
            </DialogTitle>

            <DialogContent className='mt-5 '>
                <p>{`{ NAME } = first_name + last_name`}</p>
                <p>{`{ MNAME } = Module Name`}</p>
                <p>{`{ NOW } = Current date in DD-MM-YYYY`}</p>
                <p>{`{ TNOW } = Current date in DD-MM-YYYY HH:MM`}</p>
            </DialogContent>
        </Dialog>
    )
}

export default InfoModal
