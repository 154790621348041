import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput,
} from 'react-admin';

import { Cs_Issue_Templates, Ecom_Categories, } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { STATUSES } from "../../common/constants";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const IssueTemplateEdit = () => {
    const { isUpdatable } = useIsPermission("ISSUE_TEMPLATE");
    return (
        <Edit title={<IssueTemplateTitle />}>
            <PermissionGuard permission={isUpdatable}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput source="name" fullWidth validate={[required()]} />
                    <TextInput source="slug" fullWidth validate={[required()]} />
                    <SelectInput
                        source="status"
                        label={'Status'}
                        fullWidth
                        validate={[required()]}
                        choices={STATUSES}
                    />
                </SimpleForm>
            </PermissionGuard >
        </Edit>
    )
}

const IssueTemplateTitle = () => {
    const record = useRecordContext<Cs_Issue_Templates>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.issue_templates.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default IssueTemplateEdit;
