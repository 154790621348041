import Button from "@mui/material/Button";
import { CloudUploadOutlined } from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from "@mui/material";
import BulkQuestionsViaDocInput from "./BulkQuestionsVIaDocInput";
import DocQuestionsPrev from "./DocQuestionsPrev";

function BulkUploadDialogViaDocFile({ open, setOpen }: { open: boolean, setOpen: any }) {
    const [questionsList, setQuestionsList] = React.useState([])

    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        {questionsList?.length > 0 ? <>
            <DocQuestionsPrev handleClose={handleClose} questionsList={questionsList} open={open} />
        </>
            :
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className="flex flex-row justify-between">

                    <DialogTitle>
                        Bulk Upload  Questions Via Doc
                    </DialogTitle>
                    <DialogTitle>
                        {/* <a
                        href="https://assets.kaksya.in/samples/question-upload.xlsx"
                        download
                        className="flex gap-2 text-blue-900 cursor-pointer hover:underline"
                        >
                        <Download /> Sample
                        </a> */}
                    </DialogTitle>

                </div>
                <DialogContent className="">
                    <DialogContentText>
                        <BulkQuestionsViaDocInput
                            questionsList={questionsList}
                            setQuestionsList={setQuestionsList}
                            onSuccess={(key: string) => {
                                handleClose();
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        }
    </>
    );
}


export const BulkUploadQuestionBankViaDocAction = ({ }: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <BulkUploadDialogViaDocFile open={open} setOpen={setOpen} />
        <Tooltip title="Bulk Upload">
            <Button onClick={() => {
                setOpen(true)
            }}>
                <CloudUploadOutlined />
                <span className="px-2">

                    UPLOAD Via Doc (Beta)
                </span>
            </Button>
        </Tooltip>
    </>
};

