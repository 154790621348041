import {
  Edit,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { EditToolbar } from "../../common/EditToolbar";
import PDFInput from '../../common/inputs/PDFInput';
import ImageInput from '../../common/inputs/ImageInput';

const PdfEdit = () => (
  <Edit title={<BannerTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="title" fullWidth />
      <ImageInput source="thumbnail" fullWidth />
      <FormDataConsumer>
        {
          ({ formData }) => {
            return <PDFInput fullWidth
              formData={formData}
              source={'url'} />
          }
        }
      </FormDataConsumer>
      <ReferenceInput reference={'pdf_categories'} source={'category_id'}>
        <SelectInput fullWidth optionText="name" required />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const BannerTitle = () => {
  const record = useRecordContext<any>();
  return record ? (
    <span>
      {record?.name || record?.title}
    </span>
  ) : null;
};

export default PdfEdit;
