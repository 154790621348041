import React from "react";
import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import AddIcon from "@mui/icons-material/Add";

const PermissionBasedActions = ({ isWritable }:any) => {
  return (
    <TopToolbar>
      {isWritable && <CreateButton label="Create" icon={<AddIcon />} />}
      <ExportButton />
    </TopToolbar>
  );
};

export default PermissionBasedActions

