import * as React from 'react';
import {
  Create,
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  SimpleForm,
  SelectInput,
  useCreate,
  useNotify,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

import { QUESTION_TYPES } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import { filterToQueryVideo, videoNameRender } from '../batch_schedules/BatchScheduleEdit';
import ImageInputWithType from '../../common/inputs/ImageInputWithType';

const Options = ({ selectedType }: { selectedType: string }) => {
  if (!selectedType) return null;

  if (selectedType === 'NUMERIC') {
    return <TextInput fullWidth source="solution_text" type="number" />;
  }

  return (
    <ArrayInput
      source="options"
      sx={{
        border: '1px solid #aaaaaa',
        padding: '10px',
        borderRadius: '10px',
        marginBottom: '20px'
      }}
    >
      <SimpleFormIterator>
        <ImageInputWithType source="image" type="test" typeId="" />
        <TextInput fullWidth source="display_order" defaultValue={0} />
        <HTMLEditorInput label="Content" fullWidth source="content" validate={[validHtml]} />
        <BooleanInput defaultValue={false} fullWidth label="Is Correct" source="is_correct" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const QuestionBankCreate = () => {
  const [selectedType, setSelectedType] = React.useState<string>('');
  const [selectedMasterClass, setSelectedMasterClass] = React.useState<string>('');
  const [selectedMasterSubject, setSelectedMasterSubject] = React.useState<string>('');
  const [selectedMasterChapter, setSelectedMasterChapter] = React.useState<string>('');
  const [selectedMasterTopic, setSelectedMasterTopic] = React.useState<string>('');

  const [create, { isLoading }] = useCreate();
  const navigate = useNavigate();
  const notify = useNotify();

  const postSave = (data: any) => {
    if (isLoading) return;

    try {
      let formattedData: any = { ...data };

      if (formattedData.options) {
        formattedData.options = { data: formattedData.options };
      }

      if (formattedData.type === 'NUMERIC') {
        delete formattedData.options;
      }

      if (formattedData.type === 'SINGLE') {
        const correctOptions = formattedData.options?.data?.filter((opt: any) => opt.is_correct);
        if (correctOptions?.length !== 1) {
          notify('Exactly one correct answer should be selected', { type: 'error' });
          return;
        }
      }

      if (formattedData.type === 'MULTIPLE') {
        const correctOptions = formattedData.options?.data?.filter((opt: any) => opt.is_correct);
        if (!correctOptions?.length) {
          notify('At least one correct answer should be selected', { type: 'error' });
          return;
        }
      }

      formattedData.master_class_id = selectedMasterClass;
      formattedData.master_subject_id = selectedMasterSubject;
      formattedData.master_chapter_id = selectedMasterChapter;
      formattedData.master_topic_id = selectedMasterTopic;
      create('questions', { data: formattedData }, {
        onSuccess: () => navigate(`/questions_bank`)
      });
    } catch (e) {
      console.error("Error saving question:", e);
    }
  };

  return (
    <Create resource="questions">
      <SimpleForm onSubmit={postSave}>
        <SelectInput
          source="type"
          label="Type"
          fullWidth
          validate={[required()]}
          choices={QUESTION_TYPES}
          onChange={(e) => setSelectedType(e.target.value)}
        />
        <ImageInputWithType source="image" type="test" typeId="" />
        <TextInput source="name" fullWidth />
        <HTMLEditorInput source="content" fullWidth />
        <Options selectedType={selectedType} />

        <TextInput source="positive_marks" fullWidth />
        <TextInput source="negative_marks" fullWidth />
        <HTMLEditorInput source="solution_description" fullWidth />

        <ReferenceInput name="Video" source="video_id" reference="videos">
          <AutocompleteInput
            optionText={videoNameRender}
            filterToQuery={filterToQueryVideo}
            limitChoicesToValue
            debounce={500}
            fullWidth
          />
        </ReferenceInput>

        <ImageInputWithType source="solution_image" type="test" typeId="" />

        <ReferenceInput
          label="Master Class"
          source="master_class_id"
          reference="master_classes"
          sort={{ field: "display_order", order: "ASC" }}
        >
          <SelectInput optionText="name" fullWidth
            // validate={[required()]} 
            onChange={(e) => {
              setSelectedMasterClass(e.target.value);
            }}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Master Subject"
          source="master_subject_id"
          reference="master_subjects"
          sort={{ field: "display_order", order: "ASC" }}
        >
          <SelectInput
            optionText="name"
            fullWidth
            // validate={[required()]}
            onChange={(e) => {
              setSelectedMasterSubject(e.target.value);
              setSelectedMasterChapter("");
              setSelectedMasterTopic("")
            }}
          />
        </ReferenceInput>

        {selectedMasterSubject && (
          <ReferenceInput
            label="Master Chapter"
            source="master_chapter_id"
            reference="master_chapters"
            filter={{ subject_id: selectedMasterSubject }}
            sort={{ field: "display_order", order: "ASC" }}
          >
            <SelectInput
              optionText="name"
              fullWidth
              // validate={[required()]}
              onChange={(e) => {
                setSelectedMasterChapter(e.target.value);
                setSelectedMasterTopic("")
              }
              }
            />
          </ReferenceInput>
        )}

        {/* Master Topic Selection (Filtered by Chapter) */}
        {selectedMasterChapter && (
          <ReferenceInput
            label="Master Topic"
            source="master_topic_id"
            reference="master_topics"
            filter={{ chapter_id: selectedMasterChapter }}
            sort={{ field: "display_order", order: "ASC" }}
          >
            <SelectInput optionText="name" fullWidth
              // validate={[required()]}
              onChange={(e) => setSelectedMasterTopic(e.target.value)}
            />
          </ReferenceInput>
        )}

      </SimpleForm>
    </Create>
  );
};

export default QuestionBankCreate;
