import { useEffect } from "react";
import { useController } from "react-hook-form";

const PdfUrlPrefilled = (props: any) => {
    const { field } = useController({ name: props.source });

    useEffect(() => {
        if (props.selectedPdfUrl) {
            field.onChange(props.selectedPdfUrl);
        } else {
            field.onChange("");
        }
    }, [props.selectedPdfUrl]);

    return (
        props?.selectedPdfUrl &&
        <div className="w-full">
            <div className="border border-gray-300 rounded py-2 px-2 hover:border-black focus-within:border-blue-600">
                <input
                    disabled
                    value={field.value || ""}
                    className="w-full border-none outline-none"
                    readOnly
                />
            </div>
        </div>

    );
};

export default PdfUrlPrefilled;
