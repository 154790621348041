import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    NumberField,
    ImageField, EditButton,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';

const PodcastTopicList = () => {
    const { isUpdatable, isWritable, isDeletable } = useIsPermission("PODCAST");
    return (
        <List
            actions={<PermissionBasedActions isWritable={isWritable} />}
            sort={{ field: 'display_order', order: 'ASC' }}>
            <Datagrid>
                <ImageField source={'thumbnail'} />
                <TextField source="name" />
                <NumberField source={'display_order'} />
                <FormattedDateTimeField source="created_at" />
                {isUpdatable &&
                    <EditButton />
                }
                {isDeletable &&
                    <SoftDeleteWithDialogAction />
                }
            </Datagrid>
        </List>
    );
};
export default PodcastTopicList;
