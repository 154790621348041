import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, ReferenceField, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import FullNameField from "../../common/FullNameField";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';
import useIsPermission from '../../hooks/useIsPermission';

function jsonToStringArray(json: any) {
    const data = json;
    return Object.keys(data).map((key) => {
        return `${key}: ${data[key]}`;
    });
}

export function JsonToTagsView({ source }: { source: string }) {
    const record = useRecordContext();
    const data = record[source];
    if (!data) {
        return <></>
    }
    return <div className={'flex flex-wrap gap-1'}>
        {
            jsonToStringArray(data).map((item, index) => {
                return <span key={index} className={'tag px-1 py-0.5 text-xs rounded bg-gray-500 text-white capitalize'}>{item}</span>
            })
        }
    </div>
}

const IssuesList = () => {
    const { isWritable, isDeletable } = useIsPermission("ISSUE");
    return (
        <List
            actions={<PermissionBasedActions isWritable={isWritable} />}

            sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid rowClick={'show'}>
                <TextField source="issue_description" />
                <JsonToTagsView source="device_meta" />
                <ReferenceField reference={'cs_issue_topics'} source={'issue_topic_id'}>
                    <TextField fullWidth source="name" />
                </ReferenceField>
                <ReferenceField label={'Student'} source={'created_by_id'} reference={'users'}>
                    <FullNameField />
                </ReferenceField>
                <TextField source="resolution_status" />
                <FormattedDateTimeField source="created_at" />
                {isDeletable &&
                    <SoftDeleteWithDialogAction />
                }
            </Datagrid>
        </List>
    );
};
export default IssuesList;
