import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import CloseIcon from '@mui/icons-material/Close';
const PdfViewer = ({ source }: any) => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const fileUrl = record?.[source];

    if (!fileUrl) return null;

    return (
        <>
            <button
                className="px-4  text-blue-700 rounded-md"
                onClick={() => setOpen(true)}
            >
                View PDF
            </button>

            {open && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    onClick={() => setOpen(false)} // Clicking the background closes the modal
                >
                    <div
                        className="bg-gray-800 w-5/6 h-5/6 rounded-lg shadow-lg relative pt-8"
                        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
                    >
                        <button
                            className="absolute top-1 right-3 text-red-600 hover:text-red-700 text-xl"
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </button>
                        <iframe
                            src={fileUrl}
                            className="w-full h-full rounded-lg"
                            title="PDF Viewer"
                        ></iframe>
                    </div>
                </div>

            )}
        </>
    );
};

export default PdfViewer;
