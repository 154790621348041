import { Button, Checkbox, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { BASE_URL } from '../../common/constants';
import { useQuery } from "react-query";

const ModalQuestionEditView = ({ selectedQuestions, setIsEdit, handleSelect, handleClose, type }: any) => {
    const record = useRecordContext();
    console.log("record",record)
    const dataProvider = useDataProvider();
    const TEST_ID = record?.test_id || record?.exercise_id ||record?.id
    const { data: SectionData, refetch, error } = useQuery(
        ['sections', 'getList', {}],
        () => dataProvider.getList('sections', {
            filter: {
                test_id: TEST_ID
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' }
        })
    );
    const SECTION_ID = type === "exercise" ? SectionData?.data[0]?.id : record?.id
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [perPage] = useState(10);
    const [editedQuestions, setEditedQuestions] = useState([...selectedQuestions]);
    const [checkedQuestions, setCheckedQuestions] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        setTotal(selectedQuestions?.length || 0);
        setEditedQuestions([...selectedQuestions]);

        const initialChecked = selectedQuestions.reduce((acc: any, question: any) => {
            acc[question.id] = true;
            return acc;
        }, {});
        setCheckedQuestions(initialChecked);
    }, [selectedQuestions]);

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleInputChange = (id: string, field: string, value: string | number) => {
        setEditedQuestions((prev) =>
            prev.map((q) => (q.id === id ? { ...q, [field]: value } : q))
        );
    };

    const handleSave = async () => {
        const data = {
            sectionId: SECTION_ID,
            questions: editedQuestions.map(
                ({ id, display_order, positive_marks, negative_marks }) => ({
                    id,
                    display_order: Number(display_order),
                    positive_marks: Number(positive_marks),
                    negative_marks: Number(negative_marks),
                })
            ),
        };

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        try {
            const response = await fetch(
                BASE_URL + '/admin/tests/' + TEST_ID + '/create-section-questions-bulk',
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                }
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // const result = await response.json();
            handleClose()
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCheckboxChange = (question: any) => {
        setCheckedQuestions((prev) => ({
            ...prev,
            [question.id]: !prev[question.id], // Toggle checkbox state
        }));

        // Call the handleSelect function to update the parent component's selectedQuestions
        handleSelect(question);
    };

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedQuestions = editedQuestions.slice(startIndex, endIndex);

    return (
        <div className="min-w-[70vw] max-w-full">
            <div className="relative border rounded-lg overflow-hidden">
                <div style={{ minHeight: '70vh', overflowY: 'auto' }}>
                    <table className="min-w-full table-auto border-collapse">
                        <thead>
                            <tr className="bg-gray-100 border-b border-gray-200 sticky top-0 z-10">
                                {/* <th className="p-2"></th> */}
                                <th className="p-2">Name</th>
                                <th className="p-2">Content</th>
                                <th className="p-2">Image</th>
                                <th className="p-2">Positive Marks</th>
                                <th className="p-2">Negative Marks</th>
                                <th className="p-2">Display Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedQuestions.map((question: any) => (
                                <tr key={question.id} className="border-b border-gray-200 hover:bg-gray-50">
                                    {/* <td className="p-1">
                                        <Checkbox
                                            checked={checkedQuestions[question.id] || false} // Check if the question is selected
                                            onChange={() => handleCheckboxChange(question)} // Update checkbox state
                                        />
                                    </td> */}
                                    <td className="p-1 text-center">{question.name}</td>
                                    <td className="p-1 text-center" dangerouslySetInnerHTML={{ __html: question.content }} />

                                    <td className="p-1 flex justify-center">
                                        {question.image ? (
                                            <img
                                                src={question.image}
                                                alt={question.name}
                                                className="h-16 w-16 object-cover"
                                            />
                                        ) : (""
                                        )}
                                    </td>
                                    <td className="p-1 text-center">
                                        <input
                                            type="number"
                                            value={question.positive_marks || ''}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    question.id,
                                                    'positive_marks',
                                                    e.target.value
                                                )
                                            }
                                            className="border rounded p-1 w-16"
                                        />
                                    </td>
                                    <td className="p-1 text-center">
                                        <input
                                            type="number"
                                            value={question.negative_marks || ''}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    question.id,
                                                    'negative_marks',
                                                    e.target.value
                                                )
                                            }
                                            className="border rounded p-1 w-16"
                                        />
                                    </td>
                                    <td className="p-1 text-center">
                                        <input
                                            type="number"
                                            value={question.display_order || ''}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    question.id,
                                                    'display_order',
                                                    e.target.value
                                                )
                                            }
                                            className="border rounded p-1 w-16"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Pagination Controls */}
                <div className="sticky bottom-0 bg-white z-10">
                    <Pagination
                        count={Math.ceil(total / perPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        className="py-2"
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    />
                </div>
            </div>

            <div className="p-2 flex flex-row gap-6">
                <Button variant="contained" color="info" onClick={() => setIsEdit(false)}>
                    Back
                </Button>
                {selectedQuestions.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                        <div className="font-semibold px-1">({selectedQuestions.length})</div>
                    </Button>
                )}
                <Button onClick={handleClose}>Cancel</Button>
            </div>
        </div>
    );
};

export default ModalQuestionEditView;
