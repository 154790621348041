import * as React from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    Labeled,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, NullableBooleanInput,
} from 'react-admin';

import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const VideoSubjectEdit = () => {
    const { isUpdatable } = useIsPermission("VIDEO_SUBJECT");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<BannerTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput name={'Name'} source={'name'} />
                </SimpleForm>
            </Edit>
        </PermissionGuard>
    )
}

const BannerTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.video_subjects.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default VideoSubjectEdit;
