import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    ReferenceInput,
    SelectInput,
    useDataProvider,
    SimpleFormIterator,
    BooleanInput,
    ArrayInput, useNotify, useCreate, useUpdate, useUpdateMany, AutocompleteInput,
} from 'react-admin';
import { Questions } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import { QUESTION_TYPES, VIDEO_TYPES } from "../../common/constants";
import { Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { filterToQueryVideo, videoNameRender } from '../batch_schedules/BatchScheduleEdit';
import ImageInputWithType from '../../common/inputs/ImageInputWithType';

const OptionsEdit = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const navigate = useNavigate();
    const [create, { }] = useCreate();
    const [update] = useUpdate();
    const [loading, setLoading] = useState(false);
    const { data: oldOptions, refetch, isLoading, error } = useQuery(
        ['options_' + record.id, 'getList', { question_id: record.id }],
        () => dataProvider.getList('options', {
            filter: { question_id: record.id },
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'display_order', order: 'ASC' }
        })
    );

    if (!oldOptions || record.type === 'NUMERIC') {
        return <Typography>No Options Available</Typography>
    }
    const _oldOptions = JSON.parse(JSON.stringify(oldOptions));
    return <div>
        <SimpleForm sx={{ width: '100%' }} defaultValues={{ options: oldOptions?.data }} onSubmit={async (e) => {
            if (loading) {
                return;
            }
            const { options } = e;
            if (record.type === 'SINGLE') {
                const correctOptions = options?.filter((option: any) => !!option.is_correct);
                if (correctOptions?.length !== 1) {
                    notify(
                        'Exactly one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            if (record.type === 'MULTIPLE') {
                const correctOptions = options?.filter((option: any) => !!option.is_correct);
                if (!correctOptions?.length) {
                    notify(
                        'At least one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            const deletedOptions: any[] = [];
            const newOptions = options.filter((o: any) => !o.id);
            const updatedOptions = options.filter((o: any) => {

                let oldObject: any = null;
                _oldOptions.data.forEach((oo: any) => {
                    if (oo.id === o.id) {
                        oldObject = oo;
                    }
                });
                if (!oldObject) {
                    return false
                }
                if (o.image === oldObject.image && o.content === oldObject.content && o.is_correct === oldObject.is_correct && o.display_order === oldObject.display_order) {
                    return false
                }
                return o;
            });
            _oldOptions?.data.forEach((o: any) => {
                let found = false;
                options.forEach((oo: any) => {
                    if (oo.id === o.id) {
                        found = true;
                    }
                })
                if (!found) {
                    deletedOptions.push({ ...o })
                }
            })
            setLoading(true);

            Promise.all([...deletedOptions.map((d: any) => {
                return new Promise((resolve, reject) => {

                    update('options', { id: d.id, data: { deleted_at: new Date() }, previousData: d }, {
                        onSuccess: (eee) => {
                            resolve(eee);
                            notify(
                                'Options Updated Successfully', {
                                type: 'success'
                            }
                            );
                        },
                        onError: (error) => {
                            reject(error);
                            notify(
                                error,
                                {
                                    type: 'error'
                                }
                            );
                        }
                    });
                })
            }), ...updatedOptions.map((d: any) => {
                return new Promise((resolve, reject) => {
                    let oldObject = null;
                    _oldOptions.data.forEach((oo: any) => {
                        if (oo.id === d.id) {
                            oldObject = oo;
                        }
                    });
                    update('options', {
                        id: d.id, data: {
                            image: d.image,
                            content: d.content,
                            display_order: d.display_order,
                            is_correct: d.is_correct,
                        }, previousData: oldObject
                    }, {
                        onSuccess: (eee) => {
                            resolve(eee);

                            notify(
                                'Options Updated Successfully', {
                                type: 'success'
                            }
                            );
                        },
                        onError: (error) => {
                            reject(error);
                            notify(
                                error,
                                {
                                    type: 'error'
                                }
                            );
                        }
                    });
                })
            }), ...newOptions.map((d: any) => {
                return new Promise((resolve, reject) => {
                    create('options', {
                        data: {
                            image: d.image,
                            question_id: record.id,
                            content: d.content,
                            display_order: d.display_order,
                            is_correct: d.ic_correct
                        }
                    }, {
                        onSuccess: (eee) => {
                            resolve(eee);
                        },
                        onError: (error) => {
                            reject(error);
                        }
                    });
                })
            })]).then(() => {
                navigate(`/questions_bank`);
            }).catch((e) => {
                notify(
                    e,
                    {
                        type: 'error'
                    }
                );
            })
        }}>

            <ArrayInput source="options" sx={{
                border: '1px solid #aaaaaa',
                padding: '10px',
                width: '100%',
                borderRadius: '10px',
                marginBottom: '20px'
            }}>
                <SimpleFormIterator sx={{ width: '100% !important', 'RaSimpleFormIterator-form': { width: '100%' } }}>
                    <ImageInputWithType
                        source="image"
                        type="test"
                        typeId={""}
                    // label="Upload Image"
                    />
                    <TextInput fullWidth source={`display_order`} />
                    <HTMLEditorInput label={'Content'} fullWidth source={`content`} validate={[validHtml]} />
                    <BooleanInput defaultValue={false} fullWidth label={'Is Correct'}
                        source={`is_correct`} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </div>
}
const QuestionBankEdit = () => {
    let { questionId } = useParams();
    const [value, setValue] = React.useState(0);
    const [selectedMasterClass, setSelectedMasterClass] = React.useState('');
    const [selectedMasterSubject, setSelectedMasterSubject] = React.useState('');
    const [selectedMasterChapter, setSelectedMasterChapter] = React.useState('');
    const [selectedMasterTopic, setSelectedMasterTopic] = React.useState('');


    return <Edit title={<SectionTitle />} redirect={() => {
        return `questions_bank`
    }} resource={'questions'} id={questionId}>
        <Tabs value={value}>
            <Tab label="Details" onClick={() => setValue(0)} />
            <Tab label="Options" onClick={() => setValue(1)} />
        </Tabs>
        {
            value == 0 && <div>
                <SimpleForm toolbar={<EditToolbar />}>
                    <ImageInputWithType
                        source="image"
                        type="test"
                        typeId={""}
                    // multiple
                    />
                    <TextInput source={'name'} fullWidth />
                    <HTMLEditorInput fullWidth source={'content'} />
                    <SelectInput
                        source="type"
                        label={'Type'}
                        fullWidth
                        disabled
                        validate={[required()]}
                        choices={QUESTION_TYPES}
                    />
                    <TextInput source={'positive_marks'} fullWidth />
                    <TextInput source={'negative_marks'} fullWidth />
                    <HTMLEditorInput fullWidth source={'solution_description'} />
                    <ReferenceInput name={'Video'} source={'video_id'} reference={'videos'}>
                        <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                            limitChoicesToValue={true} debounce={500} fullWidth />
                    </ReferenceInput>
                    <ImageInputWithType
                        source="solution_image"
                        type="test"
                        typeId={""}
                    />

                    {/* Master Class Selection */}
                    <ReferenceInput label="Master Class" source="master_class_id" reference="master_classes" sort={{ field: "display_order", order: "ASC" }}>
                        <SelectInput
                            optionText="name"
                            fullWidth
                            onChange={(e) => setSelectedMasterClass(e.target.value)}
                        />
                    </ReferenceInput>

                    {/* Master Subject Selection */}
                    <ReferenceInput label="Master Subject" source="master_subject_id" reference="master_subjects" sort={{ field: "display_order", order: "ASC" }}>
                        <SelectInput
                            optionText="name"
                            fullWidth
                            onChange={(e) => {
                                setSelectedMasterSubject(e.target.value);
                                setSelectedMasterChapter('');
                                setSelectedMasterTopic('');
                            }}
                        />
                    </ReferenceInput>

                    {/* Master Chapter Selection (Filtered by Subject) */}
                    {selectedMasterSubject && (
                        <ReferenceInput label="Master Chapter" source="master_chapter_id" reference="master_chapters" filter={{ subject_id: selectedMasterSubject }} sort={{ field: "display_order", order: "ASC" }}>
                            <SelectInput
                                optionText="name"
                                fullWidth
                                onChange={(e) => {
                                    setSelectedMasterChapter(e.target.value);
                                    setSelectedMasterTopic('');
                                }}
                            />
                        </ReferenceInput>
                    )}

                    {/* Master Topic Selection (Filtered by Chapter) */}
                    {selectedMasterChapter && (
                        <ReferenceInput label="Master Topic" source="master_topic_id" reference="master_topics" filter={{ chapter_id: selectedMasterChapter }} sort={{ field: "display_order", order: "ASC" }}>
                            <SelectInput optionText="name" fullWidth onChange={(e) => setSelectedMasterTopic(e.target.value)} />
                        </ReferenceInput>
                    )}
                </SimpleForm>
            </div>
        }
        {
            value == 1 && <div>
                <OptionsEdit />
            </div>
        }
    </Edit>
};

const SectionTitle = () => {
    const record = useRecordContext<Questions>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.questions.name', { smart_count: 1 })} &quot;
            {record.id}&quot;
        </span>
    ) : null;
};

export default QuestionBankEdit;
