import * as React from 'react';
import {
    Create,
    TextInput,
    DateTimeInput,
    required,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    NumberField,
    useLocaleState,
    FormDataConsumer, NullableBooleanInput, BooleanField, BooleanInput
} from 'react-admin';
import ImageInput from '../../common/inputs/ImageInput';
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";

import { CLASSES, STATUS, TEST_CATEGORIES, TEST_TYPES } from "../../common/constants";
import PDFInput from "../../common/inputs/PDFInput";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import ImageInputWithType from '../../common/inputs/ImageInputWithType';


const TestCreate = () => {
    let {  testSeriesId } = useParams();
    if (!testSeriesId) {
        return <LoadingSection />
    }
    return <Create redirect={() => {
        return `test_serieses/${testSeriesId}/tests`
    }} resource={'tests'}>
        <SimpleForm defaultValues={{test_series_id: testSeriesId }}>
            {/* <ImageInputWithType
                source="image"
                type="test"
                typeId="testId"
                // label="Upload Image"
                multiple
            /> */}
            <ImageInput source="image" fullWidth />
            <TextInput source="name" fullWidth validate={[required()]} />
            <TextInput source="slug" fullWidth validate={[required()]} />
            <TextInput source="display_order" type={'number'} fullWidth validate={[required()]} />
            <SelectInput
                source="type"
                label={'type'}
                fullWidth
                validate={[required()]}
                choices={TEST_TYPES}
            />
            <SelectInput
                source="category"
                label={'Category'}
                fullWidth
                defaultValue={"OBJECTIVE"}
                validate={[required()]}
                choices={TEST_CATEGORIES}
            />
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUS}                
                defaultValue={"ACTIVE"}                
            />
            {/* <FormDataConsumer>
          {({ formData }) => {
            if (formData.category === 'SUBJECTIVE') {
              return (
                <PDFInput source="question_file" label={'Questions File'} fullWidth validate={[required()]}/>
              );
            }
            return null;
          }}
        </FormDataConsumer> */}
            <DateTimeInput source={'start_time'} fullWidth validate={[required()]} />
            <DateTimeInput source={'end_time'} fullWidth validate={[required()]} />
            <TextInput type={'number'} source={'max_duration_in_minutes'} fullWidth validate={[required()]} />
            <DateTimeInput source={'valid_till'} label={'Test Valid Till'} fullWidth />
            <BooleanInput source={'is_demo'} defaultValue={false} />
            <ReferenceInput label="Test Series" source="test_series_id" reference="test_serieses">
                <SelectInput optionText="title" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <HTMLEditorInput source={'general_instructions'} fullWidth validate={[required(), validHtml]} />
            <HTMLEditorInput source={'test_instructions'} fullWidth validate={[required(), validHtml]} />
            <PDFInput multiple="true" source="solution_files" label={'Solution File'} fullWidth />
            <PDFInput multiple="true" source="question_file" label={'Question File'} fullWidth />

        </SimpleForm>
    </Create>
};

export default TestCreate;
