import { useEffect } from "react";
import { BASE_URL } from "../common/constants";

export const getCurrentUserPermission = async (token: string) => {
    try {
        const response = await fetch(BASE_URL + '/admin/permissions/access', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch permissions');
        }

        const data = await response.json();
        localStorage.setItem('permissions', JSON.stringify(data?.data));

        return Promise.resolve();
    } catch (error) {
        console.error('Error fetching permissions:', error);
        return Promise.reject(error);
    }
};

const useFetchPermissions = () => {
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            getCurrentUserPermission(token).catch((error) => {
                console.error("Permission fetch failed:", error);
            });
        }
    }, []); 
};

export default useFetchPermissions;
