import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  ReferenceField,
  CreateButton,
  ExportButton,
  useRecordContext,
  ImageField,
  NumberField,
  ReferenceInput,
  RichTextField,
  useGetOne,
} from "react-admin";
import { Link, useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import {
  Add,
  Description,
  Edit,
  PictureAsPdf,
  Videocam,
} from "@mui/icons-material";
import { Sections } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { CustomDelete } from "../../common/CustomDelete";
import { ChevronRightIcon } from "lucide-react";

const Actions = ({
  programId,
  testSeriesId,
  sectionId,
  testId,
}: {
  programId: string;
  testId: string;
  sectionId: string;
  testSeriesId: string;
}) => {
  const navigate = useNavigate();
  return (
    <>
    </>
    // <div style={{ display: "flex" }}>
    //   {["SINGLE", "MULTIPLE", "NUMERIC"].map((type, index) => {
    //     return (
    //       <Button
    //         key={index}
    //         startIcon={<Add />}
    //         onClick={() => {
    //           const data = { type };
    //           navigate(
    //             `/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/create?source=${encodeURIComponent(
    //               JSON.stringify(data)
    //             )}`
    //           );
    //         }}
    //         sx={{ padding: "0 5px" }}
    //       >
    //         Add a {type}
    //       </Button>
    //     );
    //   })}
    // </div>
  );
};

const ListActions = (props: {
  programId: string;
  testId: string;
  sectionId: string;
  testSeriesId: string;
}) => {
  return (
    <TopToolbar>
      {/* <Actions {...props} /> */}
      <ExportButton />
    </TopToolbar>
  );
};

const EditButton = () => {
  const navigate = useNavigate();
  const record: Sections = useRecordContext<Sections>();
  const { testSeriesId, programId, sectionId, testId } = useParams();
  // console.log("testSeries")

  return (
    <Button
      startIcon={<Edit />}
      onClick={(e) => {
        navigate(
          `/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/${record.id}/edit`
        );
      }}
    >
      Edit
    </Button>
  );
};

const QuestionList = () => {
  const navigate = useNavigate();
  const { testSeriesId, programId, sectionId, testId } = useParams();
  if (!testId) {
    return <LoadingSection />;
  }
  return (
    <List
    title={<QuestionListTitle/>}
      empty={
        <NoDataSection
          resource={"section_question_mappings"}
          actions={
            <Actions
              programId={programId || ""}
              testId={testId || ""}
              sectionId={sectionId || ""}
              testSeriesId={testSeriesId || ""}
            />
          }
          onCreate={() => {
            navigate(
              `/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/create`
            );
          }}
        />
      }
      sort={{ field: "display_order", order: "ASC" }}
      resource={"section_question_mappings"}
      actions={
        <ListActions
          sectionId={sectionId || ""}
          programId={programId || ""}
          testSeriesId={testSeriesId || ""}
          testId={testId || ""}
        />
      }
      filter={{
        section_id: sectionId,
      }}
    >
      <Datagrid>
        <ReferenceField reference={"questions"} label={"Image"} source={"question_id"}>
          <ImageField source="image" />
        </ReferenceField>
        <ReferenceField reference={"questions"} label={"Content"} source={"question_id"}>
          <RichTextField source="content" />
        </ReferenceField>
        <NumberField source="display_order" />
        <TextField source="type" />
        <FormattedDateTimeField source="created_at" />
        <EditButton />
        <CustomDelete />
      </Datagrid>
    </List>
  );
};

const QuestionListTitle = () => {
  const { testSeriesId, testId, sectionId } = useParams();
  const { data: testSeries, isLoading: loadingTestSeries } = useGetOne("test_serieses", { id: testSeriesId });
  const { data: test, isLoading: loadingTest } = useGetOne("tests", { id: testId });
  const { data: section, isLoading: loadingSection } = useGetOne("sections", { id: sectionId });

  if (loadingTestSeries || loadingTest || loadingSection) return <span>...</span>;

  return (
      <span className="flex flex-row items-center  font-semibold">
          <Link to={`/test_serieses`} className="text-blue-600 hover:underline">
              ...
          </Link>
          <ChevronRightIcon />
          <Link to={`/test_serieses/${testSeriesId}/tests`} className="text-blue-600 hover:underline">
              {test?.name}
          </Link>
          <ChevronRightIcon />
          <Link to={`/test_serieses/${testSeriesId}/tests/${testId}/sections`} className="text-blue-600 hover:underline">
              {section?.name}
          </Link>
          <ChevronRightIcon />
          <span>Questions</span>
      </span>
  );
};
export default QuestionList;
