import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext, Filter, TextInput, SelectInput,
    NumberField,
    useGetOne,
    Link,
} from 'react-admin';
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import { Topics } from "../../custom";
import ContentsLinkField from "./ContentsLinkField";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import StatusTags from "../../common/StatusTags";
import ContentCounts from "../../common/ContentCounts";
import { STATUS } from "../../common/constants";
import { ChevronRightIcon } from 'lucide-react';

const ListActions = ({
    programId,
    subjectId,
    chapterId
}: { programId: string, chapterId: string, subjectId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/create`)
        }} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Topics = useRecordContext<Topics>();
    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.chapter_id}/topics/${record.id}/edit`)
    }}>
        Edit
    </Button>

};

const ListFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <SelectInput
            source="status"
            label={'Status'}
            choices={STATUS}
            alwaysOn
        />
    </Filter>
);

const TopicList = () => {
    const navigate = useNavigate();
    const { subjectId, programId, chapterId } = useParams();
    if (!chapterId) {
        return <LoadingSection />
    }
    return (
        <List
            title={<TopicTitle />}
            filters={<ListFilter />}

            empty={<NoDataSection resource={'topics'} onCreate={() => {
                navigate(`/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/create`)
            }} />}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'topics'} actions={
                <ListActions programId={programId || ''} subjectId={subjectId || ''} chapterId={chapterId || ''} />
            } filter={{
                program_id: programId,
                subject_id: subjectId,
                chapter_id: chapterId,
            }}>
            <Datagrid>

                <TextField source="name" />
                <TextField source="slug" />
                <StatusTags source="status" label={'Status'} />
                <NumberField source="display_order" />
                {/* <StatusTags source="program_status" label={'Program Status'}/>
                <StatusTags source="subject_status" label={'Subject Status'}/>
                <StatusTags source="chapter_status" label={'Chapter Status'}/> */}
                <ReferenceField reference={'chapters'} source={'chapter_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference={'subjects'} source={'subject_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <ContentCounts />
                <FormattedDateTimeField source="created_at" />

                <ContentsLinkField />
                <EditButton />
                <DeleteWithDialogAction />
            </Datagrid>
        </List>
    );
};
const TopicTitle = () => {
    const { programId, subjectId, chapterId } = useParams();

    const { data: program, isLoading: programLoading } = useGetOne("programs", { id: programId });
    const { data: subject, isLoading: subjectLoading } = useGetOne("subjects", { id: subjectId });
    const { data: chapter, isLoading: chapterLoading } = useGetOne("chapters", { id: chapterId });

    if (programLoading || subjectLoading || chapterLoading) return <span>Loading...</span>;

    return (
        <span className="flex flex-row items-center text-lg font-semibold ">
            <Link to="/programs" className="text-blue-600 hover:underline">
                ...
            </Link>
            <ChevronRightIcon />
            <Link to={`/programs/${programId}/subjects`} className="text-blue-600 hover:underline">
                {subject?.name}
            </Link>
            <ChevronRightIcon />
            <Link to={`/programs/${programId}/subjects/${subjectId}/chapters`} className="text-blue-600 hover:underline">
                {chapter?.name}
            </Link>
            <ChevronRightIcon />
            <span>Topics</span>
        </span>
    );
};


export default TopicList;
