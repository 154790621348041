import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const RoleEdit = () => {
  const { isUpdatable } = useIsPermission("ACCESS_CONTROL");
  return (
    <PermissionGuard permission={isUpdatable}>
      <Edit title={<BannerTitle />}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput source="name" fullWidth />
        </SimpleForm>
      </Edit>
    </PermissionGuard>
  )
}

const BannerTitle = () => {
  const record = useRecordContext<any>();
  return record ? (
    <span>
      {record?.name || record?.title}
    </span>
  ) : null;
};

export default RoleEdit;
