import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PermissionGuard = ({ permission, children }: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!permission) {
      navigate("/");
    }
  }, [permission, navigate]);

  return permission ? children : null;
};

export default PermissionGuard;
