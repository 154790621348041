import { useState, useEffect } from "react";

interface PermissionItem {
  permissions: {
    name: string;
    type: string;
  };
}

interface PermissionResult {
  isReadable: boolean;
  isDeletable: boolean;
  isWritable: boolean;
  isUpdatable: boolean;
}

const getStoredPermissions = (): PermissionItem[] => {
  try {
    const storedPermissions = localStorage.getItem("permissions");
    return storedPermissions ? JSON.parse(storedPermissions) : [];
  } catch (error) {
    console.error("Error parsing permissions from localStorage", error);
    return [];
  }
};

const useIsPermission = (permissionName: string): PermissionResult => {
  const [permissionsData, setPermissionsData] = useState<PermissionItem[]>(getStoredPermissions);

  useEffect(() => {
    const fetchPermissions = () => {
      setPermissionsData(getStoredPermissions());
    };

    const handleStorageChange = () => fetchPermissions();
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const permissionTypes = permissionsData
    .filter((item) => item.permissions.name === permissionName)
    .map((item) => item.permissions.type);

  return {
    isReadable: permissionTypes.includes("READ"),
    isDeletable: permissionTypes.includes("DELETE"),
    isWritable: permissionTypes.includes("WRITE"),
    isUpdatable: permissionTypes.includes("UPDATE"),
  };
};

export default useIsPermission;
