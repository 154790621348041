import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  EditButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  usePermissions,
} from "react-admin";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import useIsPermission from "../../hooks/useIsPermission";
import PermissionBasedActions from "../../common/PermissionBasedActionsButton";

const OrganizationResourcesList = () => {
  const { isWritable, isDeletable, isUpdatable } = useIsPermission("ORGANIZATION_RESOURCE");

  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      actions={<PermissionBasedActions isWritable={isWritable} />}
    >
      <Datagrid>
        <ThumbnailField source="thumbnail" />
        <TextField source="title" />
        <ReferenceField
          reference="ce_blog_topics"
          label="Blog Topic"
          source="blog_topic_id"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="slug" />
        <NumberField source="display_order" />
        <ReferenceField reference="users" label="Author" source="created_by_id">
          <FullNameField source="first_name" />
        </ReferenceField>
        <FormattedDateTimeField source="created_at" />

        {isUpdatable && <EditButton />}
        {isDeletable && <DeleteWithDialogAction />}
      </Datagrid>
    </List>
  );
};

export default OrganizationResourcesList;
