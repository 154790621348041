import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, DateField, BooleanField, ReferenceManyCount, Link, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';

const VideosLinkComponent = () => {
    const record = useRecordContext();
    return <Link
        to={`/videos?filter=${encodeURIComponent(JSON.stringify({ video_subject_id: record.id }))}`}>
        Videos
        (
        <ReferenceManyCount
            label="Videos"
            reference="videos"
            target="video_subject_id"
        />
        )
    </Link>
}
const VideoSubjectList = () => {
    const { isDeletable, isWritable, isUpdatable } = useIsPermission("VIDEO_SUBJECT");

    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
            >
                <Datagrid>
                    <TextField source="name" />
                    <FormattedDateTimeField source="created_at" />
                    <VideosLinkComponent />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default VideoSubjectList;
