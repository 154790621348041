import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, NullableBooleanInput,
} from 'react-admin';

import { Banners, } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const BannerEdit = () => {
    const { isUpdatable } = useIsPermission("BANNERS");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<BannerTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput name={'Display Order'} source={'display_order'} />
                    <NullableBooleanInput source="hidden" fullWidth label={'Hidden'} />
                    <ImageInput source={'image'} />
                    <TextInput source="redirect_url" fullWidth />
                </SimpleForm>
            </Edit>
        </PermissionGuard>
    )
}

const BannerTitle = () => {
    const record = useRecordContext<Banners>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.banners.name', { smart_count: 1 })} &quot;
            {record.display_order}&quot;
        </span>
    ) : null;
};

export default BannerEdit;
