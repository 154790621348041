import { AuthProvider } from 'react-admin';
import { BASE_URL, GQL_URL, } from "./common/constants";
import { getCurrentUserPermission } from './hooks/useFetchPermissions';

const authProvider: AuthProvider = {
    login: async ({ phone, password, session_id }) => {
        const data = await fetch(BASE_URL + '/auth/admin-login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone, password,"spirit_code": "asdiajsdojasodij", "platform": "WEB"}),
        }).then((q: any) => {
            const token = q.headers.get('authorization')?.split(' ')[1];
            getCurrentUserPermission(token).finally(() => {
                localStorage.setItem('token', token);
            })
            return q.json()
        });
        localStorage.setItem('user', JSON.stringify(data));
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('user') ? Promise.resolve() : Promise.reject(),
    getPermissions: async () => {
        const permissionsData = await fetch(GQL_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                query: `
                   query AllPermissionQuery {
                      __schema {
                        queryType{
                          fields{
                            name
                          }
                        }
                        mutationType{
                          fields{
                            name
                          }
                        }
                      }
                    }
                `,
                variables: {},
            }),
        }).then((q) => q.json());
        const _permissions = [...permissionsData?.data?.__schema?.queryType?.fields?.map((m: any) => m.name), ...permissionsData?.data?.__schema?.mutationType?.fields?.map((m: any) => m.name)]
        return {
            base: _permissions || []
        };
    },
    
    getIdentity: async (): Promise<any> => {
        return localStorage.getItem('user') ? Promise.resolve(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null) : Promise.resolve()
    },
};

export default authProvider;
