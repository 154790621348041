import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton,
    useRecordContext,
    ImageField,
    NumberField,
    ReferenceInput,
    RichTextField,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import {
    Add,
    Description,
    Edit,
    PictureAsPdf,
    Videocam,
} from "@mui/icons-material";
import { Sections } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { CustomDelete } from "../../common/CustomDelete";

const Actions = ({
    exerciseId
}: {
    exerciseId: string;
}) => {
    const navigate = useNavigate();
    return (
        <>
        </>
        // <div style={{ display: "flex" }}>
        //   {["SINGLE", "MULTIPLE", "NUMERIC"].map((type, index) => {
        //     return (
        //       <Button
        //         key={index}
        //         startIcon={<Add />}
        //         onClick={() => {
        //           const data = { type };
        //           navigate(
        //             `/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/create?source=${encodeURIComponent(
        //               JSON.stringify(data)
        //             )}`
        //           );
        //         }}
        //         sx={{ padding: "0 5px" }}
        //       >
        //         Add a {type}
        //       </Button>
        //     );
        //   })}
        // </div>
    );
};

const ListActions = (props: {
    exerciseId: string;
}) => {
    return (
        <TopToolbar>
            {/* <Actions {...props} /> */}
            <ExportButton />
        </TopToolbar>
    );
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Sections = useRecordContext<Sections>();
    const { exerciseId } = useParams();

    return (
        <Button
            startIcon={<Edit />}
            onClick={(e) => {
                navigate(
                    `/exercises/${exerciseId}/questions/${record.id}/edit`
                );
            }}
        >
            Edit
        </Button>
    );
};

const ExerciseQuestionList = () => {
    const navigate = useNavigate();
    const { exerciseId } = useParams();
    if (!exerciseId) {
        return <LoadingSection />;
    }
    return (
        <List
            empty={
                <NoDataSection
                    resource={"section_question_mappings"}
                    actions={
                        <Actions
                            exerciseId={exerciseId || ""}
                        />
                    }
                    onCreate={() => {
                        navigate(
                            `exercise/${exerciseId}/questions/create`
                        );
                    }}
                />
            }
            sort={{ field: "display_order", order: "ASC" }}
            resource={"section_question_mappings"}
            actions={
                <ListActions
                exerciseId={exerciseId || ""}
                />
            }
            filter={{
                test_id: exerciseId,
            }}
        >
            <Datagrid>
                <ReferenceField reference={"questions"} label={"Image"} source={"question_id"}>
                    <ImageField source="image" />
                </ReferenceField>
                <ReferenceField reference={"questions"} label={"Content"} source={"question_id"}>
                    <RichTextField source="content" />
                </ReferenceField>
                <NumberField source="display_order" />
                <TextField source="type" />
                <FormattedDateTimeField source="created_at" />
                <EditButton />
                <CustomDelete />
            </Datagrid>
        </List>
    );
};
export default ExerciseQuestionList;
