import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    minValue, DateTimeInput,
    required,
} from 'react-admin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoModal from './InfoModal';
import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const CampaignEdit = () => {
    const [open, setOpen] = React.useState(false);
    const { isUpdatable } = useIsPermission("CAMPAIGNS");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<CampaignTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <div onClick={() => setOpen(true)} className="w-full px-5 flex flex-row gap-1 items-center justify-end">
                        <InfoOutlinedIcon className='cursor-pointer my-3 justify-end ' sx={{ color: 'blue' }} />
                        <p>info </p>
                    </div>
                    <TextInput source="name" fullWidth validate={[minValue(0)]} />
                    <TextInput source="title" fullWidth validate={[minValue(0)]} />
                    <TextInput source="message" fullWidth validate={[minValue(0)]} multiline rows={5} />
                    <DateTimeInput source="starts_at" validate={[required()]} fullWidth />

                </SimpleForm>
            </Edit>
            <InfoModal open={open} setOpen={setOpen} />
        </PermissionGuard>
    )
};

const CampaignTitle = () => {
    const record = useRecordContext<any>();
    return record ? (
        <span>
            {record.display_order}&quot;
        </span>
    ) : null;
};

export default CampaignEdit;
