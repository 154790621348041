import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';


const FAQList = () => {
    const { isUpdatable, isDeletable,isWritable} = useIsPermission("FAQS");
    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
                sort={{ field: 'display_order', order: 'ASC' }}>
                <Datagrid>
                    <TextField source="question" />
                    <TextField source="answer" />
                    <TextField source="display_order" />
                    <FormattedDateTimeField source="created_at" />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default FAQList;
