import * as React from "react";
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  SelectInput,
  regex,
  useNotify,
  useRedirect,
  useGetList,
} from "react-admin";
import { BASE_URL, COUNTRY_CODES, USER_STATUSES } from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const UserCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [role, setRole] = React.useState("");

  const validateNameNoNumbers = regex(
    /^[A-Za-z\s]+$/,
    "Name cannot contain numbers."
  );

  const validatePhoneNumber = (value: string) => {
    if (!/^\d{10}$/.test(value)) {
      return "Phone number must be exactly 10 digits and contain only numbers.";
    }
    return undefined;
  };


  const capitalizeFirstLetter = (value: string) => {
    if (!value) return value;
    return value.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const { data: rolesList = [] } = useGetList<any>("roles", {
    sort: { field: "watch_date", order: "ASC" },
    pagination: { page: 1, perPage: 200 },
  });

  const predefinedRoles = [
    { name: "STUDENT", id: "4d8c2d80-a3e9-4d1a-be12-08def4829437" },
  ];

   const roleOptions = [
    ...predefinedRoles,
    ...rolesList.map((role) => ({
      name: role.name,
      id: role.id,
    })),
  ];

  const handleSubmit = async (values: any) => {
    try {
      const finalData = {
        ...values,
        role_id: role, 
      };

      const response = await fetch(BASE_URL + "/admin/users", {
        method: "POST",
        body: JSON.stringify(finalData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Cookie': document.cookie 
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create user");
      }

      const data = await response.json();

      notify("User created successfully", { type: "success" });
      redirect("/users");
    } catch (error: any) {
      console.error("Error creating user:", error);
      notify(`Error: ${error.message}`, { type: "warning" });
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        {/* Role Selection */}
        <FormControl fullWidth required>
          <InputLabel>Role</InputLabel>
          <Select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            fullWidth
          >
            {roleOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Other Inputs */}
        <ImageInput source="profile_image" fullWidth />
        <TextInput
          source="first_name"
          validate={[required(), validateNameNoNumbers]}
          format={capitalizeFirstLetter}
          fullWidth
        />
        <TextInput source="last_name" format={capitalizeFirstLetter} fullWidth />
        <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
        <TextInput
          source="phone"
          validate={[required(), validatePhoneNumber]}
          fullWidth
          inputProps={{ maxLength: 10 }}
          onChange={(e) => {
            const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
            e.target.value = onlyNumbers; // Set cleaned value
          }}
        />


        <TextInput source="registration_number" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="admission_number" fullWidth />
        <SelectInput required source="status" choices={USER_STATUSES} fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
