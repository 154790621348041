import { AccountCircle } from "@mui/icons-material";
import RoleList from "./RoleList";
import RoleCreate from "./RoleCreate";
import RoleEdit from "./RoleEdit";

export default {
    list: RoleList,
    edit: RoleEdit,
    create: RoleCreate,
    icon: AccountCircle,
};
