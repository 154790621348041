import {
    AutocompleteInput,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useCreate,
    useGetOne,
    useNotify,
} from "react-admin";
import { Pdfs } from "../../custom";
import Button from "@mui/material/Button";
import { useController } from "react-hook-form";
import { PictureAsPdf } from "@mui/icons-material";
import { useEffect, useState } from "react";
import PDFInput from "./PDFInput";


const PdfNameRender = (choice: Pdfs) => `${choice?.title} `;
const filterToQueryPdf = (searchText: string) => searchText ? ({ title: `${searchText}` }) : ({ code: `${'NOT_AVAILABLE'}` });
const PdfModal = (props: any) => {
    const [title, setTitle] = useState(props?.title || '');
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfCategory, setPdfCategory] = useState('');
    const notify = useNotify();
    const [create, { isLoading }] = useCreate();
    return <div
        className={'fixed top-0 left-0 right-0 bottom-0 z-[100] bg-gray-400 bg-opacity-50 flex flex-col items-center justify-center'}>
        <div className={'rounded-md bg-white shadow-md w-[90vw] md:w-[40vw] '}>
            <SimpleForm toolbar={<></>} defaultValues={{ title }}>
                <div className="w-full">
                    <TextInput fullWidth source={'title'} value={title} onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                        required />
                    <FormDataConsumer>
                        {
                            ({ formData }) => {
                                return <PDFInput
                                    fullWidth
                                    formData={formData}
                                    source={'url'}
                                    setPdfUrl={setPdfUrl}
                                />

                            }
                        }
                    </FormDataConsumer>
                    <ReferenceInput reference={'pdf_categories'} source={'category_id'}>
                        <SelectInput
                            fullWidth
                            optionText="name"
                            required
                            onChange={(e) => setPdfCategory(e.target.value)}
                        />
                    </ReferenceInput>

                    <div className={'flex space-x-2 mt-2 border-t pt-4'}>
                        <Button
                            disabled={isLoading || !title || !pdfUrl || !pdfCategory}
                            onClick={() => {
                                if (!title || !pdfUrl) {
                                    notify(
                                        'Please fill Title and pdf',
                                        {
                                            type: 'error',
                                            messageArgs: {},
                                        }
                                    );
                                    return;
                                }
                                create('pdfs', {
                                    data: {
                                        title: title,
                                        url: pdfUrl,
                                        category_id:pdfCategory

                                    }
                                }, {
                                    onSuccess: (e) => {
                                        if (props.onClose) {
                                            props.onClose(e.id);
                                        }
                                    }
                                });
                            }}>
                            Attach Pdf
                        </Button>
                        <Button color={'error'} onClick={() => {
                            if (props.onClose) {
                                props.onClose('');
                            }
                        }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </SimpleForm>
        </div>
    </div>
}

const PdfAutocompleteInput = (props: any) => {
    const [createPdf, setCreatePdf] = useState(false)
    const i = useController({ name: props.source });
    const pdfId = i.field.value;
    const { data: selectedPdf } = useGetOne("pdfs", { id: pdfId }, { enabled: !!pdfId });

    useEffect(() => {
        if (selectedPdf) {
            props?.setSelectedPdfUrl(selectedPdf?.url);
        }
    }, [selectedPdf]);


    if (i.field.value) {
        return <div className={'w-full p-4 rounded-md bg-gray-50 mb-4 border border-gray-200 flex justify-between items-center'}>
            <PictureAsPdf className={'text-black'} style={{ fontSize: '100px' }} />
            <Button color={'error'} onClick={() => {
                i.field.onChange('');
                props?.setSelectedPdfUrl("");
            }}>Change Pdf</Button>
        </div>
    }
    return <div className={'w-full'}>
        <>
            {
                createPdf && <PdfModal title={props?.formData?.title} onClose={(e: any) => {
                    if (e) {
                        i.field.onChange(
                            e
                        );
                    }
                    setCreatePdf(false);
                }} />
            }
            <div className={'flex justify-between items-center'}>
                <div className={'text-xs text-gray-500'}>Search by Pdf Title</div>
                <div onClick={() => {
                    setCreatePdf(true)
                }}
                    className={'text-indigo-500 text-sm font-medium py-1 cursor-pointer px-2 hover:bg-indigo-50 transition-all duration-200 rounded'}>Add
                    A New Pdf
                </div>
            </div>
            <ReferenceInput source={props.source || 'pdf_id'} label={props.label || 'Pdfs'}
                reference={'pdfs'}>
                <AutocompleteInput optionText={PdfNameRender} filterToQuery={filterToQueryPdf}
                    limitChoicesToValue={true} debounce={500} fullWidth />
            </ReferenceInput>

        </>
    </div>
}
export default PdfAutocompleteInput;
