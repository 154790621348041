import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import { ListItem, Tooltip } from "@mui/material";
import { MenuItemLink, useTranslate } from "react-admin";

const MultiLevelMenu = ({ option, permissions = [] }: { option: Record<string, any>, permissions?: any[] }) => {
  const translate = useTranslate();
  const { children } = option;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  // Function to check if the user has permission for a module
  const hasPermission = (module?: string) => {
    if (module === "" || module === undefined) return true; // Always allow if permission is empty
    return permissions.some((perm: any) => perm.permissions?.name === module);
  };
  

  return (
    <React.Fragment>
      <Tooltip title={translate(option.name)} placement="left">
        <ListItem button onClick={handleClick}>
          <div className="flex flex-row justify-between w-full">
            <div className="flex text-gray-600">
              {option.icon}
              <div className="text-[14px] pl-4">{translate(option.name)}</div>
            </div>
            <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
          </div>
        </ListItem>
      </Tooltip>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div className="ml-3">
            {children?.map((child: any) => {
              const isAllowed = hasPermission(child.permission);

              return (
                <div key={child.name} className="flex justify-between  items-center">
                  {isAllowed ? (
                    <div className="w-full">
                      <MenuItemLink
                        to={child.to}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(child.name, { smart_count: 2 })}
                        leftIcon={child.icon}
                        target={child?.openInNewTab ? "_blank" : "_self"}
                      />
                    </div>
                  ) : (
                    <div className="flex  w-full justify-between items-center text-sm cursor-not-allowed opacity-60 px-4 py-2">
                      {child?.icon}
                      <div className="flex w-full justify-between">
                        <span className="ml-4">{translate(child.name, { smart_count: 2 })}</span>
                        <LockIcon style={{ fontSize: "15px" }} className="ml-2" />
                      </div>
                    </div>

                  )}
                </div>
              );
            })}
          </div>
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default MultiLevelMenu;
