import { Button } from "@mui/material";
import { useState } from "react";
import { CheckCircle, UploadFileOutlined } from "@mui/icons-material";
import { BASE_URL } from "../../common/constants";
import { LoadingSection } from "../../common/LoadingSection";

export const uploadFile = (questions: any,) => {
    const formData = new FormData();
    formData.append("questions", questions);

    return fetch(BASE_URL + `/admin/tests/question-bank/upload/extract-questions`, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};


const BulkQuestionsViaDocInput = (props: any = {}) => {
    const [loading, setLoading] = useState(false);
    const [docFile, setDocFile] = useState(null as any);
    return (
        <>
            <div className="" style={{ position: "relative", }}>
                {
                    loading ? <LoadingSection /> :

                        <>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        docFile ? <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                            <UploadFileOutlined />
                                            <Button onClick={async (e) => {
                                                try {
                                                    setLoading(true);
                                                    const res = await uploadFile(docFile);
                                                    if (res?.status === 201 || res?.status === 200) {
                                                        const data = await res.json();
                                                        props?.setQuestionsList(data?.questions)
                                                        // props.onSuccess();
                                                    } else {
                                                        console.log(res);
                                                        alert("Unable to upload questions, check console for error");
                                                    }
                                                } catch (error) {
                                                    console.log(error);

                                                    alert("Unable to upload questions, check console for error");
                                                }
                                                setLoading(false);
                                            }}>
                                                Upload Doc File
                                            </Button>
                                        </div> :
                                            <Button disabled={loading}>
                                                Add Word file
                                                <input
                                                    accept={".doc,.docx"}
                                                    placeholder={props.placeholder}
                                                    style={{
                                                        position: "absolute",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        top: "0",
                                                        opacity: "0",
                                                    }}
                                                    multiple={false}
                                                    value={""}
                                                    onChange={async (e) => {
                                                        const file = e.target.files?.[0];
                                                        setDocFile(file);
                                                    }}
                                                    type={"file"}
                                                />
                                            </Button>

                                    }
                                </div>

                            </div>
                        </>
                }

            </div>
        </>
    );
};
export default BulkQuestionsViaDocInput;
