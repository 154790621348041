import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { BASE_URL } from "../../common/constants";

// Custom HTTP client with Authorization headers
const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const token = localStorage.getItem("token");
    if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};

// Initialize base REST provider
const baseRestProvider = simpleRestProvider(`${BASE_URL}/admin`, httpClient);

export const restDataProvider = {
    getList: async (resource: string, params: any) => {
        if (!["roles", "permissions"].includes(resource)) {
            return baseRestProvider.getList(resource, params);
        }

        const { json } = await httpClient(`${BASE_URL}/admin/${resource}`, { method: "GET" });

        if (!json.success || !Array.isArray(json.data)) {
            return Promise.reject(`Invalid API response for ${resource}`);
        }

        return { data: json.data, total: json.data.length };
    },

    getOne: (resource: string, params: any) => baseRestProvider.getOne(resource, params),

    create: async (resource: string, params: any) => {
        if (!["roles", "permissions"].includes(resource)) {
            return baseRestProvider.create(resource, params);
        }

        const { json } = await httpClient(`${BASE_URL}/admin/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
        });

        if (!json.success || !json.data || !json.data.id) {
            return Promise.reject(`Invalid create response for ${resource}`);
        }

        return { data: json.data }; // Ensure the response contains the correct format
    },

    update: (resource: string, params: any) => baseRestProvider.update(resource, params),
    delete: (resource: string, params: any) => baseRestProvider.delete(resource, params),
};
