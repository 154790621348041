import { useParams } from 'react-router';
import { LoadingSection } from '../../common/LoadingSection';
import { Create, FormDataConsumer, ImageInput, SimpleForm, TextInput, required } from 'react-admin';
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import VideoAutocompleteInput from '../../common/inputs/VideoAutocompleteInput';

const DemoVideoCreate = () => {
  let { categoryId } = useParams();
  if (!categoryId) {
    return <LoadingSection />
  }
  return <Create redirect={() => {
    return `demo_video_categories/${categoryId}/demo_video`
  }} resource={'demo_videos'}>
    <SimpleForm
      defaultValues={{ demo_video_category_id: categoryId }}
    >
      <ImageInput source="image" fullWidth />
      <TextInput source="title" fullWidth validate={[required()]} />
      <TextInput source="video_url" fullWidth validate={[required()]} />
      <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]} />
      {/* <ReferenceInput label="Program" source="program_id" reference="programs">
        <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
      </ReferenceInput> */}
    </SimpleForm>
  </Create>
}

export default DemoVideoCreate
