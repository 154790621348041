import * as React from 'react';
import {
    List,
    EditButton,
    Datagrid,
    TextField, DateField, ReferenceField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';

const CampaignList = () => {
    const { isUpdatable, isDeletable, isWritable } = useIsPermission("CAMPAIGNS");
    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
            >
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="title" />
                    <TextField source="message" />
                    <ReferenceField reference={'segmentations'} source={'segment_id'}>
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField source="starts_at" />
                    <FormattedDateTimeField source="created_at" />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default CampaignList;
