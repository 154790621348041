import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    ReferenceInput,
    SelectInput,
    NullableBooleanInput,
    DateTimeInput,
    BooleanInput,
    FormDataConsumer,
    minValue,
} from 'react-admin';

import { EditToolbar } from "../../common/EditToolbar";
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';

import { Ecom_Coupons } from "../../custom";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const CouponEdit = () => {
    const { isUpdatable } = useIsPermission("COUPONS");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<CouponTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput source="code" fullWidth validate={[required()]} />
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => formData.discount_in_percentage ?
                                <>
                                    <TextInput source="discount_in_percentage" prefix={'%'} type={'number'} validate={[minValue(0)]} fullWidth />
                                    <TextInput source="max_discount_price" prefix={'₹'} type={'number'} validate={[minValue(0)]} fullWidth />
                                </> : <TextInput source="discount_in_price" prefix={'₹'} type={'number'} validate={[minValue(0)]} fullWidth />
                        }
                    </FormDataConsumer>
                    <TextInput source="max_total_usages" type={'number'} validate={[minValue(0)]} fullWidth />
                    <TextInput source="max_usage_by_per_student" type={'number'} validate={[minValue(0)]} fullWidth />
                    <ReferenceInput label="Category" source="category_id" reference="ecom_categories">
                        <SelectInput optionText="name" disabled fullWidth />
                    </ReferenceInput>
                    <ReferenceInput label="Product Template" source="product_template_id"
                        reference="ecom_product_templates">
                        <SelectInput optionText="name" disabled fullWidth />
                    </ReferenceInput>
                    <NullableBooleanInput source={'hidden'} fullWidth />
                    <DateTimeInput source={'started_at'} validate={[required()]} fullWidth />
                    <DateTimeInput source={'expiring_at'} fullWidth />
                    <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]} />
                </SimpleForm>
            </Edit>
        </PermissionGuard>
    );
};

const CouponTitle = () => {
    const record = useRecordContext<Ecom_Coupons>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.coupons.name', { smart_count: 1 })} &quot;
            {record.code}&quot;
        </span>
    ) : null;
};

export default CouponEdit;
