import {

    List,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';

const PermissionsList = () => {
    return (
        <div>
            <List
            // sort={{ field: 'created_at', order: 'DESC' }}
            >
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="type" />
                    <FormattedDateTimeField source="created_at" />
                    {/* <EditButton /> */}
                    {/* <DeleteWithDialogAction /> */}
                </Datagrid>
            </List>
        </div>
    );
};
export default PermissionsList;
