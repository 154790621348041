import {

    List,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import { AssignPermissions } from './AssignPermissionModal';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';
import useIsPermission from '../../hooks/useIsPermission';

const RoleList = () => {
    const { isWritable } = useIsPermission("ACCESS_CONTROL");
    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
            >
                <Datagrid>
                    <TextField source="name" />
                    <FormattedDateTimeField source="created_at" />
                    <AssignPermissions />
                    {/* <EditButton /> */}
                    {/* <DeleteWithDialogAction /> */}
                </Datagrid>
            </List>
        </div>
    );
};
export default RoleList;
