import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';

const SegmentList = () => {
    const { isUpdatable, isDeletable, isWritable } = useIsPermission("SEGMENTS");
    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
            >
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="module" />
                    <TextField source="condition" />
                    <FormattedDateTimeField source="created_at" />
                    {isUpdatable &&
                        <EditButton />
                    }

                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default SegmentList;
