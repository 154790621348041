import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, NumberField, ReferenceField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import ProductTemplatesLinkField from "./ProductTemplateLinkField";
import AttributesLinkField from "./AttributesLink";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';
import useIsPermission from '../../hooks/useIsPermission';


const CategoryList = () => {
    const { isWritable, isDeletable, isUpdatable } = useIsPermission("ECOM_CATEGORIES");
    return (
        <List
            actions={<PermissionBasedActions isWritable={isWritable} />}
            sort={{ field: 'display_order', order: 'ASC' }}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="slug" />
                <NumberField source="display_order" />
                <ProductTemplatesLinkField />
                <AttributesLinkField />
                <ReferenceField reference={'ecom_categories'} source={'parent_id'}>
                    <TextField source={'name'} />
                </ReferenceField>
                <TextField source="status" />
                <FormattedDateTimeField source="created_at" />
                {isDeletable &&
                    <DeleteWithDialogAction />
                }
                {isUpdatable &&
                    <EditButton />
                }
            </Datagrid>
        </List>
    );
};
export default CategoryList;
