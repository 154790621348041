import { CreateButton, Datagrid, ExportButton, List, ReferenceField, TextField, TopToolbar, useRecordContext } from 'react-admin'
import { NoDataSection } from '../../common/NoDataSection'
import { DeleteWithDialogAction } from '../../common/DeleteWithDialogAction'
import { useNavigate, useParams } from 'react-router'
import { Add, Description, Edit, PictureAsPdf, Videocam } from "@mui/icons-material";
import { Button } from '@mui/material'
import ThumbnailField from '../../common/ThumbnailField';
import useIsPermission from '../../hooks/useIsPermission';


const ListActions = ({ categoryId }: { categoryId?: string }) => {
  const { isWritable } = useIsPermission("DEMO_VIDEOS");
  const navigate = useNavigate();
  return <TopToolbar>
    {isWritable &&
      <CreateButton onClick={(e) => {
        e.preventDefault();
        if (!categoryId) {
          navigate(`/demo_video_categories/${categoryId}/demo_video`)
        } else {
          navigate(`/demo_video_categories/${categoryId}/demo_video/create`)
        }
      }} />
    }
    <ExportButton />
  </TopToolbar>
};

const EditButton = () => {
  const navigate = useNavigate();
  const record: any = useRecordContext<any>();
  const { categoryId } = useParams();

  return <Button startIcon={<Edit />} onClick={(e) => {
    navigate(`/demo_video_categories/${categoryId}/demo_video/${record?.id}/edit`)
  }}>
    Edit
  </Button>

};
const DemoVideosList = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { isDeletable, isWritable, isUpdatable } = useIsPermission("DEMO_VIDEOS");

  return (
    <List sort={{ field: 'created_at', order: 'DESC' }} resource={'demo_videos'}
      actions={
        <ListActions
          categoryId={categoryId}
        />
      }
      empty={<NoDataSection resource={'demo_videos'} onCreate={() => {
        navigate(`/demo_video_categories/${categoryId}/demo_video/create`)
      }} />}
      filter={{
        demo_video_category_id: categoryId
      }}>
      <Datagrid>
        <ThumbnailField source="image" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="video_url" />
        <ReferenceField reference={'demo_video_categories'} source={'demo_video_category_id'} label="Program">
          <ReferenceField reference={'programs'} source={'program_id'}>
            <TextField source={'name'} />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField reference={'demo_video_categories'} source={'demo_video_category_id'} label="Batch">
          <ReferenceField reference={'batches'} source={'batch_id'}>
            <TextField source={'title'} />
          </ReferenceField>
        </ReferenceField>
        {/* <FormattedDateTimeField source="created_at" /> */}

        {isUpdatable &&
          <EditButton />
        }
        {isDeletable &&
          <DeleteWithDialogAction />
        }
      </Datagrid>
    </List>
  )
}

export default DemoVideosList
