import * as React from 'react';
import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { NoDataSection } from "../../common/NoDataSection";
import { Ecom_Product_Templates } from "../../custom";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import ProductsLinkField from "./ProductLinkField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { useMemo } from "react";
import useIsPermission from '../../hooks/useIsPermission';

const ListActions = ({ categoryId }: { categoryId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/ecom_categories/${categoryId}/ecom_product_templates/create`)
        }} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Ecom_Product_Templates = useRecordContext<Ecom_Product_Templates>();
    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/ecom_categories/${record.category_id}/ecom_product_templates/${record.id}/edit`)
    }}>
        Edit
    </Button>
};

const ProductTemplateList = () => {
    const { isUpdatable, isDeletable } = useIsPermission("ECOM_CATEGORIES");
    const params = useParams();
    const [searchParams] = useSearchParams();
    let { categoryId } = useParams();
    if (!categoryId) {
        return <LoadingSection />
    }
    const navigate = useNavigate();
    // const filter = useMemo(() => {
    //     let result: any = {};
    //     const type = searchParams.get('type');
    //     if (type === 'PROGRAM') {
    //         result['program'] = {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 id: {_is_null: false}
    //             }
    //         }
    //     }
    //     if (type === 'SUBJECT') {
    //         result['subject'] = {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 id: {_is_null: false}
    //             }
    //         }
    //     }
    //     if (type === 'CHAPTER') {
    //         result['chapter'] = {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 id: {_is_null: false}
    //             }
    //         }
    //     }
    //     if (type === 'TEST_SERIES') {
    //         result['test_series'] = {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 id: {_is_null: false}
    //             }
    //         }
    //     }
    //     if (type === 'BATCH') {
    //         result['batch'] = {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 id: {_is_null: false}
    //             }
    //         }
    //     }
    //     return result;
    // }, [searchParams])
    return (
        <List
            empty={<NoDataSection resource={'ecom_product_templates'} onCreate={() => {
                navigate(`/ecom_categories/${categoryId}/ecom_product_templates/create`)
            }} />}
            actions={<ListActions categoryId={categoryId} />}
            filter={{ category_id: categoryId }}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'ecom_product_templates'}>
            <Datagrid>
                <TextField source="name" />
                <ReferenceField reference={'ecom_categories'} source={'category_id'}>
                    <TextField source={'name'} />
                </ReferenceField>
                <FormattedDateTimeField source="created_at" />

                <ProductsLinkField />
                {isUpdatable &&
                    <EditButton />
                }
                {isDeletable &&
                    <DeleteWithDialogAction />
                }
            </Datagrid>
        </List>
    );
};
export default ProductTemplateList;
