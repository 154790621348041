import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField,
    DateField,
    useRecordContext,
    ReferenceField,
    ShowButton,
    FieldProps,
    Filter,
    TextInput,
    SelectInput,
    required,
    ReferenceInput,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    ExportButton,
    CreateButton,
    Link,
    ReferenceManyCount,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import { Batches, Programs } from "../../custom";
import { BATCH_TEMPLATE_TYPE, STATUSES } from "../../common/constants";
import StatusTags from "../../common/StatusTags";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';
const Actions = (props: FieldProps<Batches>) => {
    const { isDeletable, isWritable, isUpdatable } = useIsPermission("BATCH_TAMPLATE");

    return <div className={'flex gap-2'}>
        <ShowButton />
        {isUpdatable &&
            <EditButton />
        }
        {isDeletable &&
            <SoftDeleteWithDialogAction />
        }
    </div>;
};

Actions.defaultProps = {
    label: 'Actions'
};

const ListFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="title" alwaysOn />
        <SelectInput
            source="status"
            label={'Status'}
            fullWidth
            choices={STATUSES}
            alwaysOn
        />
        <SelectInput
            source="type"
            label={'Type'}
            fullWidth
            choices={BATCH_TEMPLATE_TYPE}
            alwaysOn
        />
    </Filter>
);
const BatchesLinkField = (props: FieldProps<Programs>) => {
    const record = useRecordContext<Programs>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/batches?filter=${encodeURIComponent(JSON.stringify({ template_id: record.id }))}`}>
            Batches
            (
            <ReferenceManyCount
                label="Batches"
                reference="batches"
                target="template_id"
            />
            )
        </Link>
    );
};

BatchesLinkField.defaultProps = {
    label: 'Batches'
};

const BatchTemplateList = () => {
    const { isWritable } = useIsPermission("BATCH_TAMPLATE");
    return (
        <List filters={<ListFilter />}
            actions={<PermissionBasedActions isWritable={isWritable} />}
            sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid>
                <TextField source="title" />
                <TextField source="slug" />
                <TextField source="type" />
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <StatusTags source="status" label={'Status'} />
                <BatchesLinkField />
                <FormattedDateTimeField source="created_at" />
                <Actions />
            </Datagrid>
        </List>
    );
};
export default BatchTemplateList;
