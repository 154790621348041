import TestSeriesList from './TestSeriesList';
import TestSeriesEdit from './TestSeriesEdit';
import {ViewInAr} from "@mui/icons-material";
import TestSeriesCreate from './TestSeriesCreate';
import TestSeriesShow from './TestSeriesShow';

export default {
    list: TestSeriesList,
    edit: TestSeriesEdit,
    create:TestSeriesCreate,
    show:TestSeriesShow,
    icon: ViewInAr,
};
