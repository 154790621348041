import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    ReferenceField,
} from 'react-admin';
import { Questions } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import CustomDisableField from '../questions/customDisableField';
const ExerciseQuestionEdit = () => {
    let { exerciseId, questionId } = useParams();
    if (!exerciseId) {
        return <LoadingSection />
    }
    return <Edit title={<SectionTitle />} redirect={() => {
        return `exercises/${exerciseId}/questions_list`
    }} resource={'section_question_mappings'} id={questionId}>
        <SimpleForm toolbar={<EditToolbar />}>
            <ReferenceField source="question_id" reference="questions">
                <CustomDisableField source='name' label='name' />
            </ReferenceField>
            <ReferenceField source="question_id" reference="questions">
                <CustomDisableField source='content' label='content' />
            </ReferenceField>
            <ReferenceField source="question_id" reference="questions">
                <CustomDisableField source='image' label='image' />
            </ReferenceField>
            <TextInput source={'display_order'} fullWidth />
            <TextInput source={'positive_marks'} fullWidth />
            <TextInput source={'negative_marks'} fullWidth />
        </SimpleForm>
    </Edit>
};

const SectionTitle = () => {
    const record = useRecordContext<Questions>();
    return record ? (
        <span>
            {record.id}&quot;
        </span>
    ) : null;
};

export default ExerciseQuestionEdit;
