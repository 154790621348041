import * as React from "react";
import {
  Show,
  TextInput,
  useTranslate,
  useRecordContext,
  required,
  SelectInput,
  ReferenceInput,
  Create,
  TabbedShowLayout,
  TextField,
  ReferenceField,
  DateTimeInput,
  Pagination,
  Datagrid,
  ReferenceManyField,
  TopToolbar,
  SimpleForm,
  useRefresh,
  RichTextField,
  SaveButton,
  useDataProvider,
  useGetList,
  NullableBooleanInput,
  AutocompleteInput,
  BooleanField,
  FormDataConsumer,
  Edit,
  NumberField,
  Toolbar,
  useCreate,
  useNotify,
  FieldProps,
  Count,
  useGetOne,
  minValue,
  DateInput,
  DateField,
  SimpleShowLayout,
} from "react-admin";

import {
  Batch_Schedules,
  Batches,
  Contents,
  Tests,
  Users,
  Videos,
} from "../../custom";
import ThumbnailField from "../../common/ThumbnailField";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  ChatBubble,
  ChevronRight,
  CopyAll,
  DocumentScanner,
  Edit as EditIcon,
  Today,
  Visibility,
} from "@mui/icons-material";
// @ts-ignore
import { Calendar, momentLocalizer } from "react-big-calendar";

import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  Alert,
  Box,
  InputAdornment,
  LinearProgress,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { NoDataSection } from "../../common/NoDataSection";
import ImageInput from "../../common/inputs/ImageInput";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { isScheduleLive, isScheduleUpcoming } from "../../class-utilities";
import { BatchScheduleSubjectChapterInput } from "../batch_schedules/BatchScheduleEdit";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { EditToolbar } from "../../common/EditToolbar";
import PDFInput from "../../common/inputs/PDFInput";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";
import { LoadingSection } from "../../common/LoadingSection";
import BatchScheduleTemplates from "./BatchScheduleTemplates";
import { getWeekday } from "../../common/string-utilities";
import { template } from "lodash";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import VideoInput from "../../common/inputs/VideoInput";
import StatusTags from "../../common/StatusTags";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import { BulkUploadExerciseQuestionsAction } from "../contents/BulkUploadExerciseQuestions";
import { BulkUploadStudentAction } from "./BulkStudentUpload";
import NonCategoryProductList from "../non_category_products/NonCategoryProductList";
import { useParams } from "react-router";
import { BASE_URL } from "../../common/constants";
import { addDays } from "date-fns";
import { EndsOnTime, ProductCard } from "../subjects/SubjectShow";
import OrderField from "../../common/OrderField";
import TotalAmountInput from "../../common/inputs/TotalAmountInput";
import PdfAutocompleteInput from "../../common/inputs/PdfAutoCompleteInput";
import PdfUrlPrefilled from "../../common/inputs/PdfUrlPrefilled";

const Actions = () => {
  const [open, setOpen] = useState(false);
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const refresh = useRefresh();

  const record = useRecordContext();
  return (
    <>
      <TopToolbar>
        <BulkUploadStudentAction path={"batches"} />

        <Button
          onClick={() => {
            setOpen(true);
          }}
          startIcon={<Today />}
        >
          Add a Schedule
        </Button>
        <Button
          onClick={() => {
            setOpenStudentModal(true);
          }}
          startIcon={<Today />}
        >
          Assign Batch
        </Button>
      </TopToolbar>

      <Dialog
        open={open}
        title={"Schedule Form"}
        onClose={(_refresh: boolean) => {
          setOpen(false);
        }}
      >
        <ScheduleForm
          onClose={(_refresh: boolean) => {
            if (_refresh) {
              refresh();
            }
            setOpen(false);
          }}
        />
      </Dialog>
      <Dialog
        open={openStudentModal}
        onClose={(_refresh: boolean) => {
          setOpenStudentModal(false);
        }}
      >
        {record && (
          <AssignStudentForm
            batchId={record?.id}
            onClose={(_refresh: boolean) => {
              if (_refresh) {
                refresh();
              }
              setOpenStudentModal(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

// -------------------------------------------- Assign student ------------------------------------------
export const studentProfileRender = (choice: Users) =>
  `${choice.first_name} ${choice.last_name || ""} | ${choice.phone || "No Phone"
  } | ${choice.email || "No Email"}`;

export const filterToQueryStudent = (searchText: string) => ({
  phone: `${searchText}%`,
});
const AssignStudentForm = (props: any) => {
  const { batchId } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const {
    data: productsData,
    refetch,
    error,
  } = useQuery(["products_" + batchId, "getList", { batchId }], () =>
    dataProvider.getList("ecom_products", {
      filter: {
        product_template: {
          format: "hasura-raw-query",
          value: {
            batch_id: { _eq: batchId },
          },
        },
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "created_at", order: "ASC" },
    })
  );

  const postSave = (data: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(BASE_URL + "/admin/orders/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...data,
        offer_amount: parseInt(data.offer_amount),
      }),
    })
      .then((q) => q.json())
      .then((d) => {
        console.log(d);
      })
      .finally(() => {
        setLoading(false);
        props.onClose(true);
      });
  };

  const products = productsData?.data || [];
  if (!batchId) {
    return <LoadingSection />;
  }
  return (
    <div style={{ width: "500px", maxWidth: "90vw" }}>
      <Create
        resource={"batch_students"}
        redirect={undefined}
        mutationOptions={{
          onSuccess: () => {
            if (props.onClose) {
              props.onClose(true);
            }
          },
        }}
      >
        <SimpleForm onSubmit={postSave} defaultValues={{ batch_id: batchId }}>
          <ReferenceInput
            name={"Student by phone"}
            source={"student_id"}
            reference={"users"}
            filter={{ role: "STUDENT" }}
          >
            <AutocompleteInput
              optionText={studentProfileRender}
              filterToQuery={filterToQueryStudent}
              debounce={500}
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          <SelectInput
            source="product_id"
            label={"Plan"}
            fullWidth
            validate={[required()]}
            choices={products}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const product = products.find(
                (p: any) => p.id === formData.product_id
              );
              if (!product) {
                return <></>;
              }
              return (
                <div className={"w-full"}>
                  <DateInput
                    source="ends_on"
                    label="Ends On"
                    fullWidth
                    defaultValue={addDays(
                      new Date(),
                      product?.validity_in_days
                    )}
                    validate={[required()]}
                  />
                  <ProductCard
                    key={product.id}
                    compactView={true}
                    productId={product.id}
                  />

                  <div className={"pt-2"}>
                    <TextInput
                      fullWidth
                      key={product.id + "price"}
                      type={"number"}
                      defaultValue={
                        product.amount -
                        (product.discount || 0) +
                        (product.delivery_amount || 0)
                      }
                      source={"offer_amount"}
                    />
                  </div>
                </div>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};

// const AssignStudentForm = (props: any) => {
//     const record = useRecordContext();
//     const dataProvider = useDataProvider();
//
//     return <div style={{width: '500px', maxWidth: '90vw'}}>
//         <Create resource={'batch_students'} redirect={undefined} mutationOptions={{
//             onSuccess: () => {
//                 if (props.onClose) {
//                     props.onClose(true);
//                 }
//             }
//         }}>
//             <SimpleForm defaultValues={{batch_id: record.id}}>
//                 <ReferenceInput name={'Student by phone'} source={'student_id'} reference={'users'}
//                                 filter={{role: 'STUDENT'}}>
//                     <AutocompleteInput optionText={studentProfileRender} filterToQuery={filterToQueryStudent}
//                                        debounce={500} fullWidth validate={[required()]}/>
//                 </ReferenceInput>
//                 <DateInput source="ends_on" label="Validity " fullWidth validate={[required()]}/>
//
//             </SimpleForm>
//         </Create>
//     </div>
// };

export const BatchScheduleHomeworkInput = ({
  batch_schedule_id,
  subject_id,
  batch_id,
  value,
  onChange,
}: {
  value: any[];
  onChange: any;
  batch_schedule_id?: string;
  subject_id: string;
  batch_id: string;
}) => {
  const [show, setShow] = useState(false);
  const dataProvider = useDataProvider();

  const {
    data: contents,
    isLoading,
    error,
  } = useQuery(
    [
      "batch_contents" + batch_id,
      "getList",
      { batch_id: batch_id, subject_id: subject_id },
    ],
    () =>
      dataProvider.getList("contents", {
        filter: {
          batch_id: batch_id,
          subject_id: subject_id,
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  return (
    <div className={"rounded overflow-hidden w-full"}>
      <div
        onClick={() => setShow(!show)}
        className={"flex cursor-pointer justify-between px-3 py-2 bg-gray-100"}
      >
        <div>Select Homework</div>
        <ChevronRight />
      </div>
      {show && (
        <div className={"border"}>
          <div className="divide-y divide-gray-200 border-bborder-gray-200">
            {contents?.data?.map((content: Contents, index) => (
              <div key={index} className="relative flex items-start py-4 px-3 ">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`content-${index}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {content.title}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    id={`content-${index}`}
                    name={`content-${index}`}
                    type="checkbox"
                    onChange={() => {
                      if (value?.includes(content.id)) {
                        const val = [...value];
                        val?.splice(val?.indexOf(content.id), 1);
                        onChange(val);
                      } else {
                        onChange([...value, content.id]);
                      }
                    }}
                    checked={value?.includes(content.id)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ScheduleForm = (props: any) => {
  const record = useRecordContext();
  const [create, { }] = useCreate();
  const notify = useNotify();
  const [selectedHomeworks, setSelectedHomeworks] = useState([] as any[]);
  const dataProvider = useDataProvider();
  const postSave = (data: any) => {
    create(
      "batch_schedules",
      { data: data },
      {
        onSuccess: (e) => {
          if (!selectedHomeworks.length) {
            if (props.onClose) {
              props.onClose(true);
            }
            return;
          }
          Promise.all(
            selectedHomeworks.map((h) =>
              create("batch_schedule_contents", {
                data: { batch_schedule_id: e.id, content_id: h },
              })
            )
          )
            .then((results) => {
              if (props.onClose) {
                props.onClose(true);
              }
            })
            .catch((error) => {
              notify(
                typeof error === "string"
                  ? error
                  : typeof error === "undefined" || !error.message
                    ? "ra.auth.sign_in_error"
                    : error.message,
                {
                  type: "error",
                  messageArgs: {
                    _:
                      typeof error === "string"
                        ? error
                        : error && error.message
                          ? error.message
                          : undefined,
                  },
                }
              );
            });
        },
        onError: (error: any) => {
          notify(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message
                ? "ra.auth.sign_in_error"
                : error.message,
            {
              type: "error",
              messageArgs: {
                _:
                  typeof error === "string"
                    ? error
                    : error && error.message
                      ? error.message
                      : undefined,
              },
            }
          );
        },
      }
    );
  };

  const {
    data: subjectsData,
    isLoading,
    error,
  } = useQuery(
    [
      "batch_template_subjects_subjects_" + record?.id,
      "getList",
      { batch_template_id: record?.template_id },
    ],
    () =>
      dataProvider.getList("subjects", {
        filter: {
          batch_template_subjects: props.batchTemplateSubjectId
            ? {
              format: "hasura-raw-query",
              value: {
                id: { _eq: props.batchTemplateSubjectId },
              },
            }
            : {
              format: "hasura-raw-query",
              value: {
                batch_template_id: { _eq: record?.template_id },
              },
            },
        },
        pagination: { page: 1, perPage: 400 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  const subjects = subjectsData?.data || [];
  if (!record || isLoading) {
    return <LoadingSection />;
  }
  if (!subjects.length) {
    return (
      <div className={"p-4"}>
        <Typography>No subject available for this batch template</Typography>
      </div>
    );
  }
  return (
    <div style={{ width: "500px", maxWidth: "90vw" }}>
      <Create
        resource={"batch_schedules"}
        redirect={undefined}
        mutationOptions={{
          onSuccess: () => {
            if (props.onClose) {
              props.onClose(true);
            }
          },
        }}
      >
        <SimpleForm
          onSubmit={postSave}
          defaultValues={{
            batch_id: record.id,
            subject_id: subjects.length === 1 ? subjects[0].id : null,
            batch_template_subject_id: props.batchTemplateSubjectId,
          }}
        >
          <ReferenceInput
            name={"Batch"}
            source={"batch_id"}
            reference={"batches"}
          >
            <SelectInput
              optionText="title"
              disabled
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          {record?.id && (
            <div className={"w-full"}>
              <ReferenceInput
                fullWidth
                name={"Primary Teacher"}
                source={"primary_teacher_id"}
                reference={"users"}
                filter={{
                  batch_teachers: {
                    format: "hasura-raw-query",
                    value: {
                      batch_id: {
                        _eq: record?.id,
                      },
                    },
                  },
                }}
              >
                <AutocompleteInput
                  optionText={studentProfileRender}
                  filterToQuery={filterToQueryStudent}
                  debounce={500}
                  fullWidth
                />
              </ReferenceInput>
            </div>
          )}
          <ImageInput source={"thumbnail"} validate={[required()]} />
          <SelectInput
            optionText="name"
            source={"subject_id"}
            choices={subjects || []}
            disabled={!subjects}
            fullWidth
            validate={[required()]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.subject_id && (
                <>
                  <BatchScheduleSubjectChapterInput
                    subjectId={formData.subject_id}
                  />
                </>
              )
            }
          </FormDataConsumer>
          <TextInput source="title" validate={required()} fullWidth />
          <NullableBooleanInput source="chat_enabled" fullWidth />
          <NullableBooleanInput source="is_demo" fullWidth />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <VideoAutocompleteInput
                formData={formData}
                fullWidth
                source={"video_id"}
              />
            )}
          </FormDataConsumer>
          <DateTimeInput
            defaultValue={props.startDateTime}
            source="start_time"
            validate={required()}
            fullWidth
          />
          <DateTimeInput
            defaultValue={props.endDateTime}
            source="end_time"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="restriction_max_duration_multiple"
            type={"number"}
            fullWidth
          />
          <TextInput
            source="restriction_max_number_playback"
            type={"number"}
            fullWidth
          />

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.subject_id ? (
                <>
                  <BatchScheduleHomeworkInput
                    value={selectedHomeworks}
                    onChange={(e: any[]) => {
                      setSelectedHomeworks(e);
                    }}
                    batch_id={record.id as string}
                    subject_id={formData.subject_id}
                  />
                </>
              ) : (
                <div
                  className={
                    "text-sm w-full border border-amber-700 rounded bg-amber-100 text-amber-900 p-1"
                  }
                >
                  Select Subject for Homework
                </div>
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};

const LiveChatItem = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  if (record.video_id && isScheduleLive(record as Batch_Schedules)) {
    return (
      <div>
        <Button
          onClick={() => navigate(`/batch_schedules/${record.id}/live`)}
          startIcon={<ChatBubble />}
        >
          Chat
        </Button>
      </div>
    );
  }
  return <div />;
};

LiveChatItem.defaultProps = {
  source: "liveChat",
  label: "Live Chat",
};

const ScheduleEditButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <div>
      <Button onClick={() => navigate(`/batch_schedules/${record.id}`)}>
        <EditIcon />
      </Button>
    </div>
  );
};

const ScheduleShowButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <div>
      <Button onClick={() => navigate(`/batch_schedules/${record.id}/show`)}>
        <Visibility />
      </Button>
    </div>
  );
};

const ScheduleTabularViewAction = () => {
  return (
    <div className={"flex space-x-2"}>
      <LiveChatItem />
      <DeleteWithDialogAction />
      <ScheduleShowButton />
      <ScheduleEditButton />
    </div>
  );
};

ScheduleTabularViewAction.defaultProps = {
  source: "actions",
  label: "Actions",
};

const BatchScheduleWrapper = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: subjects,
    isLoading,
    error,
  } = useQuery(
    [
      "batch_template_subjects_subjects_" + record.id,
      "getList",
      { batch_template_id: record.batch_template_id },
    ],
    () =>
      dataProvider.getList("subjects", {
        filter: {
          batch_template_subjects: {
            format: "hasura-raw-query",
            value: {
              batch_template_id: record.batch_template_id,
            },
          },
        },
        pagination: { page: 1, perPage: 400 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  return (
    <>
      <Alert severity="warning">
        * Faded schedules means their subjects are removed from batch template.
        Please check this may cause inconsistency on students side. Either
        remove this schedule or add subject to batch template
      </Alert>

      <ReferenceManyField
        label={"Batch Schedules"}
        reference="batch_schedules"
        target="batch_id"
        sort={{ field: "start_time", order: "DESC" }}
        pagination={<Pagination />}
      >
        <Datagrid
          rowStyle={(su) => {
            return subjects?.data?.find((s) => s.id === su.subject_id)
              ? {}
              : { opacity: "0.4" };
          }}
          sx={{
            width: "100%",
            "& .column-comment": {
              maxWidth: "20em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TextField source={"title"} />
          {/*<VideoItem/>*/}
          <ReferenceField reference={"videos"} source={"video_id"}>
            <TextField source={"code"} />
          </ReferenceField>
          <ReferenceField reference={"subjects"} source={"subject_id"}>
            <TextField source={"name"} />
          </ReferenceField>
          <ReferenceField reference={"chapters"} source={"chapter_id"}>
            <TextField source={"name"} />
          </ReferenceField>
          <BooleanField source={"is_demo"} />
          <FormattedDateTimeField source={"start_time"} />
          <FormattedDateTimeField source={"end_time"} />
          <FormattedDateTimeField source={"created_at"} />
          <ScheduleTabularViewAction />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export function getFirstAndLastDay(date: Date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  lastDay.setHours(23, 59, 59, 999);
  return { firstDay, lastDay };
}

const localizer = momentLocalizer(moment);

// Example usage:
export const BatchScheduleCalender = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const record = useRecordContext();
  const [selectSlotStartDate, setSelectSlotStartDate] = useState(null as any);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const {
    data: schedules,
    isLoading,
    refetch,
    error,
  } = useQuery(
    [
      "batch_schedules" + record.id + selectedDate.toISOString(),
      "getList",
      {
        batch_id: record.id,
        selected_date: selectedDate.toISOString(),
      },
    ],
    () =>
      dataProvider.getList("batch_schedules", {
        filter: {
          batch_id: record.id,
          start_time: {
            format: "hasura-raw-query",
            value: {
              _gte: getFirstAndLastDay(selectedDate)?.firstDay,
              _lte: getFirstAndLastDay(selectedDate)?.lastDay,
            },
          },
        },
        pagination: { page: 1, perPage: 400 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  const { data: scheduleTemplatesData } = useQuery(
    [
      "batch_schedule_templates_" + record.id,
      "getList",
      { batch_id: record.id },
    ],
    () =>
      dataProvider.getList("batch_schedule_templates", {
        filter: {
          batch_id: record.id,
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  const scheduleTemplates = scheduleTemplatesData?.data || [];
  const shellSchedules = useMemo(() => {
    let startDate = getFirstAndLastDay(selectedDate)?.firstDay;
    let endDate = getFirstAndLastDay(selectedDate)?.lastDay;
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    const draft: any[] = [];
    while (currentDate <= endDate) {
      const d = getWeekday(currentDate.toISOString());
      const templates = scheduleTemplates.filter(
        (s) => s.day === d && s.start_time && s.end_time
      );
      templates.forEach((temp) => {
        const [hours, minutes] = temp.start_time.split(":").map(Number);
        const newDate = new Date(currentDate);
        newDate.setHours(newDate.getHours() + hours);
        newDate.setMinutes(newDate.getMinutes() + minutes);
        const [endHours, endMinutes] = temp.end_time.split(":").map(Number);
        const endDate = new Date(currentDate);
        endDate.setHours(endDate.getHours() + endHours);
        endDate.setMinutes(endDate.getMinutes() + endMinutes);
        if (temp.start_time > temp.end_time) {
          endDate.setHours(endDate.getHours() + 24);
        }
        if (endDate.getTime() > new Date().getTime()) {
          draft.push({
            name: `Draft: ${d} Schedule`,
            draft: true,
            batch_template_subject_id: temp.batch_template_subject_id,
            start_time: newDate,
            end_time: endDate,
          });
        }
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return draft;
  }, [
    getFirstAndLastDay(selectedDate)?.firstDay,
    getFirstAndLastDay(selectedDate)?.lastDay,
    scheduleTemplates,
    schedules,
  ]);

  useEffect(() => {
    if (selectedDate) {
      refetch({
        queryKey: [
          [
            "batch_schedules" + record.id + selectedDate.toISOString(),
            "getList",
            {
              batch_id: record.id,
              selected_date: selectedDate.toISOString(),
            },
          ],
          {
            filter: {
              batch_id: record.id,
              start_time: {
                format: "hasura-raw-query",
                value: {
                  _gte: getFirstAndLastDay(selectedDate)?.firstDay,
                  _lte: getFirstAndLastDay(selectedDate)?.lastDay,
                },
              },
            },
            pagination: { page: 1, perPage: 400 },
            sort: { field: "created_at", order: "ASC" },
          },
        ],
      });
      console.log({
        batch_id: record.id,
        start_time: {
          format: "hasura-raw-query",
          value: {
            _gte: getFirstAndLastDay(selectedDate)?.firstDay?.toISOString(),
            _lte: getFirstAndLastDay(selectedDate)?.lastDay?.toISOString(),
          },
        },
      });
    }
  }, [selectedDate]);

  const events = useMemo(() => {
    return (
      schedules?.data?.map((schedule) => {
        return {
          ...schedule,
          name: schedule.title,
          start_time: new Date(schedule.start_time),
          end_time: new Date(schedule.end_time),
        };
      }) || []
    );
  }, [schedules]);
  return (
    <div>
      <Dialog
        open={!!selectSlotStartDate}
        title={"Schedule Form"}
        onClose={(_refresh: boolean) => {
          setSelectSlotStartDate(null);
        }}
      >
        <ScheduleForm
          batchTemplateSubjectId={
            selectSlotStartDate?.batch_template_subject_id
          }
          startDateTime={selectSlotStartDate?.start}
          endDateTime={selectSlotStartDate?.end}
          onClose={(_refresh: boolean) => {
            if (_refresh) {
              refresh();
            }
            setSelectSlotStartDate(null);
          }}
        />
      </Dialog>
      <Calendar
        localizer={localizer}
        defaultView={"day"}
        selectable={true}
        onSelectSlot={(slotInfo: any) => {
          setSelectSlotStartDate(slotInfo);
        }}
        eventPropGetter={(event: any) => {
          if (event.draft) {
            return {
              style: {
                border: "2px solid #888888",
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            };
          }
          if (isScheduleLive(event)) {
            if (!event.video_id) {
              return {
                style: {
                  backgroundImage:
                    "radial-gradient(circle, black 2px, transparent 2px), radial-gradient(circle, black 2px, transparent 2px)",
                  backgroundSize: "30px 30px",
                  backgroundColor: "red",
                  backgroundPosition: "0 0, 15px 15px",
                },
              };
            }
            return {
              style: {
                backgroundColor: "red",
              },
            };
          }
          if (isScheduleUpcoming(event)) {
            if (!event.video_id) {
              return {
                style: {
                  backgroundImage:
                    "radial-gradient(circle, black 2px, transparent 2px), radial-gradient(circle, black 2px, transparent 2px)",
                  backgroundSize: "30px 30px",
                  backgroundColor: "cornflowerblue",
                  backgroundPosition: "0 0, 15px 15px",
                },
              };
            }
            return {
              style: {
                backgroundColor: "cornflowerblue",
              },
            };
          }
          if (!event.video_id) {
            return {
              style: {
                backgroundImage:
                  "radial-gradient(circle, black 2px, transparent 2px), radial-gradient(circle, black 2px, transparent 2px)",
                backgroundSize: "30px 30px",
                backgroundColor: "#888888",
                backgroundPosition: "0 0, 15px 15px",
              },
            };
          }
          return {
            style: {
              backgroundColor: "#888888",
            },
          };
        }}
        events={[...events, ...shellSchedules]}
        startAccessor="start_time"
        messages={{
          agenda: "Schedule",
        }}
        onSelectEvent={(event: any) => {
          if (event.id) {
            navigate(`/batch_schedules/${event.id}/show`);
          } else {
            setSelectSlotStartDate({
              batch_template_subject_id: event.batch_template_subject_id,
              start: event.start_time,
              end: event.end_time,
            });
          }
        }}
        onNavigate={(date: Date) => {
          setSelectedDate(date);
        }}
        endAccessor="end_time"
        style={{ height: 800 }}
      />
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BatchScheduleSwitchWrapper = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Calender" />
          <Tab label="Tabular View" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BatchScheduleCalender />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BatchScheduleWrapper />
      </TabPanel>
    </Box>
  );
};

const HomeworkEditForm = ({ contentId }: { contentId: string }) => {
  return (
    <SimpleForm
      id={contentId}
      toolbar={
        <Toolbar>
          <SaveButton />
          {/*<Button onClick={() => setMode('LIST')}>*/}
          {/*    Cancel*/}
          {/*</Button>*/}
        </Toolbar>
      }
    >
      <TextInput source="title" fullWidth validate={[required()]} />
      <TextInput
        source="config.number_of_pages"
        validate={[required()]}
        type={"number"}
        label={"Number of pages"}
        fullWidth
      />
      <NullableBooleanInput source={"is_demo"} fullWidth label={"Is Demo"} />
      <TextInput source="display_order" type={"number"} fullWidth />
      <ReferenceInput label="Subject" source="subject_id" reference="subjects">
        <SelectInput
          optionText="name"
          disabled
          fullWidth
          validate={[required()]}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
const HomeworkEditButton = ({ onClick }: { onClick: any }) => {
  const record = useRecordContext();
  return (
    <Button
      startIcon={<EditIcon />}
      onClick={() => {
        onClick(record);
      }}
    >
      Edit
    </Button>
  );
};

export const chapterRender = (choice: any) => `${choice.name}`;
export const filterToQueryChapter = (searchText: string) => ({
  name: `${searchText}%`,
});

const BatchHomeworkWrapper = () => {
  const record = useRecordContext();
  const [mode, setMode] = useState("LIST" as "LIST" | "EDIT" | "CREATE");
  const dataProvider = useDataProvider();
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string | null>("");
  const [selectedItem, setSelectedItem] = useState(null as any);
  const {
    data: subjects,
    isLoading,
    error,
  } = useQuery(
    [
      "batch_template_subjects_subjects_" + record.id,
      "getList",
      { batch_template_id: record.template_id },
    ],
    () =>
      dataProvider.getList("subjects", {
        filter: {
          batch_template_subjects: {
            format: "hasura-raw-query",
            value: {
              batch_template_id: { _eq: record.template_id },
            },
          },
        },
        pagination: { page: 1, perPage: 400 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  return (
    <>
      {mode === "EDIT" && (
        <Edit>
          <HomeworkEditForm contentId={selectedItem.id} />
        </Edit>
      )}
      {mode === "LIST" && (
        <>
          <div className={"flex justify-between"}>
            <div />
            <Button onClick={() => setMode("CREATE")}>Add New</Button>
          </div>
          <ReferenceManyField
            label={"Batch Homework"}
            reference="contents"
            target="batch_id"
            sort={{ field: "created_at", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="title" />
              <NumberField source="display_order" />
              <BooleanField source="is_demo" />
              <ReferenceField
                reference={"subjects"}
                label={"Subject"}
                source={"subject_id"}
              >
                <TextField source={"name"} />
              </ReferenceField>
              <ReferenceField
                reference={"chapters"}
                label={"Chapter"}
                source={"chapter_id"}
              >
                <TextField source={"name"} />
              </ReferenceField>
              <StatusTags source={"subject_status"} label={"Subject Status"} />
              <StatusTags source={"chapter_status"} label={"Chapter Status"} />
              <FormattedDateTimeField source="created_at" />

              {/* <HomeworkEditButton onClick={(r: any) => {
                 setSelectedItem(r);
                 setMode('EDIT');
              }}/> */}
              <DeleteWithDialogAction />
            </Datagrid>
          </ReferenceManyField>
        </>
      )}
      {mode === "CREATE" && (
        <div className={"border rounded p-2"}>
          <Create
            resource={"contents"}
            redirect={undefined}
            mutationOptions={{
              onSuccess: () => {
                setMode("LIST");
              },
            }}
          >
            <SimpleForm
              toolbar={
                <Toolbar>
                  <SaveButton />
                  <Button onClick={() => setMode("LIST")}>Cancel</Button>
                </Toolbar>
              }
              defaultValues={{ batch_id: record.id, type: "NOTES" }}
            >
              <TextInput source="title" fullWidth validate={[required()]} />

              <TextInput
                source="config.number_of_pages"
                type={"number"}
                label={"Number of pages"}
                fullWidth
              />
              <SelectInput
                optionText="name"
                source={"subject_id"}
                choices={subjects?.data || []}
                disabled={!subjects?.data}
                fullWidth
                validate={[required()]}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (!formData.subject_id) {
                    return <></>;
                  }
                  return (
                    <ReferenceInput
                      fullWidth
                      name={"Chapter"}
                      source={"chapter_id"}
                      reference={"chapters"}
                      filter={{
                        subject_id: formData?.subject_id,
                      }}
                    >
                      <AutocompleteInput
                        optionText={chapterRender}
                        filterToQuery={filterToQueryChapter}
                        debounce={500}
                        fullWidth
                      />
                    </ReferenceInput>
                  );
                }}
              </FormDataConsumer>
              <NullableBooleanInput
                source={"is_demo"}
                fullWidth
                label={"Is Demo"}
              />
              <TextInput source="display_order" type={"number"} fullWidth />
              {/* <PDFInput source="url" label={"Upload File"} fullWidth /> */}

              <FormDataConsumer>
                {
                  ({ formData, ...rest }) => {
                    return <>
                      <PdfAutocompleteInput  fullWidth
                        formData={formData}
                        source={'pdf_id'}
                        selectedPdfUrl={selectedPdfUrl}
                        setSelectedPdfUrl={setSelectedPdfUrl}
                      />

                      <PdfUrlPrefilled fullWidth
                        formData={formData}
                        source={'url'}
                        selectedPdfUrl={selectedPdfUrl}
                      />

                    </>
                  }
                }
              </FormDataConsumer>
            </SimpleForm>
          </Create>
        </div>
      )}
    </>
  );
};

const BatchExerciseWrapper = () => {
  const record = useRecordContext();
  const [mode, setMode] = useState("LIST" as "LIST" | "EDIT" | "CREATE");
  const dataProvider = useDataProvider();
  const [create, { isLoading: createLoading }] = useCreate();
  const navigate = useNavigate();
  const notify = useNotify();
  const submitContentCreate = (data: any) => {
    if (createLoading) {
      return;
    }
    const contentsData: any = {
      ...data,
    };

    const testData = {
      name: contentsData?.title,
      program_id: contentsData?.program_id,
    };

    const sectionData = {
      name: "Section 1",
      program_id: contentsData?.program_id,
    };

    create(
      "tests",
      { data: testData },
      {
        onSuccess: (e) => {
          create(
            "contents",
            { data: { ...contentsData, exercise_id: e.id } },
            {
              onSuccess: (ee) => {
                create(
                  "sections",
                  { data: { ...sectionData, test_id: e.id } },
                  {
                    onSuccess: (eee) => {
                      notify("Created SuccessFully", {
                        type: "success",
                      }),
                        setMode("LIST");
                    },
                  }
                );
              },
            }
          );
        },
      }
    );
  };

  const [selectedItem, setSelectedItem] = useState(null as any);
  const {
    data: subjects,
    isLoading,
    error,
  } = useQuery(
    [
      "batch_template_subjects_subjects_" + record.id,
      "getList",
      { batch_template_id: record.template_id },
    ],
    () =>
      dataProvider.getList("subjects", {
        filter: {
          batch_template_subjects: {
            format: "hasura-raw-query",
            value: {
              batch_template_id: { _eq: record.template_id },
            },
          },
        },
        pagination: { page: 1, perPage: 400 },
        sort: { field: "created_at", order: "ASC" },
      })
  );

  const { data: batchData } = useGetOne("batches", { id: record?.id });
  const templateId = batchData?.template_id;
  const { data: batchesTempData } = useGetOne("batch_templates", {
    id: templateId,
  });
  const ProgramId = batchesTempData?.program_id;

  const QuestionPaperViewPage = (props: FieldProps<Tests>) => {
    const record = useRecordContext<any>();
    if (!record) {
      return null;
    }

    return (
      <Link
        to={`/batches/${record.batch_id}/programs/${ProgramId}/exercise/${record?.exercise_id}/tests/${record?.exercise_id}/question_paper`}
      >
        <Tooltip title="Show question paper">
          <Button onClick={(e) => { }}>
            <DocumentScanner />
          </Button>
        </Tooltip>
      </Link>
    );
  };

  const QuestionsLinkField = (props: FieldProps<Tests>) => {
    const record = useRecordContext<any>();
    if (!record) {
      return null;
    }
    return (
      <Link
        to={`/batches/${record.batch_id}/programs/${ProgramId}/exercise/${record?.exercise_id}/tests/${record?.exercise_id}/questions_list`}
      >
        Questions (
        <Count resource="questions" filter={{ test_id: record?.exercise_id }} />
        )
      </Link>
    );
  };

  QuestionPaperViewPage.defaultProps = {};

  return (
    <>
      {mode === "EDIT" && (
        <Edit>
          <HomeworkEditForm contentId={selectedItem.id} />
        </Edit>
      )}
      {mode === "LIST" && (
        <>
          <div className={"flex justify-between"}>
            <div />
            <Button onClick={() => setMode("CREATE")}>Add New</Button>
          </div>
          <ReferenceManyField
            label={"Batch Exercise"}
            reference="contents"
            target="batch_id"
            filter={{ type: "EXERCISES" }}
            sort={{ field: "created_at", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="title" />
              <NumberField source="display_order" />
              <BooleanField source="is_demo" />
              <StatusTags source="program_status" label={"Program Status"} />
              <StatusTags source="subject_status" label={"Subject Status"} />
              <StatusTags source="chapter_status" label={"Chapter Status"} />
              <StatusTags source="topic_status" label={"Topic Status"} />
              <ReferenceField reference={"topics"} source={"topic_id"}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference={"chapters"} source={"chapter_id"}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference={"subjects"} source={"subject_id"}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference={"programs"} source={"program_id"}>
                <TextField source="name" />
              </ReferenceField>
              <FormattedDateTimeField source="created_at" />
              <QuestionPaperViewPage />
              <QuestionsLinkField />
              <BulkUploadExerciseQuestionsAction />
              {/* <EditButton /> */}
              <DeleteWithDialogAction />
            </Datagrid>
          </ReferenceManyField>
        </>
      )}
      {mode === "CREATE" && (
        <div className={"border rounded p-2"}>
          <Create
            resource={"contents"}
            redirect={undefined}
            mutationOptions={{
              onSuccess: () => {
                setMode("LIST");
              },
            }}
          >
            <SimpleForm
              toolbar={
                <Toolbar>
                  <SaveButton />
                  <Button onClick={() => setMode("LIST")}>Cancel</Button>
                </Toolbar>
              }
              defaultValues={{
                batch_id: record.id,
                program_id: ProgramId,
                type: "EXERCISES",
              }}
              onSubmit={submitContentCreate}
            >
              <TextInput source="title" fullWidth validate={[required()]} />

              <SelectInput
                optionText="name"
                source={"subject_id"}
                choices={subjects?.data || []}
                disabled={!subjects?.data}
                fullWidth
                validate={[required()]}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (!formData.subject_id) {
                    return <></>;
                  }
                  return (
                    <ReferenceInput
                      fullWidth
                      name={"Chapter"}
                      source={"chapter_id"}
                      reference={"chapters"}
                      filter={{
                        subject_id: formData?.subject_id,
                      }}
                    >
                      <AutocompleteInput
                        optionText={chapterRender}
                        filterToQuery={filterToQueryChapter}
                        debounce={500}
                        fullWidth
                      />
                    </ReferenceInput>
                  );
                }}
              </FormDataConsumer>
              <NullableBooleanInput
                source={"is_demo"}
                fullWidth
                label={"Is Demo"}
              />
              <TextInput source="display_order" type={"number"} fullWidth />
              <PDFInput source="url" label={"Upload File"} fullWidth />
            </SimpleForm>
          </Create>
        </div>
      )}
    </>
  );
};

export const BatchProductCard = () => {
  const record = useRecordContext();
  return <NonCategoryProductList batchId={record.id as string} />;
};

BatchProductCard.defaultProps = {
  source: "batch_id",
  label: "Product",
};

const TeacherForm = () => {
  const refresh = useRefresh();
  const record = useRecordContext();
  // @ts-ignore
  return (<Create resource={"batch_teachers"} redirect={() => {
    refresh();
  }}
  >
    <SimpleForm defaultValues={{ batch_id: record.id }}>
      <ReferenceInput
        filter={{ role: "TEACHER" }}
        source={"teacher_id"}
        label={"Teacher"}
        reference={"users"}
      >
        <AutocompleteInput
          optionText={studentProfileRender}
          filterToQuery={filterToQueryStudent}
          limitChoicesToValue={true}
          debounce={500}
          fullWidth
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
  );
};

const BatchProductEditButton = ({ product }: { product: any }) => {
  const [formOpened, setFormOpened] = useState(false);
  const refresh = useRefresh();
  return (
    <div className={"py-2"}>
      <Button onClick={() => setFormOpened(true)}>Edit Amount</Button>
      <Dialog
        open={formOpened}
        title={"Edit product form"}
        onClose={(_refresh: boolean) => {
          setFormOpened(false);
        }}
      >
        <Edit
          redirect={window.location.href}
          className={" w-[400px]"}
          mutationMode={"pessimistic"}
          resource={"ecom_products"}
          mutationOptions={{
            onSuccess: () => {
              setFormOpened(false);
              refresh();
            },
          }}
          id={product.id}
        >
          <SimpleForm toolbar={<EditToolbar disableDelete={true} />}>
            <TextInput
              type={"number"}
              source="mrp"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <TextInput
              type={"number"}
              source="discount"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <TextInput
              type={"number"}
              source="gst_percentage"
              prefix={"%"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <div className="w-full">
                    <TotalAmountInput formData={formData} source="amount" />
                  </div>
                );
              }}
            </FormDataConsumer>
            <TextInput
              type={"number"}
              source="apple_amount"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
          </SimpleForm>
        </Edit>
      </Dialog>
    </div>
  );
};

const BatchShow = () => {
  const [batchStudentQuery, setBatchStudentQuery] = useState("");
  return (
    <Show title={<BatchTitle />} actions={<Actions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="resources.batches.tabs.details">
          <ThumbnailField size={"large"} source="image" />
          <TextField source="title" fullWidth />
          <TextField source="display_order" fullWidth />
          <ReferenceField reference={"batch_templates"} source={"template_id"}>
            <TextField fullWidth source="title" />
          </ReferenceField>
          <FormattedDateTimeField source="start_time" fullWidth />
          <FormattedDateTimeField source="end_time" fullWidth />
          <TextField source="status" />
          <TextField source="text_description" />
          <RichTextField source="html_description" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"product"}
          label="resources.batches.tabs.product"
        >
          <BatchProductCard />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"schedules"}
          label="resources.batches.tabs.schedules"
        >
          <BatchScheduleSwitchWrapper />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          path={"homework"}
          label="resources.batches.tabs.homework"
        >
          <BatchHomeworkWrapper />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"exercise"}
          label="resources.batches.tabs.exercise"
        >
          <BatchExerciseWrapper />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"subjects"}
          label="resources.batches.tabs.students"
        >
          <input
            className={"border rounded p-2"}
            value={batchStudentQuery}
            onChange={(e) => setBatchStudentQuery(e.target.value)}
            placeholder={"Search Student"}
          />
          <ReferenceManyField
            label={"Batch Students"}
            reference="batch_students"
            target="batch_id"
            sort={{ field: "created_at", order: "DESC" }}
            filter={
              batchStudentQuery
                ? {
                  user: {
                    format: "hasura-raw-query",
                    value: {
                      _or: [
                        { phone: { _eq: batchStudentQuery } },
                        { email: { _eq: batchStudentQuery } },
                        { first_name: { _ilike: `%${batchStudentQuery}%` } },
                        { last_name: { _ilike: `%${batchStudentQuery}%` } },
                      ],
                    },
                  },
                }
                : {}
            }
            pagination={<Pagination />}
          >
            <Datagrid
              sx={{
                width: "100%",
                "& .column-comment": {
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
              sort={{ field: "start_time", order: "ASC" }}
            >
              <ReferenceField reference={"users"} source={"student_id"}>
                <FullNameField />
              </ReferenceField>
              <ReferenceField reference={"users"} source={"created_by_id"}>
                <FullNameField />
              </ReferenceField>
              <ReferenceField reference={"ecom_orders"} source={"order_id"}>
                <OrderField />
              </ReferenceField>
              <FormattedDateTimeField source="created_at" />
              <EndsOnTime source="ends_on" />

              <SoftDeleteWithDialogAction />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          path={"schedule_templates"}
          label="Schedule Templates"
        >
          <BatchScheduleTemplates />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          path={"teachers"}
          label="resources.batches.tabs.teachers"
        >
          <TeacherForm />
          <ReferenceManyField
            label={"Batch Teachers"}
            reference="batch_teachers"
            target="batch_id"
            pagination={<Pagination />}
          >
            <Datagrid
              sx={{
                width: "100%",
                "& .column-comment": {
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
              sort={{ field: "start_time", order: "ASC" }}
            >
              <ReferenceField reference={"users"} source={"teacher_id"}>
                <FullNameField />
              </ReferenceField>
              <ReferenceField reference={"users"} source={"created_by_id"}>
                <FullNameField />
              </ReferenceField>
              <FormattedDateTimeField source="created_at" />
              <DeleteWithDialogAction />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
const BatchTitle = () => {
  const record = useRecordContext<Batches>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("resources.batches.name", { smart_count: 1 })} &quot;
      {record.title}
    </span>
  ) : null;
};

export default BatchShow;
