import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  ImageField,
  TopToolbar,
  useRecordContext,
  RichTextField,
} from "react-admin";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { BulkUploadQuestionBankAction } from "./BulkUploadQuestions";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { useNavigate } from "react-router";
import { Add, CloudUploadOutlined, Download } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Edit, Upload } from "lucide-react";
import MasterDataField from "./MasterDataField";
import { CopyIdButton } from "../../common/CopyIdButton";
import useIsPermission from "../../hooks/useIsPermission";
import { useEffect, useRef, useState } from "react";
import { BulkUploadQuestionBankViaDocAction } from "./BulkUploadUsingDocs";


// const ListFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="name" alwaysOn />
//     <SelectInput source="status" label={"Status"} choices={STATUS} alwaysOn />
//   </Filter>
// );
const Actions = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex" }}>
      <Button
        startIcon={<Add />}
        onClick={() => {
          navigate(
            `/questions_bank/create`
          );
        }}
        sx={{ padding: "0 5px" }}
      >
        Create
      </Button>
      {/* {["SINGLE", "MULTIPLE", "NUMERIC"].map((type, index) => {
        return (
          <Button
            key={index}
            startIcon={<Add />}
            onClick={() => {
              const data = { type };
              navigate(
                `/questions_bank/create?source=${encodeURIComponent(
                  JSON.stringify(data)
                )}`
              );
            }}
            sx={{ padding: "0 5px" }}
          >
            Add a {type}
          </Button>
        );
      })} */}
    </div>
  );
};

const BulkUploadOptions = () => {

  const [showActions, setShowActions] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown on click outside
  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
  //       setShowActions(false);
  //     }
  //   };

  //   if (showActions) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showActions]);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={() => setShowActions((prev: any) => !prev)}
        className=" hover:bg-gray-200 cursor-pointer text-[#4f3cc9] flex flex-row gap-2"
      >
     <CloudUploadOutlined/>   Bulk Upload
        {/* <MoreVertIcon /> */}
      </button>

      {showActions && (
        <div className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-lg p-2 flex flex-col text-start gap-2 z-10">
          <div className="flex w-full justify-start gap-2 text-[#4f3cc9] text-md items-center">
          <BulkUploadQuestionBankAction />

          </div>
          <div className="flex w-full gap-2 justify-start text-[#4f3cc9] text-md items-center hover:bg-blue-50">
          <BulkUploadQuestionBankViaDocAction/>
          </div>
        </div>
      )}
    </div>
  );

}

const CustomListActions = () => (
  <TopToolbar>
    <Actions />
    <div className="pl-3">

    <BulkUploadOptions/>
    </div>
    {/* <ExportButton /> */}
  </TopToolbar>
);

const EditButton = () => {
  const navigate = useNavigate();
  const record = useRecordContext()
  return (
    <Button
      startIcon={<Edit />}
      onClick={(e) => {
        navigate(
          `/questions_bank/${record.id}/edit`
        );
      }}
    >
      Edit
    </Button>
  );
};


const QuestionBankList = () => {
  const { isDeletable, isWritable, isUpdatable } = useIsPermission("QUESTION_BANK");
  return (
    <List
      resource={"questions"}
      // filters={<ListFilter />}
      actions={<CustomListActions />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid>
        <CopyIdButton />
        <TextField source="name" />
        <RichTextField source="content" />
        <ImageField source="image" />
        <MasterDataField />
        <FormattedDateTimeField source="created_at" />
        {isUpdatable &&
          <EditButton />
        }
        {/* <DeleteWithDialogAction /> */}
      </Datagrid>
    </List>
  );
};
export default QuestionBankList;
