import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput, NullableBooleanInput, minValue,
    FormDataConsumer,
} from 'react-admin';

import { Ecom_Products, } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import PDFInput from "../../common/inputs/PDFInput";
import { useNavigate } from "react-router-dom";
import TotalAmountInput from '../../common/inputs/TotalAmountInput';


const NonCategoryProductEdit = () => {
    let { productTemplateId, productId } = useParams();
    const navigate = useNavigate();
    if (!productTemplateId) {
        return <LoadingSection />
    }

    return (
        // @ts-ignore
        <Edit title={<ProductTitle />} redirect={() => {
            navigate(-1);
        }} id={productId} resource={'ecom_products'}>
            <SimpleForm toolbar={<EditToolbar />}>
                <ImageInput source="images" multiple fullWidth validate={[required()]} />
                <PDFInput source="preview_pdf" fullWidth label={'Preview PDF'} />
                <NullableBooleanInput source={'address_required'} fullWidth name={'Address Required'} />
                <TextInput source="name" fullWidth validate={[required()]} />
                <TextInput type={'number'} source="mrp" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                <TextInput type={'number'} source="discount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                <TextInput type={'number'} source="gst_percentage" prefix={'%'} fullWidth validate={[required(), minValue(0)]} />
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return <div className='w-full'>
                        <TotalAmountInput source="amount" formData={formData} />
                    </div>
                }}
            </FormDataConsumer>
                <TextInput type={'number'} source="apple_amount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                <TextInput type={'number'} source="validity_in_days" defaultValue={0} fullWidth validate={[required(), minValue(0)]} />
                <TextInput source="text_description" multiline rows={4} fullWidth validate={[required()]} />
                <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]} />
            </SimpleForm>
        </Edit>
    );
};

const ProductTitle = () => {
    const record = useRecordContext<Ecom_Products>();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.products.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default NonCategoryProductEdit;
