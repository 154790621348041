import { useEffect, useState } from "react";
import {
  ImageField,
  RichTextField,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Button, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import { LoadingSection } from "../../common/LoadingSection";
import { Pagination } from "@mui/material";
import ModalQuestionEditView from "./ModalQuestionEditView";

const QuestionsListInput = (props: any = {}) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage] = useState(10);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [classList, setClassList] = useState<any[]>([]);
  const [subjectList, setSubjectList] = useState<any[]>([]);
  const [chapterList, setChapterList] = useState<any[]>([]);
  const [topicList, setTopicList] = useState<any[]>([]);

  // Filter States
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");

  // Fetch Class & Subject Lists (Independent)
  useEffect(() => {
    const fetchClassesAndSubjects = async () => {
      setLoading(true);
      try {
        const [classes, subjects] = await Promise.all([
          dataProvider.getList("master_classes", { pagination: { page: 1, perPage: 500 }, sort: { field: "display_order", order: "ASC" }, filter: {} }),
          dataProvider.getList("master_subjects", { pagination: { page: 1, perPage: 500 }, sort: { field: "name", order: "ASC" }, filter: {} }),
        ]);

        setClassList(classes.data);
        setSubjectList(subjects.data);
      } catch (error) {
        notify("Failed to load filters", { type: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchClassesAndSubjects();
  }, [dataProvider]);

  // Fetch Chapters Based on Selected Subject
  useEffect(() => {
    if (!selectedSubject) {
      setChapterList([]);
      setSelectedChapter("");
      return;
    }

    dataProvider.getList("master_chapters", {
      pagination: { page: 1, perPage: 500 },
      sort: { field: "name", order: "ASC" },
      filter: { subject_id: selectedSubject },
    })
      .then((response) => setChapterList(response.data))
      .catch(() => notify("Failed to load chapters", { type: "error" }));
  }, [dataProvider, notify, selectedSubject]);

  // Fetch Topics Based on Selected Chapter
  useEffect(() => {
    if (!selectedChapter) {
      setTopicList([]);
      setSelectedTopic("");
      return;
    }

    dataProvider.getList("master_topics", {
      pagination: { page: 1, perPage: 500 },
      sort: { field: "name", order: "ASC" },
      filter: { chapter_id: selectedChapter },
    })
      .then((response) => setTopicList(response.data))
      .catch(() => notify("Failed to load topics", { type: "error" }));
  }, [dataProvider, notify, selectedChapter]);

  // Fetch Questions with Applied Filters
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("questions", {
        pagination: { page, perPage },
        sort: { field: "created_at", order: "DESC" },
        filter: {
          master_class_id: selectedClass || undefined,
          master_subject_id: selectedSubject || undefined,
          master_chapter_id: selectedChapter || undefined,
          master_topic_id: selectedTopic || undefined,
        },
      })
      .then((response) => {
        setQuestions(response.data);
        setTotal(response?.total || 0);
        setLoading(false);
      })
      .catch(() => {
        notify("Failed to load questions", { type: "error" });
        setLoading(false);
      });
  }, [dataProvider, notify, page, perPage, selectedClass, selectedSubject, selectedChapter, selectedTopic]);

  const handleSelect = (id: any) => {
    setSelectedQuestions((prev) =>
      prev.includes(id) ? prev.filter((q) => q !== id) : [...prev, id]
    );
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="min-w-[70vw] min-h-[70vh]">
      {loading ? (
        <LoadingSection />
      ) : isEdit ? (
        <ModalQuestionEditView
          selectedQuestions={selectedQuestions}
          setIsEdit={setIsEdit}
          handleSelect={handleSelect}
          handleClose={props?.handleClose}
          type={props?.type}
        />
      ) : (
        <>
          {/* Filters */}
          <div className="flex  flex-row flex-wrap gap-4 my-2">
            <Select
              className="h-8 "
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              displayEmpty>
              <MenuItem value="">All Classes</MenuItem>
              {classList.map((cls) => (
                <MenuItem key={cls.id} value={cls.id}>{cls.name}</MenuItem>
              ))}
            </Select>

            <Select
              className="h-8 "
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              displayEmpty>
              <MenuItem value="">All Subjects</MenuItem>
              {subjectList.map((subj) => (
                <MenuItem key={subj.id} value={subj.id}>{subj.name}</MenuItem>
              ))}
            </Select>

            <Select
              className="h-8 "
              value={selectedChapter}
              onChange={(e) => setSelectedChapter(e.target.value)}
              displayEmpty
              disabled={!selectedSubject}>
              <MenuItem value="">All Chapters</MenuItem>
              {chapterList.map((chap) => (
                <MenuItem key={chap.id} value={chap.id}>{chap.name}</MenuItem>
              ))}
            </Select>

            <Select
              className="h-8 "
              value={selectedTopic}
              onChange={(e) => setSelectedTopic(e.target.value)}
              displayEmpty
              disabled={!selectedChapter}>
              <MenuItem value="">All Topics</MenuItem>
              {topicList.map((topic) => (
                <MenuItem key={topic.id} value={topic.id}>{topic.name}</MenuItem>
              ))}
            </Select>
          </div>

          {/* Question List */}
          <div className="relative border rounded-lg overflow-hidden">
            {questions.length === 0 ? (
              <Typography className="text-center py-4">No Data Currently</Typography>
            ) : (
              <>
                <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                  <table className="min-w-full table-auto border-collapse">
                    <thead>
                      <tr className="bg-gray-100 border-b border-gray-200 sticky top-0 z-10">
                        <th className="p-2"></th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Content</th>
                        <th className="p-2">Image</th>
                        <th className="p-2 text-start">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questions.map((question) => (
                        <tr key={question.id} className="border-b border-gray-200 hover:bg-gray-50">
                          <td className="p-1"><Checkbox checked={selectedQuestions.includes(question)} onChange={() => handleSelect(question)} /></td>
                          <td className="p-1 text-center"><TextField source="name" record={question} /></td>
                          <td className="p-1 text-center"><RichTextField source="content" record={question} /></td>
                          <td className="p-1 flex justify-center"><ImageField source="image" record={question} /></td>
                          <td className="p-1 text-center"><FormattedDateTimeField source="created_at" record={question} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination count={Math.ceil(total / perPage)} page={page} onChange={handlePageChange} color="primary" />
              </>
            )}
          </div>

          {/* Buttons */}
          <div className="p-2 flex flex-row gap-3">
            {selectedQuestions.length > 0 && (
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Next <div className="font-semibold px-1">({selectedQuestions.length})</div>
              </Button>
            )}
            <Button onClick={props?.handleClose}>Cancel</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionsListInput;
