import * as React from 'react';
import {Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput} from 'react-admin';
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";


const SectionCreate = () => {
    let {programId, testSeriesId, testId} = useParams();
    if (!testSeriesId) {
        return <LoadingSection/>
    }
    return <Create redirect={() => {
        return `test_serieses/${testSeriesId}/tests/${testId}/sections`
    }} resource={'sections'}>
        <SimpleForm defaultValues={{program_id: programId, test_series_id: testSeriesId, test_id: testId}}>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <ReferenceInput label="Test" source="test_id" reference="tests">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
};

export default SectionCreate;
