import { AccountCircle } from "@mui/icons-material";
import PermissionsList from "./PermissionsList";
import PermissionsCreate from "./PermissionsCreate";

export default {
    list: PermissionsList,
    // edit: RoleEdit,
    create: PermissionsCreate,
    icon: AccountCircle,
};
