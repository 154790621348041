import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useController } from "react-hook-form";
import { BASE_URL } from "../constants";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { uploadFile } from "./ImageInput";

const PDFInput = (props: any = {}) => {
    const i = useController({ name: props.source });
    const [loading, setLoading] = useState(false);

    const showImages = () => {
        if (loading) {
            return false;
        }
        if (props.multiple && (!i.field.value || i.field.value.length < 10)) {
            return true;
        }
        if (!props.multiple && !i.field.value) {
            return true;
        }
        return false;
    };
    const renderImages = () => {
        const result: any = [];
        if (props.multiple) {
            i.field.value?.forEach((v: string, index: number) => {
                result.push(
                    <div key={index + ""}>
                        <PictureAsPdfOutlined onClick={() => {
                            window.open(v);
                        }} />
                        <Button
                            onClick={() => {
                                const _images = JSON.parse(JSON.stringify(i.field.value));
                                _images.splice(index, 1);
                                i.field.onChange(_images);
                            }}
                            color="error"
                        >
                            Remove
                        </Button>
                    </div>
                );
            });
        } else if (i.field.value) {
            result.push(
                <div key={"pdf"}>
                    <PictureAsPdfOutlined onClick={() => {
                        window.open(i.field?.value);
                    }} />
                    <Button onClick={() => i.field.onChange("")} color="error">
                        Remove
                    </Button>
                </div>
            );
        }
        return result;
    };
    return (
        <>
            <>{renderImages()}</>
            {loading && <CircularProgress />}
            <div className={'p-2 bg-gray-100 rounded-md w-full mb-2 border-gray-400 border'} style={{ position: "relative" }}>
                {showImages() && (
                    <Button>
                        Add {props.label || 'PDF'}
                        <input
                            placeholder={props.placeholder}
                            style={{
                                position: "absolute",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                top: "0",
                                opacity: "0",
                            }}
                            accept="application/pdf"
                            multiple={false}
                            {...i.field}
                            value={""}
                            onChange={async (e) => {
                                const file = e.target.files?.[0];
                                if (!file) {
                                    return;
                                }
                                try {
                                    setLoading(true);
                                    const res = await uploadFile(file);
                                    if (res?.status === 201 || res?.status === 200) {
                                        const data = await res.json();
                                        if (props?.setPdfUrl) {
                                            props.setPdfUrl(data?.url);
                                        }
                                        if (data) {
                                            if (props.multiple) {
                                                const v = i.field.value || [];
                                                i.field.onChange([
                                                    ...v,
                                                    `${data.url}`,
                                                ]);
                                            } else {
                                                i.field.onChange(
                                                    `${data.url}`
                                                );
                                            }
                                        }
                                    } else {
                                        alert("Unable to upload PDF, check console for error");
                                    }
                                } catch (error) {
                                    console.log(error);

                                    alert("Unable to upload PDF, check console for error");
                                }
                                setLoading(false);
                            }}
                            type={"file"}
                        />
                    </Button>
                )}
            </div>
        </>
    );
};
export default PDFInput;
