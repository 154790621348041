import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const InfoModalOrg = ({ open, setOpen }: { open: any, setOpen: any }) => {
    return (

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            sx={{ "& .MuiDialog-paper": { width: "70vw", maxWidth: "800px" } }}
        >
            <DialogTitle className="flex flex-row justify-between items-center bg-gray-100 h-7">
                <div className="flex flex-row gap-3 items-center">
                    <InfoOutlinedIcon sx={{ color: 'blue' }} />
                    <p>Info</p>
                </div>
                <CloseOutlinedIcon onClick={() => setOpen(false)} className='cursor-pointer' sx={{ color: "red" }} />
            </DialogTitle>

            <DialogContent className='mt-5 '>
                <div className='flex flex-col gap-3'>
                    <div>
                        <h2 className='font-bold'>BLOCK_MULTI_LOGIN</h2>
                        <div>{"Mobile/web: Login 1 => working (token 1)"}</div>
                        <div>{"Mobile/web: Login 2 => Logout previous device and logged in on device 2"}</div>
                        <div>Token 1 disabled</div>
                        <div>Token 2 generated</div>
                    </div>

                    <div>
                        <h2 className='font-bold'>BLOCK_MULTI_LOGIN_WITH_RESTRICTION</h2>
                        <div>{"Mobile/web: Login 1 => working"}</div>
                        <div>{"Mobile/web: Login 2 => Error: please logout from your previous device"}</div>
                    </div>

                    <div>
                        <h2 className='font-bold'>BLOCK_MULTI_DEVICE_LOGIN</h2>
                        <div>{"Mobile: Login 1 => working"}</div>
                        <div>{"Web: Login 2 => working"}</div>
                        <div>{"Mobile: Login 3 => Logout previous device (login 1) and logged in on device 3"}</div>
                        <div>{"Web: Login 2 => Logout previous device (login 2) and logged in on device 3"}</div>
                    </div>

                    <div >
                        <h2 className='font-bold'>BLOCK_MULTI_DEVICE_LOGIN_WITH_RESTRICTION</h2>
                        <div>{"Mobile: Login 1 => working"}</div>
                        <div>{"Web: Login 2 => working"}</div>
                        <div>{"Mobile: Login 3 => Error: please logout from your previous device"}</div>
                        <div>{"Web: Login 2 => Error: please logout from your previous device"}</div>
                    </div>
                </div>

            </DialogContent>
        </Dialog >
    )
}

export default InfoModalOrg
