import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, DateField, BooleanField, ReferenceField, NumberField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import ThumbnailField from "../../common/ThumbnailField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';


const TestimonialList = () => {
    const { isUpdatable, isWritable, isDeletable } = useIsPermission("TESTIMONIALS");

    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
                sort={{ field: 'display_order', order: 'ASC' }}>
                <Datagrid>

                    <ThumbnailField source="author_image" />
                    <TextField source="author_name" />
                    <NumberField source="display_order" />
                    <ReferenceField reference={'batch_templates'} source={'batch_template_id'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                    <TextField source="description" />
                    <FormattedDateTimeField source="created_at" />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default TestimonialList;
