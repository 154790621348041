import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  useRecordContext,
  SelectInput,
  required,
} from "react-admin";

import { Programs } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import { CLASSES, EXAMS, LANGUAGES, STATUSES } from "../../common/constants";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import PermissionGuard from "../../common/PermissionGuard";
import useIsPermission from "../../hooks/useIsPermission";

const ProgramEdit = () => {
  const { isUpdatable } = useIsPermission("PROGRAM");
  return (
    <PermissionGuard permission={isUpdatable}>
      <Edit title={<ProgramTitle />} mutationMode={"pessimistic"}>
        <SimpleForm toolbar={<EditToolbar />}>
          <ImageInput source="image" fullWidth />
          <TextInput source="name" fullWidth validate={[required()]} />
          <TextInput source="slug" fullWidth validate={[required()]} />
          <TextInput
            type={"number"}
            source="display_order"
            fullWidth
            validate={[required()]}
          />
          <SelectInput
            source="status"
            label={"Status"}
            fullWidth
            validate={[required()]}
            choices={STATUSES}
          />
          <SelectInput
            source="config.class"
            label={"Class"}
            fullWidth
            validate={[required()]}
            choices={CLASSES}
          />
          <SelectInput
            source="config.language"
            label={"Language"}
            validate={[required()]}
            fullWidth
            choices={LANGUAGES}
          />
          <SelectInput
            source="config.exam"
            validate={[required()]}
            label={"Exam"}
            fullWidth
            choices={EXAMS}
          />
          <HTMLEditorInput
            source={"description"}
            fullWidth
            validate={[required(), validHtml]}
          />
        </SimpleForm>
      </Edit>
    </PermissionGuard>
  );
};

const ProgramTitle = () => {
  const record = useRecordContext<Programs>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("resources.programs.name", { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default ProgramEdit;
