import { useSearchParams } from 'react-router-dom';
import ImageInput from '../../common/inputs/ImageInput'
import PDFInput from '../../common/inputs/PDFInput'
import { Create, FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin'

const PdfsCreate = () => {
      const [searchParams] = useSearchParams();
      const categoryId = searchParams.get('category_id');
      const isDisabled = !!categoryId;
  return (
    <Create redirect={'/pdfs'}>
      <SimpleForm defaultValues={{
                category_id: categoryId 
            }}>
        <ImageInput source="thumbnail" fullWidth />
        <TextInput source="title" fullWidth />
        <FormDataConsumer>
          {
            ({ formData }) => {
              return <PDFInput fullWidth
                formData={formData}
                source={'url'} />
            }
          }
        </FormDataConsumer>
        <ReferenceInput
          reference={'pdf_categories'}
          source={'category_id'}
          defaultValue={categoryId} 
        >
          <SelectInput fullWidth optionText="name"
            disabled={isDisabled}
            required
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default PdfsCreate
