import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';
import {BASE_URL, GQL_URL, } from "../common/constants";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = async (auth: FormValues) => {
        setLoading(true);
        try {
            setSessionId('');
            const data = await fetch(BASE_URL + '/auth/get-admin-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: auth.username,
                    country_code: '+91'
                }),
            }).then((q) => q.json());
            if (data) {
                setSessionId(data?.session_id)
            } else {
                notify(
                    'Unable to login',
                    {
                        type: 'error'
                    }
                );
            }
        } catch (e) {
            notify(
                'Unable to login',
                {
                    type: 'error'
                }
            );
        } finally {
            setLoading(false);
        }
    };
    
    const handleLogin = async (auth: FormValuesOTP) => {
        setLoading(true);
        login(
            {...auth},
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    },
                }
            );
        });
    };

    // if (sessionId) {
        return <Form onSubmit={handleLogin} key={'otp'} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',

                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{minWidth: 300, marginTop: '6em'}}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{bgcolor: 'secondary.main'}}>
                            <LockIcon/>
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        Welcome to Kaksya
                    </Box>
                    <Box sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                autoFocus
                                source="phone"
                                label={'Username'}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                            <TextInput
                                source="password"
                                label={'Password'}
                                type={"password"}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    // }
    // return (
    //     <Form onSubmit={handleSubmit} noValidate>
    //         <Box
    //             sx={{
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 minHeight: '100vh',
    //                 alignItems: 'center',
    //                 justifyContent: 'flex-start',
    //
    //                 backgroundRepeat: 'no-repeat',
    //                 backgroundSize: 'cover',
    //             }}
    //         >
    //             <Card sx={{minWidth: 300, marginTop: '6em'}}>
    //                 <Box
    //                     sx={{
    //                         margin: '1em',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                     }}
    //                 >
    //                     <Avatar sx={{bgcolor: 'secondary.main'}}>
    //                         <LockIcon/>
    //                     </Avatar>
    //                 </Box>
    //                 <Box
    //                     sx={{
    //                         marginTop: '1em',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         color: theme => theme.palette.grey[500],
    //                     }}
    //                 >
    //                     Welcome to Kaksya
    //                 </Box>
    //                 <Box sx={{padding: '0 1em 1em 1em'}}>
    //                     <Box sx={{marginTop: '1em'}}>
    //                         <TextInput
    //                             autoFocus
    //                             source="username"
    //                             label={translate('ra.auth.username')}
    //                             disabled={loading}
    //                             validate={required()}
    //                             fullWidth
    //                         />
    //                     </Box>
    //                 </Box>
    //                 <CardActions sx={{padding: '0 1em 1em 1em'}}>
    //                     <Button
    //                         variant="contained"
    //                         type="submit"
    //                         color="primary"
    //                         disabled={loading}
    //                         fullWidth
    //                     >
    //                         {loading && (
    //                             <CircularProgress size={25} thickness={2}/>
    //                         )}
    //                         {translate('ra.auth.sign_in')}
    //                     </Button>
    //                 </CardActions>
    //             </Card>
    //         </Box>
    //     </Form>
    // );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    username?: string;
}

interface FormValuesOTP {
    otp?: string;
}
