import * as React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    minValue,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput,
    required
} from 'react-admin';
import { Programs } from "../../custom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoModal from './InfoModal';

export const SegmentRender = (choice: Programs) => `${choice.name}`;
const CampaignCreate = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Create redirect={'/campaigns'}>
                <SimpleForm>
                    <div onClick={() => setOpen(true)} className="w-full px-5 flex flex-row gap-1 items-center justify-end">
                        <InfoOutlinedIcon className='cursor-pointer my-3 justify-end ' sx={{ color: 'blue' }} />
                        <p>info </p>
                    </div>
                    <TextInput source="name" fullWidth validate={[minValue(0)]} />
                    <TextInput source="title" fullWidth validate={[minValue(0)]} />
                    <TextInput source="message" fullWidth validate={[minValue(0)]} multiline rows={5} />
                    <DateTimeInput source="starts_at" validate={[required()]} fullWidth />
                    <ReferenceInput name={'Segment'} source={'segment_id'} reference={'segmentations'} fullWidth>
                        <AutocompleteInput optionText={SegmentRender} debounce={500} fullWidth />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
            <InfoModal open={open} setOpen={setOpen} />

        </>
    );
};

export default CampaignCreate;
