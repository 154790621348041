import {
  AccountCircle,
  BugReport,
  BugReportOutlined,
  Campaign,
  Category,
  ContactSupport,
  CorporateFare,
  CurrencyRupee,
  Dashboard,
  DocumentScannerRounded,
  Forum,
  Image,
  ImportantDevices,
  Key,
  LocalActivity,
  LocalLibrary,
  LocalLibraryOutlined,
  Newspaper,
  OndemandVideo,
  Pin,
  PsychologyAlt,
  QuestionAnswer,
  Task,
  VerifiedUser,
  ViewInAr,
  Webhook,
  YouTube,
} from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { FileSlidersIcon } from "lucide-react";
const MenuOptions = [
  [
    {
      name: "resources.dashboard.organization_dashboard",
      to: "/",
      icon: <Dashboard />,
      permission: "DASHBOARD",
      children: [],
    },
  ],

  [
    {
      name: "Organizations",
      icon: <CorporateFare />,
      children: [
        {
          name: "resources.organizations.name",
          to: "/organizations",
          icon: <CorporateFare />,
          permission: "ORGANIZATION",
          children: [],
        },
        {
          name: "resources.organization_resources.name",
          to: "/organization_resources",
          icon: <CorporateFare />,
          permission: "ORGANIZATION_RESOURCE",
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "User Management",
      icon: <AccountCircle />,
      children: [
        {
          name: "resources.users.name",
          to: "/users",
          permission: "USERS",
          icon: <AccountCircle />,
          children: [],
        },
        {
          name: "Deleted Users",
          to: "/deleted_users",
          permission: "DELETED_USERS",
          icon: <AccountCircle />,
          children: [],
        },
        {
          name: "resources.user_permissions.name",
          to: "/acl_user_permissions",
          icon: <Key />,
          permission: "USERS",
          children: [],
        },
        {
          name: "resources.otps.name",
          to: "/otps",
          permission: "USERS_OTP",
          icon: <Pin />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "Access Control",
      icon: <PictureAsPdfIcon />,
      children: [
        {
          name: "Role",
          to: "/roles",
          permission: "ACCESS_CONTROL",
          // permission: "VIDEO",
          icon: <PictureAsPdfIcon />,
          children: [],
        }
      ],
    },
  ],

  [
    {
      name: "Study",
      icon: <LocalLibrary />,
      children: [{
        name: "resources.programs.name",
        to: "/programs",
        permission: "PROGRAM",
        icon: <ViewInAr />,
        children: [],
      },
      {
        name: "resources.test_serieses.name",
        permission: "PROGRAM",
        to: "/test_serieses",
        icon: <DocumentScannerRounded />,
        children: [],
      },
      {
        name: "Exercise",
        permission: "PROGRAM",
        to: "/exercises",
        icon: <FileSlidersIcon />,
        children: [],
      },
      {
        name: "resources.batch_templates.name",
        permission: "BATCH_TAMPLATE",
        to: "/batch_templates",
        icon: <LocalLibraryOutlined />,
        children: [],
      },
      {
        name: "resources.batches.name",
        to: "/batches",
        permission: "BATCH",
        icon: <LocalLibrary />,
        children: [],
      },

      ],
    },
  ],

  [
    {
      name: "Question Bank",
      icon: <Newspaper />,
      children: [
        {
          name: "Question Bank",
          to: "/questions_bank",
          permission: "QUESTION_BANK",
          icon: <Newspaper />,
          children: [],
        },
        {
          name: "MetaData",
          to: "/metadata",
          permission: "",
          icon: <Newspaper />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "Video Repository",
      icon: <OndemandVideo />,
      children: [
        {
          name: "Video Categories",
          to: "/video_subjects",
          permission: "VIDEO_SUBJECT",
          icon: <Webhook />,
          children: [],
        },
        {
          name: "resources.videos.name",
          to: "/videos",
          permission: "VIDEO",
          icon: <Webhook />,
          children: [],
        },
        {
          name: "Demo videos",
          to: "/demo_video_categories",
          permission: "DEMO_VIDEOS",
          icon: <OndemandVideo />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "PDF Repository",
      icon: <PictureAsPdfIcon />,
      children: [
        {
          name: "PDF Categories",
          to: "/pdf_categories",
          permission: "PDF_CATEGORIES",
          icon: <PictureAsPdfIcon />,
          children: [],
        },
        {
          name: "PDF",
          to: "/pdfs",
          permission: "PDF",
          icon: <PictureAsPdfIcon />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "Broadcast",
      icon: <Campaign />,
      children: [
        {
          name: "Segments",
          to: "/segmentations",
          permission: "SEGMENTS",
          icon: <Campaign />,
          children: [],
        },
        {
          name: "Campaigns",
          to: "/campaigns",
          permission: "CAMPAIGNS",
          icon: <Campaign />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "Ecom",
      icon: <CurrencyRupee />,
      children: [
        {
          name: "resources.coupons.name",
          to: "/ecom_coupons",
          permission: "COUPONS",
          icon: <LocalActivity />,
          children: [],
        },
        {
          name: "resources.orders.name",
          to: "/ecom_orders",
          permission: "ORDER",
          icon: <CurrencyRupee />,
          children: [],
        },
        {
          name: "resources.categories.name",
          to: "/ecom_categories",
          permission: "ECOM_CATEGORIES",
          icon: <Category />,
          children: [],
        },
      ],
    },
  ],

  // [
  //   {
  //     name: "resources.blog_topics.name",
  //     to: "/ce_blog_topics",
  //     permission: "ce_blog_topics",
  //     icon: <Newspaper />,
  //     children: [],
  //   },
  //   {
  //     name: "resources.blogs.name",
  //     to: "/ce_blogs",
  //     permission: "ce_blogs",
  //     icon: <Newspaper />,
  //     children: [],
  //   },
  // ],

  [
    {
      name: "Blogs",
      to: "https://blogs.kaksya.in/ghost/#/signin",
      permission: "BLOGS",
      icon: <Newspaper />,
      sideIcon: <ArrowOutwardIcon sx={{ fontSize: 18 }} />,
      children: [],
      openInNewTab: true,
    },
  ],


  // [
  //   {
  //     name: "Download",
  //     icon: <Download />,
  //     children: [
  //       {
  //         name: "resources.download_categories.name",
  //         to: "/download_categories",
  //         permission: "download_categories",
  //         icon: <Download />,
  //         children: [],
  //       },
  //       {
  //         name: "resources.downloads.name",
  //         to: "/downloads",
  //         permission: "downloads",
  //         icon: <Download />,
  //         children: [],
  //       },
  //     ],
  //   },
  // ],

  [
    {
      name: "Podcast",
      icon: <Campaign />,
      children: [
        {
          name: "resources.podcast_topics.name",
          to: "/podcast_topics",
          permission: "PODCAST",
          icon: <Campaign />,
          children: [],
        },
        {
          name: "resources.podcasts.name",
          to: "/podcasts",
          permission: "PODCAST",
          icon: <Campaign />,
          children: [],
        },
      ],
    },
  ],

  [
    {
      name: "Banner,Testimonials and Faqs",
      icon: <Image />,
      children: [
        {
          name: "resources.banners.name",
          to: "/banners",
          permission: "BANNERS",
          icon: <Image />,
          children: [],
        },
        {
          name: "resources.testimonials.name",
          to: "/testimonials",
          permission: "TESTIMONIALS",
          icon: <VerifiedUser />,
          children: [],
        },
        {
          name: "resources.faqs.name",
          to: "/faqs",
          permission: "FAQS",
          icon: <QuestionAnswer />,
          children: [],
        },
      ],
    },
  ],

  // [
  //   {
  //     name: "Doubt and Queries",
  //     icon: <ContactSupport />,
  //     children: [
  //       {
  //         name: "resources.doubts.name",
  //         to: "/doubts",
  //         permission: "doubts",
  //         icon: <ContactSupport />,
  //         children: [],
  //       },
  //       {
  //         name: "resources.queries.name",
  //         to: "/queries",
  //         icon: <PsychologyAlt />,
  //         children: [],
  //       },
  //     ],
  //   },
  // ],

  [
    {
      name: "Issues",
      icon: <BugReportOutlined />,
      children: [
        {
          name: "resources.issue_templates.name",
          to: "/cs_issue_templates",
          permission: "ISSUE_TEMPLATE",
          icon: <BugReportOutlined />,
          children: [],
        },
        {
          name: "resources.issue_topics.name",
          to: "/cs_issue_topics",
          permission: "ISSUE_TOPICS",
          icon: <BugReportOutlined />,
          children: [],
        },

        {
          name: "resources.issues.name",
          permission: "ISSUE",
          to: "/cs_issues",
          icon: <BugReport />,
          children: [],
        },
        {
          name: "resources.issue_responses.name",
          to: "/cs_issue_responses",
          permission: "ISSUE_RESPONSE",
          icon: <Forum />,
          children: [],
        },

      ],
    },
  ],

  [
    {
      name: "Reports",
      // to: "/report/signups",
      // permission: "REPORT_SIGNUP",
      icon: <FlagCircleIcon />,
      children: [
        {
          name: "resources.reports.signups",
          to: "/report/signups",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
        {
          name: "resources.reports.tests",
          to: "/report/tests",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
        {
          name: "resources.reports.store_items",
          to: "/report/store_items",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
        {
          name: "resources.reports.orders",
          to: "/report/orders",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
        {
          name: "resources.reports.batch_students",
          to: "/report/batch_students",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
        {
          name: "resources.reports.application_users",
          to: "/report/application_users",
          permission: "REPORTS",
          icon: <ReportProblemIcon />,
          children: [],
        },
      ],
    },
  ],

];
export default MenuOptions;
