import {
    List,
    EditButton,
    Datagrid,
    TextField,
    BooleanField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import ThumbnailField from "../../common/ThumbnailField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionBasedActions from '../../common/PermissionBasedActionsButton';


const BannerList = () => {
    const { isUpdatable,isWritable, isDeletable } = useIsPermission("BANNERS");
    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
                sort={{ field: 'display_order', order: 'ASC' }}>
                <Datagrid>
                    <ThumbnailField source="image" />
                    <TextField source="display_order" />
                    <TextField source="redirect_url" />
                    <BooleanField source="hidden" />
                    <FormattedDateTimeField source="created_at" />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    );
};
export default BannerList;
