import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required,
} from 'react-admin';

import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const PodcastTopicEdit = () => {
    const { isUpdatable } = useIsPermission("PODCAST");
    return <PermissionGuard permission={isUpdatable}>
        <Edit title={<Title />} redirect={'/podcast_topics'}>
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput source="name" fullWidth name={'name'} />
                <ImageInput source={'thumbnail'} validate={[required()]} />
                <TextInput source="display_order" type={'number'} fullWidth />
            </SimpleForm>
        </Edit>
    </PermissionGuard>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.download_categories.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default PodcastTopicEdit;
