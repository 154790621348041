import * as React from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import clsx from "clsx";
import { useQuery } from "react-query";

const MasterDataField = () => {
    const record = useRecordContext<any>();
    const dataProvider = useDataProvider();

    if (!record) return null;
    const classId = record?.master_class_id
    const subjectId = record?.master_subject_id
    const chapterId = record?.master_chapter_id
    const topicId = record?.master_topic_id
    // Fetch user details using react-query
    const { data: masterClass, isLoading, error } = useQuery(
        ["master_classes", classId],
        () =>
            dataProvider.getOne("master_classes", {
                id: classId,
            }),
        {
            enabled: !!classId,
        }
    );
    const { data: masterSubject, isLoading: subLoading, error: subErr } = useQuery(
        ["master_subjects", subjectId],
        () =>
            dataProvider.getOne("master_subjects", {
                id: subjectId,
            }),
        {
            enabled: !!subjectId,
        }
    );

    const { data: masterChapter, isLoading: chapLoading, error: chapErr } = useQuery(
        ["master_chapters", chapterId],
        () =>
            dataProvider.getOne("master_chapters", {
                id: chapterId,
            }),
        {
            enabled: !!chapterId,
        }
    );
    const { data: masterTopic, isLoading: topLoading, error: topErr } = useQuery(
        ["master_topics", topicId],
        () =>
            dataProvider.getOne("master_topics", {
                id: topicId,
            }),
        {
            enabled: !!topicId,
        }
    );

    return <div className={'text-xs'}>
        <div className={'whitespace-nowrap'}>
            Master Class: <b>{masterClass?.data?.name|| "-"}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Master Subject: <b>{masterSubject?.data?.name||"-"}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Master Chapter: <b>{masterChapter?.data?.name || "-"}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Master Topic: <b>{masterTopic?.data?.name || "-"}</b>
        </div>
    </div>;
};
MasterDataField.defaultProps = {
    label: 'Meta Data'
}
export default MasterDataField;
