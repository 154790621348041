import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext, NumberField, Filter, TextInput, SelectInput, FieldProps,
    useGetOne,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import {Link, useNavigate} from "react-router-dom";
import {LoadingSection} from "../../common/LoadingSection";
import {useParams} from "react-router";
import Button from "@mui/material/Button";
import {Edit, Visibility} from "@mui/icons-material";
import {Chapters} from "../../custom";
import TopicsLinkField from "./TopicsLinkField";
import {NoDataSection} from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";
import StatusTags from "../../common/StatusTags";
import ContentCounts from "../../common/ContentCounts";
import {STATUS} from "../../common/constants";
import { ChevronRightIcon } from 'lucide-react';

const ListActions = ({programId, subjectId}: { programId: string, subjectId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/subjects/${subjectId}/chapters/create`)
        }}/>
        <ExportButton/>
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Chapters = useRecordContext<Chapters>();
    return <Button startIcon={<Edit/>} onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.id}/edit`)
    }}>
        Edit
    </Button>

};

const ShowChapters = (props: FieldProps<Chapters>) => {
    const navigate = useNavigate();
    const record: Chapters = useRecordContext<Chapters>();
    return <Button startIcon={<Visibility/>} onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.id}/show`)
    }}>
       
    </Button>
};

const ListFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn/>
        <SelectInput
            source="status"
            label={'Status'}
            choices={STATUS}
            alwaysOn
        />
    </Filter>
);

const ChapterList = () => {
    const {programId, subjectId} = useParams();
    const navigate = useNavigate();
    if (!subjectId) {
        return <LoadingSection/>
    }
    return (
        <List 
        title={<ChapterTitle/>}
        sort={{field: 'created_at', order: 'DESC'}}
              filters={<ListFilter/>}
              empty={<NoDataSection resource={'chapters'} onCreate={() => {
                  navigate(`/programs/${programId}/subjects/${subjectId}/chapters/create`)
              }}/>}
              resource={'chapters'} actions={
            <ListActions programId={programId + ''} subjectId={subjectId || ''}/>
        } filter={{
            program_id: programId,
            subject_id: subjectId,
        }}>
            <Datagrid>

                {/* <ThumbnailField source="image"/> */}
                <TextField source="name"/>
                <TextField source="slug"/>
                <StatusTags source="status" label={'Status'}/>
                {/* <StatusTags source="program_status" label={'Program Status'}/>
                <StatusTags source="subject_status" label={'Subject Status'}/> */}
                <NumberField source="display_order"/>
                <ReferenceField reference={'subjects'} source={'subject_id'}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name"/>
                </ReferenceField>
                <ContentCounts/>
                <FormattedDateTimeField  source="created_at"/>
                <TopicsLinkField/>
                <DeleteWithDialogAction/>
                <ShowChapters/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};

const ChapterTitle = () => {
    const { programId, subjectId } = useParams();
    
    const { data: program, isLoading: programLoading } = useGetOne('programs', { id: programId });
    const { data: subject, isLoading: subjectLoading } = useGetOne('subjects', { id: subjectId });

    if (programLoading || subjectLoading) return <span>Chapters</span>;

    return (
        <span className="flex flex-row items-center text-lg font-semibold">
            <Link to="/programs" className="text-blue-600 hover:underline">
                {program?.name}
            </Link>
            <ChevronRightIcon />
            <Link to={`/programs/${programId}/subjects`} className="text-blue-600 hover:underline">
                {subject?.name}
            </Link>
            <ChevronRightIcon />
            <span>Chapters</span>
        </span>
    );
};


export default ChapterList;
