import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput, DateTimeInput, NumberField, FormDataConsumer, BooleanInput,
} from 'react-admin';
import {Tests} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import {STATUS, TEST_CATEGORIES, TEST_TYPES} from "../../common/constants";
import PDFInput from "../../common/inputs/PDFInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const TestEdit = () => {
    let {programId, testSeriesId, testId} = useParams();
    if (!testId) {
        return <LoadingSection/>
    }
    return <Edit title={<TestTitle/>} redirect={() => {
        return `test_serieses/${testSeriesId}/tests`
    }} resource={'tests'} id={testId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source="image" fullWidth/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth validate={[required()]}/>

            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                validate={[required()]}
                choices={TEST_TYPES}
            />
            
            <SelectInput
                source="category"
                label={'Category'}
                fullWidth
                defaultValue={"OBJECTIVE"}
                validate={[required()]}
                choices={TEST_CATEGORIES}
            />
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUS}
            />
            <FormDataConsumer>
                {({ formData }) => {
                    if (formData.category === 'SUBJECTIVE') {
                        return (
                            <PDFInput source="question_file" label={'Questions File'} fullWidth validate={[required()]}/>
                        );
                    }
                    return null;
                }}
            </FormDataConsumer>
            <DateTimeInput source={'start_time'} fullWidth validate={[required()]}/>
            <DateTimeInput source={'end_time'} fullWidth validate={[required()]}/>
            <TextInput type={'number'} source={'max_duration_in_minutes'} fullWidth validate={[required()]}/>
            <DateTimeInput source={'valid_till'} label={'Test Valid Till'} fullWidth />
            <BooleanInput source={'is_demo'} defaultValue={false}/>
            <ReferenceInput label="Test Series" source="test_series_id" reference="test_serieses">
                <SelectInput optionText="title" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput fullWidth source={'general_instructions'}
                           validate={[required(), validHtml]}/>
            <HTMLEditorInput height={200} fullWidth source={'test_instructions'} validate={[required(), validHtml]}/>
            <PDFInput  multiple="true" source="solution_files" label={'Solution Files'} fullWidth/>
        </SimpleForm>
    </Edit>
};

const TestTitle = () => {
    const record = useRecordContext<Tests>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.tests.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default TestEdit;
