import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, useGetIdentity, ReferenceField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import usePermissionMiddleware from "../../common/usePermissionMiddleware";
import FullNameField from "../../common/FullNameField";
import { AssignPermissionsSuperAdmin } from './AssignPermissionModalSuperAdmin';
import useIsPermission from '../../hooks/useIsPermission';


const OrganizationList = () => {
    const { hasPermission } = usePermissionMiddleware();
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const { isUpdatable } = useIsPermission("ORGANIZATION");

    return (
        <List hasCreate={hasPermission('insert_organizations_one')}>
            <Datagrid >
                <TextField source="name" />
                <TextField source="status" />
                <TextField source="min_ios_version" />
                <TextField source="min_android_version" />
                <ReferenceField reference={'users'} source={'primary_user_id'}>
                    <FullNameField source="first_name" />
                </ReferenceField>
                <FormattedDateTimeField source="created_at" />
                {user?.role === "ADMIN" &&
                    <AssignPermissionsSuperAdmin />
                }

                {isUpdatable &&
                    <EditButton />
                }
            </Datagrid>
        </List>
    );
};
export default OrganizationList;
