import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock'; // Import lock icon

import {
    useTranslate,
    MenuItemLink,
    MenuProps,
    useSidebarState,
    Link,
} from 'react-admin';

import MenuOptions from "./SidebarOptions";
import { LoadingSection } from "../common/LoadingSection";
import usePermissionMiddleware from "../common/usePermissionMiddleware";
import MultiLevelMenu from "./MultilevelMenu";

const Menu = ({ dense = false }: MenuProps) => {
    const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    const translate = useTranslate();
    const [open] = useSidebarState();
    const { isLoading } = usePermissionMiddleware();

    // Function to check if user has permission for the given module
    const hasPermission = (module: string) => {
        if (module === "") return true; // Show option if permission is empty
        return permissions?.some((perm: any) => perm?.permissions?.name === module);
    };

    // Function to check if any submenu has permission
    const hasSubmenuPermission = (children: any[]) => {
        return children?.some((child) => hasPermission(child.permission));
    };

    return (
        <Box
            sx={{
                width: open ? 226 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {
                isLoading ? (
                    <div style={{ padding: '100px 0', width: '100%' }}>
                        <LoadingSection linear={true} />
                    </div>
                ) : (
                    MenuOptions.map((subMenuOptions, index) => (
                        <div key={index}>
                            {subMenuOptions.map((option: any, index: number) => {
                                const userHasAccess = hasPermission(option.permission);
                                const hasChildPermission = hasSubmenuPermission(option.children);

                                if (option.children.length > 0) {
                                    return hasChildPermission ? (
                                        <div key={index}>
                                            <MultiLevelMenu option={option} permissions={permissions} />
                                        </div>
                                    ) : (
                                        <div key={index} className="flex justify-between w-full px-4 py-2 text-gray-500">
                                            <div className="flex items-center">
                                                {option.icon}
                                                <span className="ml-2 text-sm">{translate(option.name, { smart_count: 2 })}</span>
                                            </div>
                                            <LockIcon style={{ fontSize: "15px" }} className="ml-2" />
                                        </div>
                                    );
                                }

                                return (
                                    <div className="flex justify-between hover:bg-gray-200">
                                        {userHasAccess ? (
                                            <Link
                                                to={option?.to}
                                                target={option?.openInNewTab ? '_blank' : '_self'}
                                                className="flex  w-full justify-between hover:bg-gray-200"
                                            >
                                                <MenuItemLink
                                                    className='w-full'
                                                    key={option.name}
                                                    to={option.to}
                                                    state={{ _scrollToTop: true }}
                                                    primaryText={translate(option.name, {
                                                        smart_count: 2,
                                                    })}
                                                    leftIcon={option.icon}
                                                    dense={dense}
                                                    target={option?.openInNewTab ? '_blank' : '_self'}
                                                />
                                                <div className="icon pr-5 flex my-auto text-black "> {option?.sideIcon} </div>
                                            </Link>
                                        ) : (
                                            <div className="flex w-full justify-between px-4 py-2 text-gray-500 ">
                                                <div className="flex items-center">
                                                    {option.icon}
                                                    <span className="ml-2 text-sm">{translate(option.name)}</span>
                                                </div>
                                                <LockIcon style={{ fontSize: "15px" }} className="ml-2" />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))
                )}
        </Box>
    );
};

export default Menu;
