import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Questions, Topics,} from "../../custom";
import {useParams} from "react-router";

const QuestionsLinkField = (props: FieldProps<Topics>) => {
    const record = useRecordContext<Questions>();
    const {programId, testSeriesId, testId} = useParams()
    if (!record) {
        return null;
    }
    return (
        <Link
            to={`/test_serieses/${testSeriesId}/tests/${testId}/sections/${record.id}/questions`}>
            Questions
            (
            <ReferenceManyCount
                label="Questions"
                reference="section_question_mappings"
                target="section_id"
            />
            )
        </Link>
    );
};

QuestionsLinkField.defaultProps = {
    label: 'Questions'

};

export default QuestionsLinkField;
