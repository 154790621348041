import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
} from 'react-admin';

import { EditToolbar } from "../../common/EditToolbar";
import PermissionGuard from '../../common/PermissionGuard';
import useIsPermission from '../../hooks/useIsPermission';

const PdfCategoriesEdit = () => {
    const { isUpdatable } = useIsPermission("PDF_CATEGORIES");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<BannerTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput name={'Name'} source={'name'} fullWidth />
                </SimpleForm>
            </Edit>
        </PermissionGuard>
    )
}

const BannerTitle = () => {
    const record = useRecordContext<any>();
    return record ? (
        <span>
            {record.name}
        </span>
    ) : null;
};

export default PdfCategoriesEdit;
