import * as React from 'react';
import {
    Create,
    SimpleForm,
    SelectInput,
    required, TextInput, minValue, ArrayInput, SimpleFormIterator, DateInput, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import { SEGMENTATION_MODULE } from "../../common/constants";
import { Batches, Programs, Subjects, Test_Seriese_Types, Test_Serieses, Users } from "../../custom";


export const operatorChoices = [
    { id: 'AND', name: 'AND' },
    { id: 'OR', name: 'OR' }
];

export const ConditionTypeChoice = [
    { id: 'BATCH', name: 'Batch' },
    { id: 'PROGRAM', name: 'Programs' },
    { id: 'SUBJECT', name: 'Subjects' },
    { id: 'TESTSERIES', name: 'Test Series' },
    { id: 'USER', name: 'User' },
];

export const ConditionMethodsChoice = [
    { id: '=', name: '= Equals' },

];

export const BatchRender = (choice: Batches) => `${choice.title}`;
export const ProgramRender = (choice: Programs) => `${choice?.slug}`
export const SubjectsRender = (choice: Subjects) => `${choice?.name}`
export const TestSeriesRender = (choice: Test_Serieses) => `${choice?.title}`
export const UsersRender = (choice: Users) =>
    choice?.created_at
        ? new Date(choice.created_at).toLocaleDateString('en-GB')
        : '';

const SegmentCreate = () => {
    const [selectedType, setSelectedType] = React.useState() as any
    const handleModuleChange = (e: any) => {
        const selectedModule = ConditionTypeChoice.find(module => module.id === e.target.value);
        setSelectedType(selectedModule ? selectedModule.name : null);
    };

    return (
        <Create redirect={'/segmentations'}>
            <SimpleForm defaultValues={{ condition: {} }}>
                <TextInput source="name" fullWidth validate={[minValue(0)]} />
                <SelectInput
                    source="module"
                    label="Module"
                    fullWidth
                    validate={[required()]}
                    choices={SEGMENTATION_MODULE}
                />
                <ArrayInput source="condition">
                    <SimpleFormIterator >
                        <SelectInput fullWidth source="type" label="Type" choices={ConditionTypeChoice} onChange={handleModuleChange}
                        />
                        <SelectInput fullWidth source="method" label="Method" choices={ConditionMethodsChoice} />
                        {selectedType === "Batch" ?
                            <ReferenceInput name={'Batch'} source={'value'} reference={'batches'}
                                fullwidth>
                                <AutocompleteInput optionText={BatchRender}
                                    debounce={500} fullWidth />
                            </ReferenceInput>
                            :
                            selectedType === "Programs" ?
                                <ReferenceInput name={'Program'} source={'value'} reference={'programs'}
                                    fullwidth>
                                    <AutocompleteInput optionText={ProgramRender}
                                        debounce={500} fullWidth />
                                </ReferenceInput>
                                :
                                selectedType === "Subjects" ?
                                    <ReferenceInput name={'Subject'} source={'value'} reference={'subjects'}
                                        fullwidth>
                                        <AutocompleteInput optionText={SubjectsRender}
                                            debounce={500} fullWidth />
                                    </ReferenceInput>
                                    :
                                    selectedType === "Test Series" ?
                                        <ReferenceInput name={'Test Series'} source={'value'} reference={'test_serieses'}
                                            fullwidth>
                                            <AutocompleteInput optionText={TestSeriesRender}
                                                debounce={500} fullWidth />
                                        </ReferenceInput>
                                        :
                                        selectedType === "User" ?
                                            <ReferenceInput name={'User'} source={'value'} reference={'users'}
                                                fullwidth>
                                                <AutocompleteInput optionText={UsersRender}
                                                    debounce={500} fullWidth />
                                            </ReferenceInput>
                                            : <>
                                            </>
                        }
                        <SelectInput source="operator" fullWidth label="Operator" choices={operatorChoices} />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    )
}

export default SegmentCreate;
