import { Datagrid, EditButton, Filter, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, TopToolbar, useListContext } from 'react-admin'
import FormattedDateTimeField from '../../common/FormattedDateTimeField'
import { DeleteWithDialogAction } from '../../common/DeleteWithDialogAction'
import ThumbnailField from '../../common/ThumbnailField'
import { useNavigate, useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import PdfViewer from '../../common/PdfViewer';
import useIsPermission from '../../hooks/useIsPermission';

const PdfFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput variant={'outlined'} label="Title" source="title" alwaysOn={true} defaultValue="" />
      <TextInput variant={'outlined'} label="Code" source="code" defaultValue="" alwaysOn={true} />
      <ReferenceInput label="PDF Categories" source="category_id" alwaysOn reference="pdf_categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
}

const ListActions = () => {
  const navigate = useNavigate();
  const { filterValues } = useListContext();

  // Convert filter values to query string
  const query = new URLSearchParams(filterValues).toString();

  return (
    <TopToolbar>
      {/* <Button
              startIcon={<CloudUpload />}
              onClick={() => {
                  navigate(`/pdfs/bulk?${query}`);
              }}
              sx={{ padding: '0 5px' }}>
              Bulk Upload
          </Button> */}

      <Link
        to={`/pdfs/create?${query}`}
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="text"
          startIcon={<Add />}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(0, 0, 0, 0.87)',
            border: 'none',
            backgroundColor: 'transparent',
            padding: '8px 16px',
            fontSize: '14px'
          }}
        >
          <Typography variant="button">Create</Typography>
        </Button>
      </Link>

    </TopToolbar>
  );
};


const PdfsList = () => {
  const { isUpdatable, isDeletable, isWritable } = useIsPermission("PDF");

  return (
    <div>
      <List actions={isWritable && <ListActions />} sort={{ field: 'code', order: 'DESC' }} filters={<PdfFilter />} >
        <Datagrid>
          <ThumbnailField source="thumbnail" />
          <TextField source={'title'} />
          <PdfViewer source="url" />
          <ReferenceField reference={'pdf_categories'} source={'category_id'}>
            <TextField source={'name'} />
          </ReferenceField>
          <FormattedDateTimeField source="created_at" />
          {isUpdatable &&
            <EditButton />
          }
          {isDeletable &&
            <DeleteWithDialogAction />
          }
        </Datagrid>
      </List>
    </div>
  )
}

export default PdfsList
