import { Create, SimpleForm, TextInput } from 'react-admin'

const RolesCreate = () => {
    return (
        <Create redirect={'/roles'}>
            <SimpleForm>
                <TextInput source="name" fullWidth />
            </SimpleForm>
        </Create>
    )
}

export default RolesCreate
