import { Datagrid, EditButton, Link, List, ReferenceManyCount, TextField, useRecordContext } from "react-admin"
import FormattedDateTimeField from "../../common/FormattedDateTimeField"
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction"
import useIsPermission from "../../hooks/useIsPermission";
import PermissionBasedActions from "../../common/PermissionBasedActionsButton";

const PdfsLinkComponent = () => {
    const record = useRecordContext();
    return <Link
        to={`/pdfs?filter=${encodeURIComponent(JSON.stringify({ category_id: record.id }))}`}>
        Pdfs
        (
        <ReferenceManyCount
            label="Pdfs"
            reference="pdfs"
            target="category_id"
        />
        )
    </Link>
}

const PdfCategoriesList = () => {
    const { isUpdatable, isDeletable, isWritable } = useIsPermission("PDF_CATEGORIES");

    return (
        <div>
            <List
                actions={<PermissionBasedActions isWritable={isWritable} />}
            >
                <Datagrid>
                    <TextField source="name" />
                    <FormattedDateTimeField source="created_at" />
                    <PdfsLinkComponent />
                    {isUpdatable &&
                        <EditButton />
                    }
                    {isDeletable &&
                        <DeleteWithDialogAction />
                    }
                </Datagrid>
            </List>
        </div>
    )
}

export default PdfCategoriesList
