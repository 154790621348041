import { Create, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { PERMISSIONS_TYPE } from '../../common/constants'

const PermissionsCreate = () => {
    return (
        <Create redirect={'/permissions'}>
            <SimpleForm>
                <TextInput source="name" fullWidth />
                <SelectInput
                    source="type"
                    label={'Type'}
                    fullWidth
                    validate={[required()]}
                    choices={PERMISSIONS_TYPE}
                />
            </SimpleForm>
        </Create>
    )
}

export default PermissionsCreate
