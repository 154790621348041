import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingSection } from "../../common/LoadingSection";
import { BASE_URL } from "../../common/constants";
import { useRecordContext } from "react-admin";

interface Permission {
  id: string;
  type: string;
  name: string;
}

interface Module {
  module: string;
  permissions: Permission[];
}

interface PermissionsListInputProps {
  handleClose: () => void;
}

const SuperAdminPermissionList: React.FC<PermissionsListInputProps> = ({
  handleClose,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<Module[]>([]);
  const [assignedAccess, setAssignedAccess] = useState<Module[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const record = useRecordContext();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token || !record?.name) return;
    const fetchAssignedAccess = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/admin/permissions/${record?.id}/ORG_ADMIN/permissions`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setAssignedAccess(data?.data || []);
      } catch (error) {
        console.error("Failed to fetch permissions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAssignedAccess();
  }, [token, record?.name]);

  useEffect(() => {
    if (!token) return;

    const fetchPermissions = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL}/admin/permissions`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setPermissions(data?.data || []);
      } catch (error) {
        console.error("Failed to fetch permissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [token]);
  useEffect(() => {
    if (assignedAccess.length > 0) {
      const assignedPermissionIds = assignedAccess.map(
        (access: any) => access?.permission_id
      );
      setSelectedPermissions(assignedPermissionIds);
    }
  }, [assignedAccess]);

  const handleModuleSelect = (modulePermissions: Permission[]) => {
    const allPermissionIds = modulePermissions.map((p) => p.id);
    const isModuleSelected = allPermissionIds.every((id) =>
      selectedPermissions.includes(id)
    );

    if (isModuleSelected) {
      setSelectedPermissions((prev) =>
        prev.filter((id) => !allPermissionIds.includes(id))
      );
    } else {
      setSelectedPermissions((prev) =>
        Array.from(new Set([...prev, ...allPermissionIds]))
      );
    }
  };

  const handlePermissionSelect = (id: string) => {
    setSelectedPermissions((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSubmit = async () => {
    if (!record?.id || selectedPermissions.length === 0) {
      console.error("Invalid role ID or no permissions selected");
      return;
    }

    const formData = {
      permissions: selectedPermissions.map((permissionId) => ({
        role_id: "386664c2-4cbf-478d-a4b4-0d10f3b1b554",
        permission_id: permissionId,
      })),
    };

    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found!");
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}/admin/permissions/${record?.id}/assign`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      handleClose();
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Permissions assigned successfully!");
    } catch (error) {
      console.error("Failed to assign permissions:", error);
      alert("Failed to assign permissions. Please try again.");
    }
  };

  const isAllSelected = permissions.every((module) =>
    module.permissions.every((p) => selectedPermissions.includes(p.id))
  );

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedPermissions([]); // Deselect all
    } else {
      setSelectedPermissions(
        permissions.flatMap((module) => module.permissions.map((p) => p.id))
      );
    }
  };
  return (
    <div className="flex flex-col min-w-[50vw] min-h-[50vh] p-4">
      {loading ? (
        <LoadingSection />
      ) : (
        <>
          <div className="flex justify-start mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    !isAllSelected && selectedPermissions.length > 0
                  }
                  onChange={handleSelectAll}
                />
              }
              label={
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Select All
                </Typography>
              }
            />
          </div>
          <div className="flex-1 overflow-auto">
            {permissions?.map((module, index) => {
              const modulePermissionIds = module?.permissions.map((p) => p.id);
              const isModuleSelected = modulePermissionIds?.every((id) =>
                selectedPermissions.includes(id)
              );
              return (
                <Accordion key={index} className="mb-4 " disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isModuleSelected}
                          indeterminate={
                            modulePermissionIds.some((id) =>
                              selectedPermissions.includes(id)
                            ) && !isModuleSelected
                          }
                          onChange={() =>
                            handleModuleSelect(module.permissions)
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {module.module}
                        </Typography>
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <FormGroup sx={{ paddingLeft: 4 }}>
                      {module.permissions.map((permission) => (
                        <FormControlLabel
                          key={permission.id}
                          control={
                            <Checkbox
                              checked={selectedPermissions.includes(
                                permission.id
                              )}
                              onChange={() =>
                                handlePermissionSelect(permission.id)
                              }
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {permission.type}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>

          <div className="flex flex-row justify-end gap-3 mt-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={selectedPermissions.length === 0}
            >
              Submit
            </Button>

            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SuperAdminPermissionList;
