import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    SelectInput,
    TabbedForm,
    useNotify,
    useGetList,
    useRedirect,
} from 'react-admin';
import { Users } from "../../custom";
import { BASE_URL, COUNTRY_CODES, USER_STATUSES } from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import { CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import { EditToolbar } from "../../common/EditToolbar";
import { Public } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useState } from "react";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionGuard from '../../common/PermissionGuard';
import { redirect, useParams } from 'react-router';

export enum ClientType {
    Chrome = "Chrome",
    Firefox = "Firefox",
    Safari = "Safari",
    Edge = "Edge",
    InternetExplorer = "Internet Explorer",
    Opera = "Opera",
    Other = "Other"
}

function detectClientType(userAgent: string): ClientType {
    if (userAgent.includes("Chrome")) return ClientType.Chrome;
    if (userAgent.includes("Firefox")) return ClientType.Firefox;
    if (userAgent.includes("Safari")) return ClientType.Safari;
    if (userAgent.includes("Edge")) return ClientType.Edge;
    if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) return ClientType.InternetExplorer;
    if (userAgent.includes("Opera")) return ClientType.Opera;
    return ClientType.Other;
}

interface ClientDetectorProps {
    userAgent: string;
}

const ClientTypeField = () => {
    const record = useRecordContext();
    const clientType = detectClientType(record?.device_details);
    return <div className={'flex gap-4'}>
        {/*<div className={'px-2 py-0.5 bg-red-500 text-white rounded-md'}>{clientType}</div>*/}
        <div>
            {record.platform === 'WEB' && <Public className={'text-blue-500'} />}
        </div>
    </div>;
};

ClientTypeField.defaultProps = {
    label: 'Device Type'
}


function generateRandomPassword(length: number) {
    const charset = "0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}



const UserEditForm = () => {
    const [updatedPassword, setUpdatedPassword] = useState('');
    const [updatingPassword, setUpdatingPassword] = useState(false);
    const record = useRecordContext();
    const [role, setRole] = React.useState(record?.role_id);
    const notify = useNotify();
    const { id } = useParams()
    const redirect = useRedirect();
    const { data: rolesList = [] } = useGetList<any>("roles", {
        sort: { field: "created_at", order: "ASC" },
        pagination: { page: 1, perPage: 200 },
    });

    const predefinedRoles = [
        { name: "STUDENT", id: "4d8c2d80-a3e9-4d1a-be12-08def4829437" },
    ];

    const roleOptions = [
        ...predefinedRoles,
        ...rolesList.map((role) => ({
            name: role.name,
            id: role.id,
        })),
    ];

    const handleSubmit = async (values: any) => {
        try {
            const finalData = {
                ...values,
                role_id: role,
            };

            const response = await fetch(BASE_URL + `/admin/users/${id}`, {
                method: "PUT",
                body: JSON.stringify(finalData),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Cookie': document.cookie
                },
            });

            if (!response.ok) {
                throw new Error("Failed to create user");
            }

            const data = await response.json();
            console.log("User Created:", data);

            notify("User updated successfully", { type: "success" });
            redirect("/users");
        } catch (error: any) {
            console.error("Error creating user:", error);
            notify(`Error: ${error.message}`, { type: "warning" });
        }
    };

    return <TabbedForm onSubmit={handleSubmit} toolbar={<EditToolbar />}>
        <TabbedForm.Tab
            label="resources.users.tabs.details">
            {/* <TextInput disabled source="role" fullWidth /> */}
            <FormControl fullWidth required>
                <InputLabel>Role</InputLabel>
                <Select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                >
                    {roleOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ImageInput source="profile_image" label={'Profile Image'} fullWidth />
            <TextInput source="first_name" validate={[required()]} fullWidth />
            <TextInput source="last_name" validate={[required()]} fullWidth />
            <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
            <TextInput source="phone" validate={[required()]} fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput source="admission_number" fullWidth />
            <SelectInput source="status" choices={USER_STATUSES} fullWidth />
            <Divider />
            <div className={'text-xl font-bold mb-2'}>
                Change Password
            </div>
            <div className={'flex space-x-2 items-center'}>
                {
                    updatedPassword && <div>{updatedPassword}</div>
                }
                {
                    updatingPassword ? <CircularProgress /> : <Button onClick={() => {
                        setUpdatingPassword(true);
                        const newP = generateRandomPassword(6);
                        fetch(BASE_URL + '/auth/admin/set-user-password', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            },
                            body: JSON.stringify({ userId: record.id, password: newP }),
                        }).then((q: any) => {
                            return q.json();
                        }).then((q) => {
                            if (!q?.error) {
                                setUpdatedPassword(newP);
                            }
                        }).finally(() => {
                            setUpdatingPassword(false);
                        });
                    }}>
                        Update Password
                    </Button>
                }
            </div>

        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.addresses"
            path="addresses"
            sx={{ maxWidth: '40em' }}
        >
            <HTMLEditorInput source="description" label="" validate={[validHtml]} />
        </TabbedForm.Tab>

    </TabbedForm>
}

const UserEdit = () => {
    const { isUpdatable } = useIsPermission("USERS");
    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<UserTile />}>
                <UserEditForm />
            </Edit>
        </PermissionGuard>
    )
}

const UserTile = () => {
    const record = useRecordContext<Users>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.users.name', { smart_count: 1 })} &quot;
            {record.first_name}&quot;
            {record.last_name}&quot;
        </span>
    ) : null;
};

export default UserEdit;
