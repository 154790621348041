import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    SelectInput,
    TabbedShowLayout,
    useDataProvider,
    Pagination,
    Datagrid,
    ReferenceField,
    ReferenceManyField, TextField, BooleanField, FunctionField, List, useRefresh, DateField,
    Show,
} from 'react-admin';
import { useMutation, useQuery } from 'react-query';

import { Users } from "../../custom";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Public } from "@mui/icons-material";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import ThumbnailField from '../../common/ThumbnailField';
import StatusTags from '../../common/StatusTags';
import {
    FormattedStartEndField,
    ProductTemplateFieldBatches,
} from '../batches/BatchesList';
import { ProductTemplateField } from '../test_serieses/TestSeriesList';

export enum ClientType {
    Chrome = "Chrome",
    Firefox = "Firefox",
    Safari = "Safari",
    Edge = "Edge",
    InternetExplorer = "Internet Explorer",
    Opera = "Opera",
    Other = "Other"
}

const ClientTypeField = () => {
    const record = useRecordContext();
    return <div className={'flex gap-4'}>
        {/*<div className={'px-2 py-0.5 bg-red-500 text-white rounded-md'}>{clientType}</div>*/}
        <div>
            {record.platform === 'WEB' && <Public className={'text-blue-500'} />}
        </div>
    </div>;
};
ClientTypeField.defaultProps = {
    label: 'Device Type'
}
const UnregisterDevice = () => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update('user_devices', {
            id: record?.id,
            data: { manually_deactivated_at: new Date(), },
            previousData: {},
        },
        ),
        {
            onSuccess: () => {
                refresh();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const record = useRecordContext();
    if (record.manually_deactivated_at) {
        return <></>
    }
    return <div>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Unregister Device
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to unregister this device?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    if (!isLoading)
                        mutate();
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        <Button color={'error'} onClick={() => {
            setOpen(true);
        }}>Unregister Device</Button>
    </div>
}
const EndSession = () => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const { mutate: mutateDevice } = useMutation(
        () => dataProvider.update('user_devices', {
            id: record?.user_device_id,
            data: { logged_out_at: new Date(), },
            previousData: {},
        },
        ),
        {
            onSuccess: () => {
                refresh();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update('user_sessions', {
            id: record?.id,
            data: { logged_out_at: new Date(), },
            previousData: record,
        },
        ),
        {
            onSuccess: () => {
                console.log('here')
                mutateDevice();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const record = useRecordContext();
    if (record.logged_out_at) {
        return <></>
    }
    return <div>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Logout User
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to log this user out?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    if (!isLoading)
                        mutate();
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        <Button color={'error'} onClick={() => {
            setOpen(true);
        }}>End Session</Button>
    </div>
}

const UserDetails = () => {
    const record = useRecordContext()
    console.log("reorddd", record)
    return (
        <div className='px-10'>
            {record?.admission_number &&
                <div className="flex flex-row gap-2" >
                    <p className='font-bold'>Admission  No :-</p>
                    <p>{record?.admission_number}</p>
                </div>
            }

            {record?.gender &&
                <div className="flex flex-row gap-2" >
                    <p className='font-bold'>Gender :-</p>
                    <p>{record?.gender}</p>
                </div>
            }

            {record?.guardians_number &&
                <div className="flex flex-row gap-2" >
                    <p className='font-bold'>Gender :-</p>
                    <p>{record?.guardians_number}</p>
                </div>
            }
            {record?.role &&
                <div className="flex flex-row gap-2" >
                    <p className='font-bold'>Role :-</p>
                    <p>{record?.role}</p>
                </div>
            }
            {record?.status &&
                <div className="flex flex-row gap-2" >
                    <p className='font-bold'>Status :-</p>
                    <p>{record?.status}</p>
                </div>
            }
        </div>
    )
}

const StudentTabs = () => {
    return <TabbedShowLayout >
        <TabbedShowLayout.Tab
            label="resources.users.tabs.details">
            <div className="flex flex-col gap-2 ">
                <FullNameField source="first_name" fullWidth />
                <UserDetails />
            </div>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="resources.users.tabs.programs"
            path="programs"
        >
            <ReferenceManyField
                label={'Programs'}
                reference="user_programs"
                target="user_id"
                pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <TextField source="id" />
                        <ReferenceField reference={'programs'} source={'program_id'}>
                            <TextField source={'name'} />
                        </ReferenceField>
                        <ReferenceField reference={'users'} source={'assigned_by'}>
                            <TextField source={'first_name'} />
                        </ReferenceField>
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="resources.users.tabs.batches"
            path="batches"
        >
            <ReferenceManyField
                label={'Batches'}
                reference="batch_students"
                target="student_id"
            // pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Image">
                            <ThumbnailField source="image" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Title">
                            <TextField source="title" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Product Template">
                            <ProductTemplateFieldBatches />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Status">
                            <StatusTags source="status" label={'Status'} />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Start - End">
                            <FormattedStartEndField source="start_time" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Template">
                            <ReferenceField reference={'batch_templates'} source={'template_id'}>
                                <TextField source="title" />
                            </ReferenceField>
                        </ReferenceField>
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="Purchased Subjects"
            path="purchased_subjects"
        >

            <ReferenceManyField
                label={'Purchased Subjects'}
                reference="student_subjects"
                target="student_id"
            // pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="Image">
                            <ThumbnailField source="image" />
                        </ReferenceField>
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="name">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="Status">
                            <StatusTags source="status" label={'Status'} />
                        </ReferenceField>
                        <DateField source='ends_on' />
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="resources.users.tabs.test_series"
            path="test_series"
        >
            <ReferenceManyField
                label={'Test Series'}
                reference="student_test_series"
                target="student_id"
            // pagination={<Pagination />}
            >
                <Datagrid
                    sx={{
                        width: '100%',
                        '& .column-comment': {
                            maxWidth: '20em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                >

                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Image">
                        <ThumbnailField source="image" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Title">
                        <TextField source="title" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Product Template">
                        <ProductTemplateField />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Slug">
                        <TextField source="slug" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Type">
                        <TextField source="type" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Status">
                        <StatusTags source="status" label={'Status'} />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Program">
                        <ReferenceField reference={'programs'} source={'program_id'}>
                            <TextField source="name" />
                        </ReferenceField>
                    </ReferenceField>

                    {/* <TestsLinkField /> */}
                    <FormattedDateTimeField source="created_at" />
                </Datagrid>
            </ReferenceManyField>

        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="resources.users.tabs.devices"
            path="devices"
        >

            <ReferenceManyField
                label={'User Devices'}
                reference="user_devices"
                target="user_id"
                sort={{ field: 'updated_at', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid
                    sx={{
                        width: '100%',
                        '& .column-comment': {
                            maxWidth: '20em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                >
                    <TextField source="spirit_code" />
                    <ClientTypeField />
                    <BooleanField source="blocked" />
                    <FormattedDateTimeField source="manually_deactivated_at" />
                    {/*<FormattedDateTimeField source="last_activity_at"/>*/}
                    <FormattedDateTimeField source="created_at" />
                    <UnregisterDevice />
                </Datagrid>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>
    </TabbedShowLayout>
}

const UserShow = () => (
    <Show title={<UserTile />}>
        <StudentTabs />
    </Show>
);

const UserTile = () => {
    const record = useRecordContext<Users>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.users.name', { smart_count: 1 })} &quot;
            {record.first_name}  {record.last_name}&quot;
        </span>
    ) : null;
};

export default UserShow;
