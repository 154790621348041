import {Image} from "@mui/icons-material";
import PdfsList from "./PdfsList";
import PdfsEdit from "./PdfsEdit";
import PdfsCreate from "./PdfsCreate";

export default {
    list: PdfsList,
    edit: PdfsEdit,
    create: PdfsCreate,
    icon: Image,
};
