import * as React from "react";
import {
  Edit,
  TextInput,
  useTranslate,
  useRecordContext,
  required,
  TabbedForm,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  NullableBooleanInput,
  ArrayInput,
  SelectArrayInput,
  RadioButtonGroupInput,
  FormDataConsumer,
} from "react-admin";

import { Organizations } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import { EditToolbar } from "../../common/EditToolbar";
import usePermissionMiddleware from "../../common/usePermissionMiddleware";
import { VIDEO_QUALITIES } from "../../common/constants";
import useIsPermission from "../../hooks/useIsPermission";
import PermissionGuard from "../../common/PermissionGuard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoModalOrg from "./InfoModalOrg";


const OrganizationEdit = () => {
  const { hasPermission } = usePermissionMiddleware();
  const { isUpdatable } = useIsPermission("ORGANIZATION");
  const [open, setOpen] = React.useState(false);
  return (
    <PermissionGuard permission={isUpdatable}>
      <Edit title={<OrganizationTile />} mutationMode={"optimistic"}>
        <TabbedForm toolbar={<EditToolbar />}>
          <TabbedForm.Tab label="resources.organizations.tabs.details">
            <ImageInput source="logo" label={"Logo Image"} fullWidth />
            <TextInput source="name" validate={[required()]} fullWidth />

            <NullableBooleanInput fullWidth source={"login_otp"} />
            <NullableBooleanInput fullWidth source={"login_password"} />
            {hasPermission("insert_organizations_one") && (
              <TextInput
                source="video_cdn_base_url"
                validate={[required()]}
                fullWidth
              />
            )}
            {hasPermission("insert_organizations_one") && (
              <ArrayInput source="qualities_allowed" defaultValue={["360"]}>
                <SelectArrayInput
                  label={"Video Qualities"}
                  fullWidth
                  choices={VIDEO_QUALITIES}
                />
              </ArrayInput>
            )}
          </TabbedForm.Tab>
          <TabbedForm.Tab
            label="resources.organizations.tabs.about_us"
            path="about-us"
          >
            <HTMLEditorInput
              source="about_us"
              fullWidth
              validate={required()}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab
            label="resources.organizations.tabs.terms_and_conditions"
            path="terms-and-conditions"
          >
            <HTMLEditorInput
              source="terms_and_conditions"
              fullWidth
              validate={[validHtml]}
            />
          </TabbedForm.Tab>
          <TabbedForm.Tab
            label="resources.organizations.tabs.preferences"
            path="preferences"
          >
            <TextInput source="ios_app_link" fullWidth label={"Ios APP Link"} />
            <TextInput
              source="android_app_link"
              fullWidth
              label={"Android APP Link"}
            />
            <TextInput
              source="min_ios_version"
              fullWidth
              label={"Min IOS version"}
            />
            <TextInput
              source="min_android_version"
              fullWidth
              label={"Min android version"}
            />

            <BooleanInput
              source="enable_public_signup"
              fullWidth
              label={"Enable Public Signup"}
            />

            {/* <BooleanInput
              source="block_multi_device_login"
              fullWidth
              label={"Prevent Multi Device Login"}
            />
            <BooleanInput
              source="block_multi_login"
              fullWidth
              label={"Prevent Multiple Login"}
            /> */}


            <RadioButtonGroupInput
              source="multi_login_type"
              fullWidth
              label={
                <div className="flex flex-row gap-5 items-center  min-w-[500px]">
                  <p className="w-1/2 text-lg">Login Restriction</p>
                  <div onClick={() => setOpen(true)} className="w-full flex flex-row gap-1 items-center justify-start">
                    <InfoOutlinedIcon className='cursor-pointer my-3 justify-end ' sx={{ color: 'blue' }} />
                  </div>
                </div>

              }
              choices={[
                { id: "BLOCK_MULTI_DEVICE_LOGIN", name: "Prevent Multi Device Login" },
                { id: "BLOCK_MULTI_LOGIN", name: "Prevent Multiple Login" },
                { id: "BLOCK_MULTI_LOGIN_WITH_RESTRICTION", name: "Prevent Multiple Login With Restrictions" },
                { id: "BLOCK_MULTI_DEVICE_LOGIN_WITH_RESTRICTION", name: "Prevent Multiple Devices Login With Restrictions" },
                { id: "NONE", name: "None" },
              ]}
            />

            <InfoModalOrg open={open} setOpen={setOpen} />

            <TextInput
              source="preferences.ses_email"
              fullWidth
              label={"Ses Email"}
            />
            <TextInput
              source="preferences.ses_access_key_id"
              fullWidth
              label={"Ses Access Key Id"}
            />
            <TextInput
              source="preferences.ses_secret_key"
              fullWidth
              label={"Ses Secret Key"}
            />
            <TextInput
              source="preferences.ses_region"
              fullWidth
              label={"Ses Region"}
            />
            <TextInput
              source="preferences.razorpay_id"
              fullWidth
              label={"Razorpay Id"}
            />
            <TextInput
              source="preferences.razorpay_secret"
              fullWidth
              label={"Razorpay Secret"}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab
            label="resources.organizations.tabs.privacy_policies"
            path="privacy-policies"
          >
            <HTMLEditorInput
              source="privacy_policies"
              fullWidth
              validate={[required(), validHtml]}
            />
          </TabbedForm.Tab>
          <TabbedForm.Tab
            label="resources.organizations.tabs.refund_and_cancellation_policy"
            path="refund_policies"
          >
            <HTMLEditorInput
              source="refund_policies"
              fullWidth
              validate={[ validHtml]}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab
            label="resources.organizations.tabs.contact_details"
            path="contact-details"
          >
            <TextInput source="support_phone" fullWidth validate={required()} />
            <TextInput source="support_email" fullWidth validate={required()} />
            <TextInput
              source="support_whatsapp"
              fullWidth
              validate={required()}
            />
            <TextInput source="address_text" fullWidth />
            <TextInput source="address_google_map_link" fullWidth />
            <TextInput source="facebook" fullWidth />
            <TextInput source="instagram" fullWidth />
            <TextInput source="telegram" fullWidth />
            <TextInput source="support_additional_phone" fullWidth />
            <TextInput source="youtube" fullWidth />
            <TextInput source="twitter" fullWidth />
            <TextInput source="linkedin" fullWidth />
          </TabbedForm.Tab>
        </TabbedForm>
      </Edit>
    </PermissionGuard>
  );
};

const OrganizationTile = () => {
  const record = useRecordContext<Organizations>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("resources.organizations.name", { smart_count: 1 })} &quot;
      {record.name}
    </span>
  ) : null;
};

export default OrganizationEdit;
