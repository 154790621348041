import {Image} from "@mui/icons-material";
import PdfCategoriesList from "./PdfCategoriesList";
import PdfCategoriesEdit from "./PdfCategoriesEdit";
import PdfCategoriesCreate from "./PdfCategoriesCreate";

export default {
    list: PdfCategoriesList,
    edit: PdfCategoriesEdit,
    create: PdfCategoriesCreate,
    icon: Image,
};
