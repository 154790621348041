import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput, AutocompleteInput, ArrayInput, SelectArrayInput,
} from 'react-admin';

import { Ce_Blogs, } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { ORGANIZATION_RESOURCE_TYPES } from "../../common/constants";
import { filterToQueryVideo, videoNameRender } from "../batch_schedules/BatchScheduleEdit";
import useIsPermission from '../../hooks/useIsPermission';
import PermissionGuard from '../../common/PermissionGuard';

const OrganizationResourcesEdit = () => {
    const { isUpdatable } = useIsPermission("ORGANIZATION_RESOURCE");

    return (
        <PermissionGuard permission={isUpdatable}>
            <Edit title={<OrganizationResourcesTitle />}>
                <SimpleForm toolbar={<EditToolbar />}>
                    <TextInput source="title" fullWidth validate={[required()]} />
                    <SelectInput
                        source="type"
                        label={'Resource Type'}
                        fullWidth
                        validate={[required()]}
                        choices={ORGANIZATION_RESOURCE_TYPES}
                    />
                    <ReferenceInput name={'Video'} source={'video_id'} reference={'videos'}>
                        <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                            limitChoicesToValue={true} debounce={500} fullWidth validate={[required()]} />
                    </ReferenceInput>
                    <TextInput source="display_order" type={'number'} fullWidth validate={[required()]} />

                </SimpleForm>
            </Edit>
        </PermissionGuard>
    );
};

const OrganizationResourcesTitle = () => {
    const record = useRecordContext<Ce_Blogs>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.blogs.name', { smart_count: 1 })} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

export default OrganizationResourcesEdit;
